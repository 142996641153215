import styled from 'styled-components';

export const SettingContainer = styled.div`
  padding: 20px;

  .setting-header {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    span {
      color: var(--color-text-secondary);
      cursor: pointer;
    }
  }

  .settings-body {
    padding: 25px 0px;
  }

  .theme-title {
    position: absolute;
    top: -30px;
  }
`;
