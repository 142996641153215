import React from 'react';
import { useLocation } from 'react-router-dom';
import PublicFooter from '../Footer/PublicFooter';
import PublicNavbar from '../Navbars/PublicNavbar/PublicNavbar';

import routes from '../../utils/routes';

const PublicLayout = ({ children }) => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== routes.DASHBOARD ? (
        <>
          <PublicNavbar />
          {children}
          <PublicFooter />
        </>
      ) : (
        children
      )}
    </>
  );
};

export default PublicLayout;
