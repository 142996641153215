import { Button, ConfigProvider } from 'antd';

const SecondaryButton = ({ children, ...props }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorBgContainer: `var(--color-grey-4)`,
            colorPrimaryBorder: 'var(--color-grey-4)',
            colorPrimaryActive: `var(--color-grey-2)`,
            colorPrimaryHover: `var(--color-grey-2)`,
            colorPrimary: `var(--color-grey-4)`,
            colorTextLightSolid: `var(--color-text)`,
            primaryShadow: 'none',
          },
        },
      }}
    >
      <Button {...props} type="primary">
        {children}
      </Button>
    </ConfigProvider>
  );
};

export default SecondaryButton;
