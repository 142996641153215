export const PUBLIC_LINKS = {
  PAYMENT_POLICY: 'https://cdn.martextrade.com/agreements/Payment-Policy.pdf',
  PRIVACY_POLICY: 'https://cdn.martextrade.com/agreements/Privacy-Policy.pdf',
  RISK_DISCLOSURE: 'https://cdn.martextrade.com/agreements/Risk-Disclosure.pdf',
  SERVICE_AGREEMENT: 'https://cdn.martextrade.com/agreements/Service-Agreement.pdf',
  TRADING_OPERATION_RULE: 'https://cdn.martextrade.com/agreements/Trading-Operations-Rules.pdf',
  AML_POLICY: 'https://cdn.martextrade.com/agreements/AML-Policy.pdf',
  KYC_POLICY: 'https://cdn.martextrade.com/agreements/KYC-Policy.pdf',
  NON_TRADING: 'https://cdn.martextrade.com/agreements/Non-Trading-Operations-and-Regulations.pdf',
};
