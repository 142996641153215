import React, { Suspense, useEffect, useState } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import routes from './utils/routes';
import PrivateRoute from './hoc/PrivateRoute';
import PublicRoute from './hoc/PublicRoute';
import { Spin, notification } from 'antd';
import HomePage from './Pages/HomePage/HomePage';
import ContactUsPage from './Pages/ContactUsPage/ContactUsPage';
import FaqPage from './Pages/FaqPage/FaqPage';
import Login from './Pages/AuthPages/Login';
import Registration from './Pages/AuthPages/Registration';
import ForgotPassword from './Pages/AuthPages/ForgotPassoword';
import ResetPassword from './Pages/AuthPages/RestorePassword';
import OTPVerification from './Pages/AuthPages/OTPVerification';
import SupportFAQPage from './Pages/SupportPage/SupportFAQPage';
import SupportDetail from './Pages/SupportPage/SupportDetail';
import { ApplicationWrapper, FallbackWrapper } from './styles/style';
import { setHeaderToken } from './api/utils/utils';
import BlockedCountry from './Pages/BlockedCountry/BlockedCountry';
import NativeChart from './Pages/NativeChart';
import { PUBLIC_API, apiURL } from './api/constant/constant';
import SponsoreProtectRoute from './hoc/SponsoreProtectRoute';
import { useUserStore } from './store/useUserStore';
import { getQueryStringObj, isSupportedBrowser, syncCurrentTimeWithServer } from './utils/utils';
import { useSessionStorage } from './store/useSessionStorage';
import _ from 'lodash';
import NotFound from './Pages/404';
import ErrorBoundary from './Pages/500/ErrorBoundary';
import { useLinkClicked } from './api/commonApi';
import RootBoundary from './Components/RootBoundary/RootBoundary';
import BrowserRestrictions from './Pages/BrowserRestrictions';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from './constants/language';
import PrivacyPolicy from './Pages/PrivacyPolicy';

const AboutUsPage = React.lazy(() => import('./Pages/AboutUsPage/AboutUsPage'));
const ProfilePage = React.lazy(() => import('./Pages/ProfilePage/ProfilePage'));
const AccountPage = React.lazy(() => import('./Pages/ProfilePage/AccountPage/AccountPage'));
const DepositPage = React.lazy(() => import('./Pages/ProfilePage/DepositPage/DepositPage'));
const TradesPage = React.lazy(() => import('./Pages/ProfilePage/TradesPage/TradesPage'));
const TransactionPage = React.lazy(() => import('./Pages/ProfilePage/TransactionPage/TransactionPage'));

const WithdrawalPage = React.lazy(() => import('./Pages/WithdrawalPage/Withdrawal'));
const SupportPage = React.lazy(() => import('./Pages/SupportPage/SupportPage'));
const MyRequestPage = React.lazy(() => import('./Pages/SupportPage/MyRequestPage/MyRequestPage'));
const AddRequestPage = React.lazy(() => import('./Pages/SupportPage/AddRequestPage/AddRequestPage'));
const TerminalPage = React.lazy(() => import('./Pages/TerminalPage/TerminalPage'));
const UPICardDepositPage = React.lazy(
  () => import('./Pages/ProfilePage/DepositPage/PaymentMethodsPages/UPICardDepositPage/UPICardDepositPage'),
);

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then((d) => ({
    default: d.ReactQueryDevtools,
  })),
);
const UnderMaintenancePage = React.lazy(() => import('./Pages/UnderMaintenance/UnderMaintenance'));
const LaunchingSoonPage = React.lazy(() => import('./Pages/LaunchingSoon'));

const errorElement = <RootBoundary />;

const router = createBrowserRouter([
  {
    path: '/',
    errorElement,
    children: [
      {
        element: (
          <ErrorBoundary>
            <PrivateRoute />
          </ErrorBoundary>
        ),
        children: [
          { path: routes.NATIVE_CHART, element: <NativeChart />, errorElement },
          { path: routes.TERMINAL.ROOT, element: <TerminalPage />, errorElement },
          { path: routes.DEMO_TRADE, element: <TerminalPage />, errorElement },
          {
            path: routes.PROFILE.ROOT,
            element: (
              <SponsoreProtectRoute>
                <ProfilePage />
              </SponsoreProtectRoute>
            ),
            children: [
              {
                path: routes.PROFILE.ACCOUNT,
                element: <AccountPage />,
                errorElement,
              },
              { path: routes.PROFILE.DEPOSIT, element: <DepositPage />, errorElement },
              { path: routes.PROFILE.TRANSFER, element: <UPICardDepositPage />, errorElement },
              { path: routes.PROFILE.UPI_CARD, element: <UPICardDepositPage />, errorElement },
              { path: routes.PROFILE.TRADES, element: <TradesPage />, errorElement },
              { path: routes.PROFILE.TRANSACTION, element: <TransactionPage />, errorElement },
              { path: routes.PROFILE.WITHDRAWAL, element: <WithdrawalPage />, errorElement },
              { path: routes.NOT_FOUND, element: <NotFound /> },
            ],
          },
          { path: routes.SUPPORT.SUPPORT_DETAIL, element: <SupportDetail />, errorElement },
          {
            path: routes.SUPPORT.ROOT,
            element: <SupportPage />,
            children: [
              { path: routes.SUPPORT.MY_REQUEST, element: <MyRequestPage />, errorElement },
              { path: routes.SUPPORT.ADD_REQUEST, element: <AddRequestPage />, errorElement },
              { path: routes.SUPPORT.FAQ, element: <SupportFAQPage />, errorElement },
              { path: routes.NOT_FOUND, element: <NotFound /> },
            ],
          },
          { path: routes.NOT_FOUND, element: <NotFound /> },
        ],
      },
      {
        element: (
          <ErrorBoundary>
            <PublicRoute />
          </ErrorBoundary>
        ),
        children: [
          {
            path: routes.HOME,
            element: <HomePage />,
            errorElement,
          },
          { path: routes.ABOUT_US, element: <AboutUsPage />, errorElement },
          { path: routes.CONTACT_US, element: <ContactUsPage />, errorElement },
          { path: routes.FAQ, element: <FaqPage />, errorElement },
          { path: routes.LOGIN, element: <Login />, errorElement },
          { path: routes.RESET_PASSWORD, element: <ResetPassword />, errorElement },
          { path: routes.REGISTRATION, element: <Registration />, errorElement },
          { path: routes.FORGOT, element: <ForgotPassword />, errorElement },
          { path: routes.OTP_VERIFY, element: <OTPVerification />, errorElement },
          { path: routes.UNDER_MAINTENANCE, element: <UnderMaintenancePage />, errorElement },
          { path: routes.NOT_FOUND, element: <NotFound /> },
        ],
      },
      { path: routes.PRIVACY_POLICY, element: <PrivacyPolicy />, errorElement },
      { path: routes.LAUNCHING_SOON, element: <LaunchingSoonPage />, errorElement },
      { path: routes.NOT_SUPPORTED, element: <BlockedCountry />, errorElement },
    ],
  },
]);

const App = () => {
  const { i18n } = useTranslation();
  const { language } = useUserStore();
  const [showDevtools, setShowDevtools] = React.useState(false);
  const loginStore = useUserStore();
  const { theme } = useUserStore();
  const [tokenVerified, setTokenVerified] = useState(false);
  const params = getQueryStringObj();
  const { setSignUpParams } = useSessionStorage();
  // eslint-disable-next-line no-unused-vars
  const [api, contextHolder] = notification.useNotification();
  const linkClicked = useLinkClicked();

  const handleQueryParams = () => {
    if (!_.isEmpty(params) && params?.affiliateCode && params?.linkCode) {
      let payload = {
        affiliateCode: params.affiliateCode,
        linkCode: params.linkCode,
      };
      linkClicked.mutate(payload);
      setSignUpParams(payload);
      window.history.replaceState(null, '', window.location.pathname);
    }
  };

  React.useEffect(() => {
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  useEffect(() => {
    // const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    document.documentElement.setAttribute('data-theme', theme ? theme : 'dark');
  }, [theme]);

  useEffect(() => {
    if (loginStore?.isLoggedIn) {
      setHeaderToken(loginStore?.token?.accessToken, loginStore?.token?.refreshToken);
    }
    setTokenVerified(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(LANGUAGES[language]);
    }
  }, [i18n, language]);

  useEffect(() => {
    const startTime = performance.now();
    fetch(apiURL + PUBLIC_API.TIME, { cache: 'no-store' })
      .then((response) => response.json())
      .then((r) => {
        const endTime = performance.now();
        const responseTime = Math.floor((endTime - startTime) / 2);
        syncCurrentTimeWithServer(r + responseTime);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    handleQueryParams();
  }, []);

  // if (!isSupportedBrowser()) {
  //   return <BrowserRestrictions />;
  // }

  return (
    <Suspense
      fallback={
        <FallbackWrapper>
          <Spin />
        </FallbackWrapper>
      }
    >
      <ApplicationWrapper>
        {contextHolder}
        {showDevtools && <ReactQueryDevtoolsProduction />}
        {tokenVerified && <RouterProvider router={router} />}
      </ApplicationWrapper>
    </Suspense>
  );
};

export default React.memo(App);
