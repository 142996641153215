import React from 'react';

const GoogleLoginSVG = () => {
  return (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.982"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.91596 12.6331C5.19089 11.2526 3.43603 9.91364 1.65137 8.6134C3.94252 4.21877 7.52078 1.45166 12.3861 0.318044C16.9089 -0.544816 21.0383 0.347798 24.7715 2.99886C25.2154 3.33805 25.6295 3.71593 26.0109 4.13248L21.5716 8.56579C18.4522 6.01589 15.0467 5.60529 11.3553 7.32804C9.23095 8.55984 7.75019 10.3302 6.91596 12.6331Z"
        fill="#EA4334"
      />
      <path
        opacity="0.984"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.65133 8.61353C3.43599 9.91377 5.19085 11.2527 6.91592 12.6333C6.36771 14.5911 6.36771 16.5489 6.91592 18.5067C5.16106 19.8158 3.40619 21.119 1.65133 22.4223C-0.550443 17.8223 -0.550443 13.2194 1.65133 8.61353Z"
        fill="#FABB04"
      />
      <path
        opacity="0.982"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8021 27.2686C24.1753 25.8881 22.492 24.5848 20.746 23.353C22.3549 22.2224 23.3858 20.696 23.8416 18.768C21.0559 18.7174 18.2702 18.6995 15.4814 18.7174V12.6357C20.3915 12.603 25.2926 12.6357 30.1877 12.7399C30.9266 16.947 30.2384 20.8983 28.123 24.5938C27.4705 25.5846 26.6959 26.4802 25.8021 27.2686Z"
        fill="#4285F3"
      />
      <path
        opacity="0.985"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.91601 18.5095C9.18631 23.6272 13.0714 25.5701 18.5774 24.3323C19.3311 24.0586 20.0522 23.7343 20.7434 23.3534C22.4893 24.5852 24.1756 25.8914 25.7994 27.269C23.4785 29.4054 20.7255 30.6253 17.5435 30.9288C10.959 31.4613 5.84938 29.0067 2.2175 23.5587C2.02682 23.1749 1.83614 22.797 1.64844 22.4251C3.4033 21.1219 5.15816 19.8157 6.91601 18.5095Z"
        fill="#34A753"
      />
    </svg>
  );
};

export default GoogleLoginSVG;
