import { Avatar, Empty } from 'antd';
import CustomModal from '../UI/CustomModal';
import { NotificationCard } from './style';
import { ReactComponent as RocketSVG } from '../../assets/icons/Rocket.svg';
import { useGetNotification, useReadNotification } from '../../api/notificationApi';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGetSetData } from '../../hooks/useGetSetData';
import CustomSpinner from '../UI/CustomSpinner';

const NotificationModal = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const { data: { data: { data: notificationList = [] } = {} } = {}, isPending } = useGetNotification();
  const readNotification = useReadNotification();
  const { fetchProfileDetails } = useGetSetData();

  const handleReadNotification = async (id, notification) => {
    if (notification.clickLink) {
      window.open(notification.clickLink, '_blank');
    }
    await readNotification.mutateAsync(id);
    fetchProfileDetails();
  };

  return (
    <CustomModal title={t('Notification')} open={isOpen} onCancel={() => setIsOpen(false)} footer={null} width={615}>
      {isPending ? (
        <CustomSpinner></CustomSpinner>
      ) : notificationList.length ? (
        notificationList.map((notification) => (
          <NotificationCard
            bordered={false}
            key={notification.id}
            onClick={() => handleReadNotification(notification?.id, notification)}
          >
            <div className={`dot ${notification?.readAt ? '' : 'active'}`}></div>
            <div className="notification__container">
              <Avatar size="large" shape="square" icon={<RocketSVG />} style={{ backgroundColor: '#f56a00' }} />
              <div className="notification__container-information">
                <div className="title">
                  <p> {notification?.title}</p>
                  <div className="dateTime">{moment(notification?.createdAt).fromNow()}</div>
                </div>
                <div className="description">{notification?.description}</div>
                {notification?.imageUrl && <img src={notification?.imageUrl} alt="notification" />}
              </div>
            </div>
          </NotificationCard>
        ))
      ) : (
        <Empty description="No notification available" />
      )}
    </CustomModal>
  );
};

export default NotificationModal;
