import styled from 'styled-components';

export const ChartContainer = styled.div`
  margin-top: 10px;
`;

export const ChartWrapper = styled.div`
  width: 100%;
  height: ${(props) => (props.nativeHeight ? '97vh' : 'calc(100vh - 160px)')};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  #chart {
    width: 100%;
    height: 100%;
  }

  .chart__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media screen and (max-width: 768px) {
    height: ${(props) => (props.nativeHeight ? '97vh' : 'calc(100vh - 355px)')};
  }
`;
