import { ReactComponent as CandleChart } from '../../assets/icons/CandleChart.svg';
import { ReactComponent as OhlcChart } from '../../assets/icons/OhlcChart.svg';
import { ReactComponent as AreaChart } from '../../assets/icons/AreaChart.svg';
import { convertHoursToSec, convertMinToSec } from '../../utils/constants';
import horizontalRayLine from '../icons/horizontalRayLine';
import horizontalSegment from '../icons/horizontalSegment';
import horizontalStraightLine from '../icons/horizontalStraightLine';
import verticalRayLine from '../icons/verticalRayLine';
import verticalSegment from '../icons/verticalSegment';
import verticalStraightLine from '../icons/verticalStraightLine';
import rayLine from '../icons/rayLine';
import segment from '../icons/segment';
import straightLine from '../icons/straightLine';
import priceLine from '../icons/priceLine';
import priceChannelLine from '../icons/priceChannelLine';
import parallelStraightLine from '../icons/parallelStraightLine';
import anyWaves from '../icons/anyWaves';
import threeWaves from '../icons/threeWaves';
import fiveWaves from '../icons/fiveWaves';
import eightWaves from '../icons/eightWaves';
import arrow from '../icons/arrow';
import circle from '../icons/circle';
import rect from '../icons/rect';
import triangle from '../icons/triangle';
import parallelogram from '../icons/parallelogram';
import fibonacciCircle from '../icons/fibonacciCircle';
import fibonacciExtension from '../icons/fibonacciExtension';
import fibonacciSegment from '../icons/fibonacciSegment';
import fibonacciSpeedResistanceFan from '../icons/fibonacciSpeedResistanceFan';
import fibonacciSpiral from '../icons/fibonacciSpiral';
import gannBox from '../icons/gannBox';
import abcd from '../icons/abcd';
import xabcd from '../icons/xabcd';

export const ChartTypes = [
  {
    type: 'candle_solid',
    label: 'Candle Chart',
    img: CandleChart,
  },
  { type: 'area', label: 'Area Chart', img: AreaChart },
  { type: 'ohlc', label: 'Ohlc Chart', img: OhlcChart },
];

export const ChartToolsOptions = [
  { type: 'horizontalRayLine', label: 'Horizontal Ray Line', img: horizontalRayLine, steps: 2 },
  { type: 'horizontalSegment', label: 'Horizontal Segment', img: horizontalSegment, steps: 2 },
  { type: 'horizontalStraightLine', label: 'Horizontal Straight Line', img: horizontalStraightLine, steps: 2 },
  { type: 'verticalRayLine', label: 'Vertical Ray Line', img: verticalRayLine, steps: 2 },
  { type: 'verticalSegment', label: 'Vertical Segment', img: verticalSegment, steps: 2 },
  { type: 'verticalStraightLine', label: 'Vertical Straight Line', img: verticalStraightLine, steps: 2 },
  { type: 'rayLine', label: 'Ray Line', img: rayLine, steps: 2 },
  { type: 'segment', label: 'Segment', img: segment, steps: 2 },
  { type: 'straightLine', label: 'Straight Line', img: straightLine, steps: 2 },
  { type: 'priceLine', label: 'Price Line', img: priceLine, steps: 2 },
  { type: 'priceChannelLine', label: 'Price Channel Line', img: priceChannelLine, steps: 3 },
  { type: 'parallelStraightLine', label: 'Parallel Straight Line', img: parallelStraightLine, steps: 3 },
  // { type: 'simpleAnnotation', label: 'Simple Annotation', img: simpleAnnotation },
  // { type: 'simpleTag', label: 'Simple Tag', img: simpleTag },
  // { type: 'anyWaves', label: 'Any Waves', img: anyWaves, steps: 10 },
  { type: 'threeWaves', label: 'Three Waves', img: threeWaves, steps: 4 },
  { type: 'fiveWaves', label: 'Five Waves', img: fiveWaves, steps: 5 },
  { type: 'eightWaves', label: 'Eight Waves', img: eightWaves, steps: 8 },
  { type: 'arrow', label: 'Arrow', img: arrow, steps: 2 },
  { type: 'circle', label: 'Circle', img: circle, steps: 2 },
  { type: 'rect', label: 'Rectangle', img: rect, steps: 2 },
  { type: 'triangle', label: 'Triangle', img: triangle, steps: 3 },
  { type: 'parallelogram', label: 'Parallelogram', img: parallelogram, steps: 3 },
  { type: 'fibonacciCircle', label: 'Fibonacci Circle', img: fibonacciCircle, steps: 2 },
  { type: 'fibonacciExtension', label: 'Fibonacci Extension', img: fibonacciExtension, steps: 3 },
  { type: 'fibonacciSegment', label: 'Fibonacci Segment', img: fibonacciSegment, steps: 2 },
  {
    type: 'fibonacciSpeedResistanceFan',
    label: 'Fibonacci Speed Resistance Fan',
    img: fibonacciSpeedResistanceFan,
    steps: 2,
  },
  { type: 'fibonacciSpiral', label: 'Fibonacci Spiral', img: fibonacciSpiral, steps: 2 },
  { type: 'gannBox', label: 'Gann Box', img: gannBox, steps: 2 },
  { type: 'abcd', label: 'abcd', img: abcd, steps: 4 },
  { type: 'xabcd', label: 'xabcd', img: xabcd, steps: 5 },
];

export const ChartIndicators = [
  { id: 'mainMA', type: 'MA', label: 'Moving Average', img: '', group: 'main', minimum: 1, maximum: 4 },
  { id: 'mainEMA', type: 'EMA', label: 'Exponential Moving Average', img: '', group: 'main', minimum: 1, maximum: 3 },
  { id: 'mainSMA', type: 'SMA', label: 'SMA', img: '', group: 'main' },
  { id: 'mainBOLL', type: 'BOLL', label: 'Bolinger Bands', img: '', group: 'main' },
  { id: 'mainSAR', type: 'SAR', label: 'Stop and Reverse', img: '', group: 'main' },
  { id: 'mainBBI', type: 'BBI', label: 'Bull And Bear Index', img: '', group: 'main' },

  { id: 'subMA', type: 'MA', label: 'Moving Average', img: '', group: 'sub' },
  { id: 'subEMA', type: 'EMA', label: 'Exponential Moving Average', img: '', group: 'sub' },
  { id: 'subVOL', type: 'VOL', label: 'Volume', img: '', group: 'sub' },
  { id: 'subMACD', type: 'MACD', label: 'Moving Average Convergence / Divergence', img: '', group: 'sub' },
  { id: 'subBOLL', type: 'BOLL', label: 'Bolinger Bands', img: '', group: 'sub' },
  { id: 'subKDJ', type: 'KDJ', label: 'KDJ Index', img: '', group: 'sub' },
  { id: 'subRSI', type: 'RSI', label: 'Relative Strength Index', img: '', group: 'sub' },
  { id: 'subBIAS', type: 'BIAS', label: 'Bias Ratio', img: '', group: 'sub' },
  { id: 'subBRAR', type: 'BRAR', label: 'BRAR', img: '', group: 'sub' },
  { id: 'subCCI', type: 'CCI', label: 'Commodity Channel Index', img: '', group: 'sub' },
  { id: 'subDMI', type: 'DMI', label: 'Direction Movement Index', img: '', group: 'sub' },
  { id: 'subCR', type: 'CR', label: 'CR', img: '', group: 'sub' },
  { id: 'subPSY', type: 'PSY', label: 'Psychological Line', img: '', group: 'sub' },
  { id: 'subDMA', type: 'DMA', label: 'Different of Moving Average', img: '', group: 'sub' },
  { id: 'subTRIX', type: 'TRIX', label: 'Triple Exponentially Smoothed Moving Average', img: '', group: 'sub' },
  { id: 'subOBV', type: 'OBV', label: 'On Balance Volume', img: '', group: 'sub' },
  { id: 'subVR', type: 'VR', label: 'Volatility Volume Ratio', img: '', group: 'sub' },
  { id: 'subWR', type: 'WR', label: 'Williams %R', img: '', group: 'sub' },
  { id: 'subMTM', type: 'MTM', label: 'Momentum Index', img: '', group: 'sub' },
  { id: 'subEMV', type: 'EMV', label: 'Ease of Movement Value', img: '', group: 'sub' },
  { id: 'subSAR', type: 'SAR', label: 'Stop and Reverse', img: '', group: 'sub' },
  { id: 'subSMA', type: 'SMA', label: 'SMA', img: '', group: 'sub' },
  { id: 'subROC', type: 'ROC', label: 'Price Rate of Change', img: '', group: 'sub' },
  { id: 'subPVT', type: 'PVT', label: 'Price and Volume Trend', img: '', group: 'sub' },
  { id: 'subBBI', type: 'BBI', label: 'Bull And Bear Index', img: '', group: 'sub' },
  { id: 'subAO', type: 'AO', label: 'Awesome Oscillator', img: '', group: 'sub' },
];

export const TimeFrameTypes = [
  { type: convertMinToSec(1) * 1000, label: '01:00', img: '1M', chartValue: '1/minute' },
  { type: convertMinToSec(2) * 1000, label: '02:00', img: '2M', chartValue: '2/minute' },
  { type: convertMinToSec(5) * 1000, label: '05:00', img: '5M', chartValue: '5/minute' },
  { type: convertMinToSec(10) * 1000, label: '10:00', img: '10M', chartValue: '10/minute' },
  { type: convertMinToSec(15) * 1000, label: '15:00', img: '15M', chartValue: '15/minute' },
  { type: convertMinToSec(30) * 1000, label: '30:00', img: '30M', chartValue: '30/minute' },
  { type: convertHoursToSec(1) * 1000, label: '01:00:00', img: '1H', chartValue: '1/hour' },
  { type: convertHoursToSec(4) * 1000, label: '04:00:00', img: '4H', chartValue: '4/hour' },
  //   { type: convertHoursToSec(24) * 1000, label: '1D', img: '', chartValue: '1/day' },
  //   { type: 7 * convertHoursToSec(24) * 1000, label: '1W', img: '', chartValue: '1/week' },
];

export const SubscribeActions = {
  onDataReady: 'onDataReady',
  onZoom: 'onZoom',
  onScroll: 'onScroll',
  onCrosshairChange: 'onCrosshairChange',
  onCandleBarClick: 'onCandleBarClick',
  onTooltipIconClick: 'onTooltipIconClick',
  onPaneDrag: 'onPaneDrag',
};
