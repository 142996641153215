import FaqPage from '../../FaqPage/FaqPage';
import { FAQContainer, FAQWrapper } from './style';

const SupportFAQPage = () => {
  return (
    <FAQWrapper>
      <FAQContainer>
        <FaqPage />
      </FAQContainer>
    </FAQWrapper>
  );
};

export default SupportFAQPage;
