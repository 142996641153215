import _ from 'lodash';
import moment from 'moment-timezone';
import { browserName } from 'react-device-detect';
import { LANGUAGES } from '../constants/language';
import openNotification from './openNotification';
import { useTranslation } from 'react-i18next';

export const convertDateToUTCOffset = (date, offset, format = 'YYYY-MM-DD HH:mm:ss') => {
  // Parse the offset
  // eslint-disable-next-line no-unused-vars
  if (offset) {
    const [_, sign, hours, minutes] = offset?.match(/UTC([+-])(\d{2}):(\d{2})/);
    const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
    const offsetMinutes = sign === '+' ? totalMinutes : -totalMinutes;

    // Convert the date to the specified offset
    return moment(date)?.utcOffset(offsetMinutes)?.format(format);
  }
};

export const adjustFromTo = (period, toTimestamp, count) => {
  let to = toTimestamp;
  let from = to;

  switch (period.timespan) {
    case 'minute': {
      to = to - (to % (60 * 1000));
      from = to - count * period.multiplier * 60 * 1000;
      break;
    }
    case 'hour': {
      to = to - (to % (60 * 60 * 1000));
      from = to - count * period.multiplier * 60 * 60 * 1000;
      break;
    }
    case 'day': {
      to = to - (to % (60 * 60 * 1000));
      from = to - count * period.multiplier * 24 * 60 * 60 * 1000;
      break;
    }
    case 'week': {
      const date = new Date(to);
      const week = date.getDay();
      const dif = week === 0 ? 6 : week - 1;
      to = to - dif * 60 * 60 * 24;
      const newDate = new Date(to);
      to = new Date(`${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`).getTime();
      from = count * period.multiplier * 7 * 24 * 60 * 60 * 1000;
      break;
    }
    case 'month': {
      const date = new Date(to);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      to = new Date(`${year}-${month}-01`).getTime();
      from = count * period.multiplier * 30 * 24 * 60 * 60 * 1000;
      const fromDate = new Date(from);
      from = new Date(`${fromDate.getFullYear()}-${fromDate.getMonth() + 1}-01`).getTime();
      break;
    }
    case 'year': {
      const date = new Date(to);
      const year = date.getFullYear();
      to = new Date(`${year}-01-01`).getTime();
      from = count * period.multiplier * 365 * 24 * 60 * 60 * 1000;
      const fromDate = new Date(from);
      from = new Date(`${fromDate.getFullYear()}-01-01`).getTime();
      break;
    }
    default:
      return null;
  }
  return [from, to];
};

export const getFromLocalStore = (variable) => {
  return JSON.parse(window.localStorage.getItem(variable) || '{}');
};

export const setInLocalStore = (variable, data) => {
  window.localStorage.setItem(variable, JSON.stringify(data));
};

export const secondsToMMSS = (time) => {
  return new Date(time * 1000).toISOString().substring(14, 19);
};

export const textEllipsis = (sentence, limit) => {
  let sentenceStr = sentence || '';
  return sentenceStr.length > limit ? sentenceStr.slice(0, limit) + '...' : sentenceStr;
};
export const getImagePreviewFromResponseData = (data) => {
  const base64 = btoa(new Uint8Array(data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
  return `data:;base64,${base64}`;
};

export const getBase64FromFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const getQueryStringObj = (search) => {
  const queryObj = Object.fromEntries(new URLSearchParams(search || window.location.search));

  const queryObjWithProperKeys = {};
  const queryObjKeys = Object.keys(queryObj);
  queryObjKeys.forEach((queryObjKey) => {
    const newQueryObjKey = queryObjKey.replace(/[\W]/g, '');
    queryObjWithProperKeys[newQueryObjKey] = queryObj[queryObjKey];
  });

  return queryObjWithProperKeys;
};

export const objectToQueryString = (obj) => {
  const params = new URLSearchParams();
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== undefined) {
      params.append(key, obj[key]);
    }
  }
  return params.toString();
};

export const countDecimal = (firstItem) => {
  let decimal = 0;

  let numb = Object.values(_.pick(firstItem, ['close', 'high', 'low', 'open']));

  numb.forEach((n) => {
    if (!Number.isInteger(n)) {
      let noOfDec = n.toString().split('.')[1].length;
      if (noOfDec > decimal) {
        decimal = noOfDec;
      }
    }
  });

  return decimal;
};

export const getQueryStringFromObj = (obj) => {
  return Object.keys(obj).reduce((i, c, index) => {
    if (index === 0) {
      return `?${c}=${obj[c]}`;
    } else {
      return i + `&${c}=${obj[c]}`;
    }
  }, '');
};

export const generateRandomBetweenTwoNumbers = (min, max) => Math.random() * (max - min) + min;

export const downloadCSV = (content, filename, contentType) => {
  // Create a blob
  var blob = new Blob([content], { type: contentType });
  var url = URL.createObjectURL(blob);

  // Create a link to download it
  var pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click();
};

export const convertDateTime = (date, getCurrentTimeZone) => {
  return <span>{convertDateToUTCOffset(date, getCurrentTimeZone)}</span>;
};

export const MetaTitle = (updatedTitle) => {
  return (document.title = updatedTitle);
};

const isInWebView = () => {
  return navigator.userAgent.indexOf('Android') > -1 && navigator.userAgent.indexOf('wv') > -1;
};

export const isSupportedBrowser = () => {
  const supportBrowsers = ['Chrome', 'Firefox', 'Safari', 'Mobile Safari', 'WebKit'];
  return supportBrowsers.includes(browserName) || isInWebView();
};

export const syncCurrentTimeWithServer = (serverTime) => {
  const originalDateNow = Date.now;
  const customStartTime = serverTime;
  const startRealTime = originalDateNow(); // Capture the real time when mocking starts

  // Override Date.now() to return the custom timestamp incremented by elapsed time
  Date.now = function () {
    const nowRealTime = originalDateNow();
    const elapsedTime = nowRealTime - startRealTime;
    return customStartTime + elapsedTime;
  };

  return function restore() {
    // Restore the original Date.now() function
    Date.now = originalDateNow;
  };
};
export const formatNumericValues = (number = 0, decimalPlaces, absolute = false) => {
  const amount = Number(number);
  const updatedDecimal = decimalPlaces ? decimalPlaces : amount % Math.floor(amount) !== 0 ? 2 : 0;

  // Convert amount to string and split into integer and decimal parts
  let [integerPart, decimalPart] = parseFloat(amount).toFixed(updatedDecimal).split('.');

  if (absolute) {
    let absIntegerPart = Math.abs(integerPart);
    integerPart = absIntegerPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
  }

  // Add commas for thousands separator
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Return formatted amount
  return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
};

export function removeCommaAndParseNumber(str) {
  // Remove commas from the string
  const stringWithoutComma = str.replace(/,/g, '');

  // Parse the string as a float number
  const number = parseFloat(stringWithoutComma);

  // Return the parsed number
  return number;
}

// get object key from thier respective value
export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

// remove null values from object
export function removeNullValues(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([key, value]) => value !== null));
}

// Returns the language name for a given code
export const getLanguageName = (language) => Object.keys(LANGUAGES).find((a) => LANGUAGES[a] === language) || 'English';

// reset react-hook-form values
export const resetReactHookFormValue = (valuesList, setValue) => {
  valuesList.forEach((key) => {
    setValue(key, null);
  });
};

export const useTranslatedNotification = () => {
  const { t } = useTranslation();

  const showNotification = (type, key, options = {}) => {
    const message = t(key);
    openNotification(type, { title: message, ...options });
  };

  return { showNotification };
};

export const copyTextToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

export const formatTimeFromSeconds = (timeInSeconds) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

export function getCandleTimestamps(epochTime, timeframe) {
  // Calculate the candle end timestamp
  const candleEnd = Math.ceil(epochTime / timeframe) * timeframe;

  // Calculate the candle start timestamp (end of the previous candle)
  const candleStart = candleEnd;

  return {
    candleStart,
    candleEnd,
  };
}
