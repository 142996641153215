import React from 'react';
import NotFoundImage from '../../assets/images/404NotFound.svg';
import CustomAnnouncement from '../../Components/UI/CustomAnnouncement/CustomAnnouncement';
import { Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import routes from '../../utils/routes';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Row style={{ margin: 'auto' }} justify={'center'}>
      <CustomAnnouncement.StaticAnnouncement
        header={t('The requested page could not be found!')}
        text={t('Go to home page')}
        img={NotFoundImage}
        actionButton={() => navigate(routes.HOME)}
      />
    </Row>
  );
};

export default NotFound;
