import React from 'react';
import tabItem1IC from '../../assets/icons/tab_item-1.svg';
import tabItem2IC from '../../assets/icons/tab_item-2.svg';
import tabItem3IC from '../../assets/icons/tab_item-3.svg';
import scrollToIC from '../../assets/icons/scroll-to.svg';
import './style.scss';
import { Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

const icons = [tabItem1IC, tabItem2IC, tabItem3IC];

const FaqTabItem = ({ title, count, index, loading, id }) => {
  const { t } = useTranslation();
  return (
    <div className="tab-item">
      <img src={icons[index]} alt="123" className="tab-item__img" />
      <div className="tab-item__text-wrapper">
        <h2 className="tab-item__text-wrapper__title">{title}</h2>
        <p className="tab-item__text-wrapper__subtitle">
          {loading ? <Skeleton.Input size="small" /> : `${count} ${t('questions')}`}
        </p>
      </div>
      <a href={`#${id}`}>
        <img src={scrollToIC} alt="scroll-to" />
      </a>
    </div>
  );
};

export default FaqTabItem;
