import styled from 'styled-components';

export const CurrencyExchangeContainer = styled.div`
  .ant-form-item {
    margin-bottom: auto;
  }

  .currency_input_selection {
    @media screen and (max-width: 768px) {
      max-width: 220px;
    }
    @media screen and (max-width: 550px) {
      max-width: 100%;
    }
  }

  .currency__form {
    justify-content: center;
  }

  .my__currency__exchange,
  .new__currency__exchange {
    display: flex;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: 768px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    @media screen and (max-width: 550px) {
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }

    .exchange__wrapper {
      display: flex;
      text-align: right;
      justify-content: flex-end;
      margin-left: auto;
      flex-direction: row;
      margin-top: 15px;

      @media screen and (max-width: 768px) {
        flex-direction: row-reverse;
        justify-content: flex-end;
        margin-left: auto;
        margin-top: 0px;
      }

      @media screen and (max-width: 550px) {
        margin-right: auto;
        margin-left: 0px;
        margin-top: 15px;
        align-items: center;
        text-align: left;
      }
    }

    .currency {
      width: 50px;
      height: 50px;
      background-color: var(--color-warning-dark);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      margin-left: 15px;

      @media screen and (max-width: 768px) {
        margin-right: 15px;
        margin-left: 0 !important;
      }

      @media screen and (max-width: 550px) {
        width: 36px;
        height: 36px;
      }
    }

    .amount__info {
      h2 {
        font-size: 24px;
      }
    }
  }

  .new__currency__exchange {
    .exchange__wrapper {
      text-align: left;
      justify-content: flex-start;
      margin-right: auto;
      margin-left: 0 !important;
      flex-direction: row;

      @media screen and (max-width: 768px) {
        margin-left: auto !important;
        margin-right: 0px;
        text-align: right;
      }

      @media screen and (max-width: 550px) {
        margin-right: auto !important;
        margin-left: 0px !important;
        margin-top: 15px;
        text-align: left;
      }
    }

    .currency {
      margin-left: 0px;
      margin-right: 15px;
      background-color: var(--color-info);
    }
  }

  .transfer__arrow {
    display: flex;
    justify-content: center;
    bottom: -80px;
    padding: 0px !important;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .exchange__rate {
    display: flex;
    justify-content: center;
    margin: 25px auto;

    @media screen and (max-width: 768px) {
      justify-content: flex-start;
    }
  }

  .exchange__action {
    display: flex;
    justify-content: center;

    .ant-col:first-child {
      text-align: right;
    }

    .ant-btn-primary {
      width: 150px;

      @media screen and (max-width: 550px) {
        width: 100%;
      }
    }
  }

  h5 {
    color: var(--color-text-secondary);
  }
`;
