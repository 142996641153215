import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  justify-content: center;
  align-items: center;
  form {
    width: 100% !important;
    margin-top: 12px;
  }
  button {
    width: 125px;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
`;
