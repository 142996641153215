const AirplaneSVG = () => {
  return (
    <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.39649 17.571C7.7188 17.571 7.78041 17.3245 7.59559 16.7085L5.62415 10.2397L20.718 1.36816"
        fill="#C8DAEA"
      />
      <path
        d="M8.39648 17.5711C8.82774 17.5711 9.07417 17.3247 9.38221 17.0783L12.1545 14.4291L8.70452 12.3345"
        fill="#A9C9DD"
      />
      <path
        d="M8.70451 12.3343L17.0215 18.4335C17.884 18.9879 18.6233 18.6799 18.8698 17.571L22.2582 1.67619C22.5662 0.320822 21.7037 -0.295254 20.7796 0.135999L1.00356 7.77534C-0.2902 8.2682 -0.2902 9.0691 0.75713 9.37714L5.87056 10.9789L17.576 3.52442C18.1305 3.21638 18.6233 3.33959 18.2537 3.77085"
        fill="#F6FBFE"
      />
    </svg>
  );
};

export default AirplaneSVG;
