import { Button } from 'antd';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CustomInput from '../../Components/UI/CustomInput/CustomInput';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useRestorePassword } from '../../api/authApi';
import { restorePasswordSchema } from './schema/schema';
import './style.scss';
import { PublicWrapper } from '../../styles/style';
import { getQueryStringObj } from '../../utils/utils';
import { useTranslation } from 'react-i18next';
import useTranslatedData from '../../hooks/useTranslatedData';

const RestorePassword = () => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const restorePassword = useRestorePassword();
  const { uuid, emailCode } = getQueryStringObj();

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleResetPassword = async (data) => {
    restorePassword.mutate({ ...data, uuid, emailCode });
  };

  const restorePasswordSchemaTranslation = useTranslatedData(restorePasswordSchema);

  return (
    <PublicWrapper>
      <div className="auth-page">
        <h2 className="auth-page__title title">{t('Reset Password')}</h2>
        <div className="auth-page__form">
          <div className="form">
            <div className="card__container">
              <Controller
                control={control}
                name="password"
                rules={restorePasswordSchemaTranslation.password}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    label={t('Set New Password')}
                    value={value}
                    onChange={onChange}
                    status={errors?.password ? 'error' : ''}
                    message={errors?.password?.message}
                    placeholder={t('Enter new password')}
                    type={showPassword ? 'text' : 'password'}
                    suffix={
                      <div onClick={handleShowPassword}>
                        {showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                      </div>
                    }
                  />
                )}
              />

              {restorePassword?.error && (
                <p className="validation-error__text">{restorePassword?.error?.response?.data?.message}</p>
              )}

              <Button
                onClick={handleSubmit(handleResetPassword)}
                type="primary"
                className="login-user__btn card__submit"
                loading={restorePassword.isPending}
              >
                {t('Save')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </PublicWrapper>
  );
};

export default RestorePassword;
