import './style.scss';

const FacebookSVG = () => {
  return (
    <a href="https://www.facebook.com/MartexTrade/" target="_blank" rel="noreferrer" className="svg-link">
      <svg width="35" height="35" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M26 8.00436L22.6662 8C19.4333 8 17.3449 10.3171 17.3449 13.9073V16.6291H14V21.5545H17.3449L17.3408 32H22.0208L22.0249 21.5545H25.8629L25.8598 16.6302H22.0249V14.3207C22.0249 13.2102 22.2679 12.6484 23.604 12.6484L25.9899 12.6473L26 8.00436Z"
          fill="white"
        />
      </svg>
    </a>
  );
};

export default FacebookSVG;
