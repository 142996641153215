import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getApi, postApi } from './utils/utils';
import { SUPPORT_API } from './constant/constant';
import routes from '../utils/routes';
import { useNavigate } from 'react-router';
import fileDownload from 'js-file-download';
import { useState } from 'react';
import openNotification from '../utils/openNotification';
import { useTranslatedNotification } from '../utils/utils';

export const useAddRequest = () => {
  const navigate = useNavigate();
  const { showNotification } = useTranslatedNotification();

  return useMutation({
    mutationFn: (data) => {
      return postApi(SUPPORT_API.ADD_REQUEST, data, true);
    },
    onSuccess: (data) => {
      navigate(routes.SUPPORT.HOME + routes.SUPPORT.MY_REQUEST);
      showNotification('success', data?.data?.message || 'Request added successfully');
    },
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};
export const useReplyTicket = () => {
  const queryClient = useQueryClient();
  const { showNotification } = useTranslatedNotification();

  return useMutation({
    mutationFn: (data) => {
      return postApi(SUPPORT_API.REPLY_TICKET, data, true);
    },
    onSuccess: (data) => {
      queryClient.refetchQueries({
        queryKey: ['support', 'ticket'],
        stale: true,
      });
      showNotification('success', data?.data?.message || 'Replied successfully');
    },
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useGetSupportTickets = () => {
  return useQuery({
    queryKey: ['support', 'tickets'],
    queryFn: () => {
      return getApi(SUPPORT_API.GET_USER_TICKETS);
    },
    onSuccess: () => {},
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useGetSupportTicket = (id) => {
  return useQuery({
    queryKey: ['support', 'ticket', id],
    queryFn: () => {
      return getApi(SUPPORT_API.GET_USER_TICKET + id);
    },
    onSuccess: () => {},
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useGetTicketFile = () => {
  const [loading, setLoading] = useState({});
  const queryClient = useQueryClient();

  const getSupportFile = async (fileId, fileName) => {
    setLoading((l) => ({ ...l, [fileId]: true }));
    try {
      let file = await queryClient.fetchQuery({
        queryKey: ['support', 'file', fileId],
        queryFn: () => {
          return getApi(SUPPORT_API.GET_TICKET_FILE + fileId, { responseType: 'blob' });
        },
      });

      fileDownload(file.data, fileName);
    } catch (error) {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    } finally {
      setLoading((l) => ({ ...l, [fileId]: false }));
    }
  };

  return {
    getSupportFile,
    loading,
  };
};
