import { useEffect, useState } from 'react';

const useMediaQuery = (minWidth) => {
  const defaultValue = () => {
    const currentWindowWidth = window.innerWidth;
    const isDesiredWidth = currentWindowWidth < minWidth;
    return isDesiredWidth;
  };

  const [state, setState] = useState({
    windowWidth: window.innerWidth,
    isDesiredWidth: defaultValue(),
  });

  useEffect(() => {
    const resizeHandler = () => {
      const currentWindowWidth = window.innerWidth;
      const isDesiredWidth = currentWindowWidth < minWidth;
      setState({ windowWidth: currentWindowWidth, isDesiredWidth });
      return isDesiredWidth;
    };
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, [state.windowWidth]);

  return state.isDesiredWidth;
};

export default useMediaQuery;
