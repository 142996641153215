export const LANGUAGES = {
  Arabic: 'ar',
  Bangla: 'bn',
  Chinese: 'zh',
  English: 'en',
  Espanol: 'es',
  Filipino: 'fil',
  French: 'fr',
  Hindi: 'hi',
  Japanese: 'ja',
  Malay: 'ms',
  Persian: 'fa',
  Portuguese: 'pt',
  Russian: 'ru',
  Thai: 'th',
  Turkish: 'tr',
  Ukrainian: 'uk',
  Vietnamese: 'vi',
};
