import React from 'react';

const DemoSVG = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.3198 21.8842C22.4926 21.8842 22.6562 21.8063 22.765 21.672C22.8739 21.5377 22.9163 21.3615 22.8806 21.1924L22.5196 19.4842C22.7252 19.2853 22.8544 19.0077 22.8544 18.6989C22.8544 18.3739 22.7097 18.0857 22.485 17.8854V11.9532L21.038 12.8487V17.8854C20.8133 18.0857 20.6685 18.3739 20.6685 18.6989C20.6685 19.0077 20.7978 19.2854 21.0034 19.4842L20.6424 21.1924C20.6066 21.3616 20.6492 21.5377 20.758 21.672C20.8668 21.8062 21.0303 21.8842 21.2032 21.8842H22.3198ZM22.8102 8.49157L13.3966 2.66425C13.1209 2.49443 12.8101 2.40918 12.4993 2.40918C12.1885 2.40918 11.8775 2.49436 11.6035 2.66425L2.18974 8.49157C1.93837 8.64711 1.78571 8.92182 1.78571 9.21748C1.78571 9.5132 1.93837 9.788 2.18974 9.94379L11.6035 15.7712C11.8775 15.9412 12.1885 16.0262 12.4993 16.0262C12.8101 16.0262 13.1209 15.9411 13.3966 15.7712L22.8102 9.94379C23.0616 9.78807 23.2143 9.5132 23.2143 9.21748C23.2143 8.92182 23.0616 8.64702 22.8102 8.49157ZM12.4993 17.4732C11.9129 17.4732 11.3406 17.3103 10.8417 17.0015L5.35064 13.6021V19.4556C5.35064 21.188 8.55271 22.5904 12.4993 22.5904C16.4473 22.5904 19.6491 21.188 19.6491 19.4556V13.6021L14.1582 17.0015C13.6594 17.3103 13.0857 17.4732 12.4993 17.4732Z"
        fill="white"
      />
    </svg>
  );
};

export default DemoSVG;
