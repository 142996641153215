import { Tag } from 'antd';
import styled from 'styled-components';
import { AnsweredIcon, PendingIcon, SuccessIcon } from '../../../constants/image';

const DesignTag = styled(Tag)`
  display: flex;
  width: 110px;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  color: ${(props) =>
    props.tagType === 'Resolved'
      ? 'var(--color-success-dark)'
      : props.tagType === 'Pending'
        ? 'var(--color-warning-dark)'
        : props.tagType === 'Answered'
          ? 'var(--color-info)'
          : 'var(--color-text)'};
  border-color: ${(props) =>
    props.tagType === 'Resolved'
      ? 'var(--color-success-dark)'
      : props.tagType === 'Pending'
        ? 'var(--color-warning-dark)'
        : props.tagType === 'Answered'
          ? 'var(--color-info)'
          : 'var(--color-text)'};
  background: transparent;
  border-radius: 5px;

  svg {
    font-size: 16px;
  }
`;

const CustomTag = ({ tagType, children, ...props }) => {
  let icon = tagType === 'Resolved' ? SuccessIcon : tagType === 'Answered' ? AnsweredIcon : PendingIcon;

  return (
    <DesignTag {...{ tagType, ...props }}>
      {tagType && <img alt="icon" src={icon} />}
      {children}
    </DesignTag>
  );
};

export default CustomTag;
