const longBetAnnotation = {
  name: 'positiveTag',
  totalStep: 2,
  createPointFigures: ({ overlay, coordinates }) => {
    let text = overlay.extendData ?? '';

    const startX = coordinates[0].x - 30;
    const startY = coordinates[0].y + 10;

    return [
      {
        type: 'custom-text',
        attrs: {
          x: startX,
          y: startY,
          text: text ?? '',
          align: 'right',
          baseline: 'bottom',
        },
        styles: {
          borderRadius: 10,
          paddingLeft: 30,
          type: 'positive',
        },
        ignoreEvent: true,
      },
    ];
  },
};

export default longBetAnnotation;
