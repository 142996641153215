import { useGetSetData } from '../../hooks/useGetSetData';
import TradeChart from '../TerminalPage/components/TradeChart/TradeChart';
import { ChartContainer, ChartWrapper } from '../TerminalPage/components/TradeChart/style';
import { ChartNotAvailable, TerminalContainer } from '../TerminalPage/style';
import { ReactComponent as GraphImg } from '../../assets/icons/no-graph.svg';
import { ChartProvider } from '../../context/ChartContext';
import { useIsFetching } from '@tanstack/react-query';
import NativeEvents from './NativeEvents';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const NativePage = () => {
  const isFetching = useIsFetching({ queryKey: ['forex', 'fav'] });
  const { getForexActivePair } = useGetSetData();
  const isChartAvailable = getForexActivePair?.forexPair?.activeFromService;

  return (
    <>
      <ChartProvider>
        <TerminalContainer>
          <div className="chart-container">
            <ChartContainer>
              <ChartWrapper nativeHeight={true}>
                {isChartAvailable || isFetching ? (
                  <TradeChart></TradeChart>
                ) : getForexActivePair?.forexPair?.name ? (
                  <PairNotAvailable getForexActivePair={getForexActivePair} />
                ) : null}
                <NativeEvents />
              </ChartWrapper>
            </ChartContainer>
          </div>
        </TerminalContainer>
      </ChartProvider>
    </>
  );
};

const PairNotAvailable = ({ getForexActivePair }) => {
  const { t } = useTranslation();

  useEffect(() => {
    window?.Android?.chartLoaded();
  }, []);

  return (
    <ChartNotAvailable>
      <div className="graphImg">
        {' '}
        <GraphImg />
      </div>
      {getForexActivePair?.forexPair?.name && (
        <h4>
          “{getForexActivePair?.forexPair?.name}” {t('is not available at this moment')}
        </h4>
      )}
      <p>{t('Please choose another pair!')}</p>
    </ChartNotAvailable>
  );
};

export default NativePage;
