import { create as zustandCreate } from 'zustand';
import { persist } from 'zustand/middleware';

const storeResetFns = new Set();
const localStorageKeys = new Set();
const sessionStorageKeys = new Set();

export const create = (stateCreator, options = {}) => {
  const { name, initialState, skipReset, excludedKeys = [], storageType, version = 0 } = options;

  // Determine storage type
  const storage = storageType === 'sessionStorage' ? sessionStorage : localStorage;

  // Track the storage key
  if (name) {
    if (storageType === 'sessionStorage') {
      sessionStorageKeys.add(name);
    } else {
      localStorageKeys.add(name);
    }
  }

  // Create the store with persist middleware if name is provided
  const store = name
    ? zustandCreate(
        persist(
          (set, get) => ({
            ...stateCreator(set, get),
          }),
          {
            name,
            getStorage: () => storage,
            partialize: (state) => {
              // Persist all state except functions
              const partialState = {};
              Object.keys(state).forEach((key) => {
                if (typeof state[key] !== 'function') {
                  partialState[key] = state[key];
                }
              });
              return partialState;
            },
            version,
          },
        ),
      )
    : zustandCreate(stateCreator);

  storeResetFns.add(() => {
    if (!skipReset) {
      const initState = { ...initialState };
      const existingData = storage.getItem(name);

      if (existingData) {
        const { state, version: storedVersion } = JSON.parse(existingData) || {};
        const updatedData = { ...initState };

        // Include excluded keys from storage
        excludedKeys.forEach((key) => {
          if (state[key] !== undefined) {
            updatedData[key] = state[key];
          }
        });

        if (storedVersion === version) {
          // Re-add functions from the state creator
          const recreatedState = stateCreator(store.setState, store.getState);
          store.setState(
            {
              ...recreatedState,
              ...updatedData,
            },
            true,
          );

          // Update storage with initial values
          storage.setItem(name, JSON.stringify({ state: updatedData, version }));
        } else {
          // Handle version mismatch if necessary
          store.setState(initState, true); // Reset state to initial
          storage.setItem(name, JSON.stringify({ state: initState, version }));
        }
      } else {
        store.setState(initState, true); // Reset state to initial
        storage.setItem(name, JSON.stringify({ state: initState, version }));
      }
    }
  });

  return store;
};

export const resetAll = () => {
  storeResetFns.forEach((reset) => reset());
};

export const clearAllStorage = () => {
  localStorageKeys.forEach((key) => localStorage.removeItem(key));
  sessionStorageKeys.forEach((key) => sessionStorage.removeItem(key));
};
