// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  var lastTime = new Date().getTime();
  var checkInterval = 1500;

  setInterval(function () {
    var currentTime = new Date().getTime();

    if (currentTime > lastTime + checkInterval * 2) {
      postMessage('wakeup');
    }

    lastTime = currentTime;
  }, checkInterval);
};
