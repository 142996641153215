import { Button, Col, Row } from 'antd';
import CustomModal from '../UI/CustomModal';
import CustomInput from '../UI/CustomInput/CustomInput';
import CustomSelect from '../UI/CustomSelect/CustomSelect';
import { CurrencyExchangeContainer } from './style';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right 2.svg';
import SecondaryButton from '../UI/SecondaryButton';
import { useTranslation } from 'react-i18next';
import { useGetSetData } from '../../hooks/useGetSetData';
import { useGetCurrency } from '../../api/commonApi';
import { useConvertCurrencyOverview } from '../../api/tradeApi';
import { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useUpdateProfileDetails } from '../../api/profileApi';
import { useTrade } from '../../store/useTrade';
import { formatNumericValues } from '../../utils/utils';

const ChangeCurrency = ({ isOpen, setIsOpen, ...props }) => {
  const { t } = useTranslation();
  const { activeTradingAccount, refetchTradingAccount } = useGetSetData();
  const { data: { data: currencyList = [] } = {} } = useGetCurrency();
  const { mutate, data: { data: { data: targetCurrency = {} } = {} } = {}, isPending } = useConvertCurrencyOverview();
  const [targetCurr, setTargetCurr] = useState('');
  const { setInvestmentValue } = useTrade();
  const { mutate: updateCurrency, isPending: isConverting } = useUpdateProfileDetails({
    onSuccess: () => {
      setIsOpen(false);
      refetchTradingAccount();
      setInvestmentValue(0);
    },
    message: 'Currency exchanged successfully',
  });

  const handleChangeCurrency = (curr) => {
    setTargetCurr(curr);
    mutate({ targetCurrency: curr });
  };

  const currencyOptions = useMemo(
    () =>
      currencyList
        .filter((curr) => curr.short !== activeTradingAccount?.currency)
        .map((c) => ({ value: c.short, label: c.short })),
    [currencyList, activeTradingAccount?.currency],
  );

  const handleUpdateCurrency = () => {
    updateCurrency({ currency: targetCurr });
  };

  useEffect(() => {
    if (currencyOptions.length) {
      setTargetCurr(currencyOptions[0].value);
      handleChangeCurrency(currencyOptions[0].value);
    }
  }, [currencyOptions]);

  return (
    <CustomModal
      open={isOpen}
      footer={null}
      title={t('Change Currency')}
      onCancel={() => setIsOpen(false)}
      {...props}
      width={790}
    >
      <CurrencyExchangeContainer>
        <Row gutter={[36, 36]} className="currency__form">
          <Col xs={24} sm={24} md={11} className="my__currency__exchange">
            <CustomInput
              label={t('My Currency')}
              formClassName="currency_input_selection"
              readOnly
              value={activeTradingAccount?.currency}
            />
            <div className="exchange__wrapper">
              <div className="amount__info">
                <h5> {t('Your are exchanging')} : </h5>
                <h2>{formatNumericValues(+activeTradingAccount?.balance)}</h2>
              </div>
              <div className="currency">{activeTradingAccount?.currencySymbol}</div>
            </div>
          </Col>
          <Col className="transfer__arrow">
            <ArrowRight />
          </Col>
          <Col xs={24} sm={24} md={11} className="new__currency__exchange">
            <CustomSelect
              label={t('New Currency')}
              formClassName="currency_input_selection"
              fullWidth={true}
              options={currencyOptions}
              onChange={(c) => handleChangeCurrency(c)}
              loading={isPending}
              value={targetCurr}
            />
            <div className="exchange__wrapper">
              <div className="currency">{targetCurrency.symbol}</div>
              <div className="amount__info">
                <h5> {t('Your are exchanging')} : </h5>
                {targetCurrency.symbol && (
                  <h2>
                    {formatNumericValues(
                      ((100 - targetCurrency.currencyConversionFeePercentage) / 100) *
                        +activeTradingAccount?.balance *
                        +targetCurrency.exchangeRate,
                    )}
                  </h2>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={[36, 36]} className="exchange__rate">
          <Col>
            <h5>
              {' '}
              {t('Exchange Fee is') +
                ` ${targetCurrency.currencyConversionFeePercentage}%. 1 ${targetCurrency.currency} = ${targetCurrency.exchangeRate} ${targetCurrency.targetCurrency}`}
            </h5>
          </Col>
        </Row>
        <Row gutter={[12, 12]} className="exchange__action">
          <Col span={12}>
            <Button
              type="primary"
              onClick={handleUpdateCurrency}
              loading={isConverting}
              disabled={isPending || isConverting}
            >
              {t('Yes, Proceed')}
            </Button>
          </Col>
          <Col span={12}>
            {' '}
            <SecondaryButton
              type="primary"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {t('Cancel')}
            </SecondaryButton>
          </Col>
        </Row>
      </CurrencyExchangeContainer>
    </CustomModal>
  );
};

export default ChangeCurrency;
