import React from 'react';

const SupportSVG = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 2.0835V9.47933C13.75 9.896 14.5833 11.0418 14.5833 12.396C14.5833 12.9168 14.4792 13.4377 14.1667 13.8543L16.25 15.521C16.3542 15.521 16.4583 15.4168 16.6667 15.4168C17.2917 15.4168 17.7083 15.8335 17.7083 16.4585C17.7083 17.0835 17.2917 17.5002 16.6667 17.5002C16.0417 17.5002 15.625 17.0835 15.625 16.4585V16.3543L13.5417 14.6877C13.0208 15.2085 12.2917 15.521 11.4583 15.521C9.68751 15.521 8.33334 14.1668 8.33334 12.396C8.33334 11.0418 9.16667 9.896 10.4167 9.47933V2.18766H10.3125C6.66667 2.60433 3.64584 5.62516 3.22917 9.37516C2.91667 11.6668 3.54167 13.7502 4.79167 15.4168C5.72917 16.6668 6.25001 18.021 6.25001 19.5835V22.9168H15.625V19.7918H17.7083C18.8542 19.7918 19.7917 18.8543 19.7917 17.7085V14.5835L21.3542 13.9585C21.7708 13.7502 21.9792 13.1252 21.7708 12.7085L19.7917 9.5835C19.375 5.72933 16.3542 2.60433 12.5 2.0835ZM11.4583 10.9377C10.625 10.9377 9.89584 11.6668 9.89584 12.5002C9.89584 13.3335 10.625 14.0627 11.4583 14.0627C12.2917 14.0627 13.0208 13.3335 13.0208 12.5002C13.0208 11.6668 12.2917 10.9377 11.4583 10.9377Z"
        fill="white"
      />
    </svg>
  );
};

export default SupportSVG;
