import React from 'react';
import './style.scss';
import TextArea from 'antd/es/input/TextArea';
import { Form } from 'antd';

const CustomTextarea = ({ label = '', status, message, ...props }) => {
  return (
    <div className="modal-form__textarea">
      {label && <label className="modal-form__textarea-label ">{label}</label>}
      <Form.Item validateStatus={status} help={message}>
        <TextArea rows={4} type="text" className="modal-form__textarea-value" {...props} />
      </Form.Item>
    </div>
  );
};

export default CustomTextarea;
