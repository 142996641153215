import { Form, Upload, message } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { useTranslation } from 'react-i18next';

const CustomUpload = (rest) => {
  const { t } = useTranslation();

  const props = {
    name: 'file',
    multiple: false,
    showUploadList: {
      showDownloadIcon: false,
    },
    beforeUpload: (file) => {
      const maxSize = 2 * 1024 * 1024;
      const isImageOrPdf = file.type.includes('image/') || file.type === 'application/pdf';
      const isValidSize = file.size <= maxSize;

      if (!isImageOrPdf) {
        message.error(t('You can only upload image or PDF files!'));
        return Upload.LIST_IGNORE;
      }
      if (!isValidSize) {
        message.error(t('You can only upload maximum 2MB file!'));
        return Upload.LIST_IGNORE;
      }

      return isImageOrPdf && isValidSize;
    },
    customRequest: ({ file, onSuccess }) => {
      onSuccess();
    },
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Form.Item label="" name="files" valuePropName="fileList" getValueFromEvent={normFile}>
      <Dragger maxCount={1} className="upload-input" {...props} {...rest}>
        <p className="upload-text">{t('Attachments')}</p>
        <p className="upload-hint">{t('Click or Drop files here')} </p>
        <p className="upload-hint">{t('Formats: jpg, jpeg, png, pdf | Max: 2Mb, 1 file')}</p>
      </Dragger>
    </Form.Item>
  );
};

export default CustomUpload;
