import { create } from 'zustand';

const initialState = {
  activeTrades: [],
};

export const useGlobalStore = create((set, get) => ({
  ...initialState,
  setActiveTrades: (trades, oldValueAppend = false) => {
    set({
      activeTrades: oldValueAppend ? [...get().activeTrades, ...trades] : trades,
    });
  },
  removeActiveTrade: (tradeId) => {
    set({
      activeTrades: get().activeTrades?.filter((t) => t.id !== tradeId),
    });
  },
}));
