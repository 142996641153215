export const hideAllPopovers = () => {
  // Simulate a click event on the body
  const event = new MouseEvent('mousedown', {
    bubbles: true,
    cancelable: true,
    view: window,
  });

  document.body.dispatchEvent(event);
};
