import React from 'react';

const ChartSVG = () => {
  return (
    <svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.0963 2.38859C18.7863 2.69369 18.7863 3.18474 18.7863 4.16683V17.7085C18.7863 18.6906 18.7863 19.1816 19.0963 19.4867C19.4063 19.7918 19.9052 19.7918 20.903 19.7918C21.9008 19.7918 22.3997 19.7918 22.7097 19.4867C23.0196 19.1816 23.0196 18.6906 23.0196 17.7085V4.16683C23.0196 3.18474 23.0196 2.69369 22.7097 2.38859C22.3997 2.0835 21.9008 2.0835 20.903 2.0835C19.9052 2.0835 19.4063 2.0835 19.0963 2.38859Z"
        fill="white"
      />
      <path
        d="M11.378 7.29183C11.378 6.30974 11.378 5.81869 11.688 5.51359C11.998 5.2085 12.4969 5.2085 13.4947 5.2085C14.4925 5.2085 14.9914 5.2085 15.3013 5.51359C15.6113 5.81869 15.6113 6.30974 15.6113 7.29183V17.7085C15.6113 18.6906 15.6113 19.1816 15.3013 19.4867C14.9914 19.7918 14.4925 19.7918 13.4947 19.7918C12.4969 19.7918 11.998 19.7918 11.688 19.4867C11.378 19.1816 11.378 18.6906 11.378 17.7085V7.29183Z"
        fill="white"
      />
      <path
        d="M4.27964 9.68009C3.96967 9.9852 3.96967 10.4762 3.96967 11.4583V17.7083C3.96967 18.6904 3.96967 19.1815 4.27964 19.4866C4.58963 19.7917 5.08852 19.7917 6.08633 19.7917C7.08414 19.7917 7.58304 19.7917 7.89302 19.4866C8.203 19.1815 8.203 18.6904 8.203 17.7083V11.4583C8.203 10.4762 8.203 9.9852 7.89302 9.68009C7.58304 9.375 7.08414 9.375 6.08633 9.375C5.08852 9.375 4.58963 9.375 4.27964 9.68009Z"
        fill="white"
      />
      <path
        d="M3.96965 22.1353C3.53128 22.1353 3.1759 22.485 3.1759 22.9165C3.1759 23.348 3.53128 23.6978 3.96965 23.6978H23.0197C23.458 23.6978 23.8134 23.348 23.8134 22.9165C23.8134 22.485 23.458 22.1353 23.0197 22.1353H3.96965Z"
        fill="white"
      />
    </svg>
  );
};

export default ChartSVG;
