import { Form, Radio } from 'antd';
import { useRef } from 'react';
import styled from 'styled-components';

const RadioWrapper = styled.div`
  border: 1px solid var(--color-border-dark);
  padding: 5px 10px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  cursor: pointer;

  .ant-radio-wrapper .ant-radio-inner {
    border-color: var(--color-text);
  }

  svg {
    margin-right: 10px;
    path {
      stroke: var(--color-text);
    }
  }

  .info {
    display: flex;
    align-items: center;
  }
`;

export const CustomRadio = ({ icon: Icon, onClick = () => {}, label, ...props }) => {
  const ref = useRef();

  const onClickRadio = () => {
    onClick(ref.current?.input);
  };

  return (
    <Form>
      <Form.Item>
        <RadioWrapper onClick={onClickRadio}>
          <div className="info">
            {Icon && <Icon />}
            {label}
          </div>
          <Radio ref={ref} {...props} />
        </RadioWrapper>
      </Form.Item>
    </Form>
  );
};
