import styled from 'styled-components';

export const PrivateLayoutWrapper = styled.div`
  padding-left: 70px;
  display: flex;
  height: 100vh;
  @media screen and (max-width: 768px) {
    padding: 0px;
  }
`;

export const PrivateLayoutAppContainer = styled.div`
  width: 100%;
  overflow: auto;
`;

export const SecondSidebarWrapper = styled.div`
  width: 245px;
  min-width: 245px;
  background-color: var(--color-grey-2);
  height: 100vh;
  position: absolute;
  left: 70px;
  z-index: 99;
  box-shadow:
    6px 0 16px 0 rgba(0, 0, 0, 0.08),
    3px 0 6px -4px rgba(0, 0, 0, 0.12),
    9px 0 28px 8px rgba(0, 0, 0, 0.05);

  @media screen and (max-width: 768px) {
    width: 100%;
    border-radius: 10px;
    height: auto;
    position: relative;
    left: 0px;
    box-shadow: none;
  }
`;

export const SidebarTabContainer = styled.div`
  padding: 20px;

  @media screen and (max-width: 768px) {
    height: calc(100vh - 83px);
  }
`;
