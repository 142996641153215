import React, { useEffect } from 'react';
import { Empty, Table } from 'antd';
import styled from 'styled-components';
import { Container, EmptyContainer, Spinner } from './style';
import { useTranslation } from 'react-i18next';

const CustomTable = (props) => {
  const [currentPage, setCurrentPage] = React.useState(props?.currentPage || 1);
  const { t } = useTranslation();

  const handlePageChange = (page) => {
    setCurrentPage(page);
    props?.onPageChange?.(page);
  };

  useEffect(() => {
    if (props?.currentPage) {
      setCurrentPage(props?.currentPage);
    }
  }, [props?.currentPage]);

  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <div>{t('Previous')}</div>;
    }
    if (type === 'next') {
      return <div>{t('Next')}</div>;
    }
    return originalElement;
  };

  const CustTable = styled(Table)`
    thead {
      tr {
        th {
          border-bottom: 1px solid var(--color-border-dark) !important;
        }
      }
    }

    tbody {
      tr {
        td {
          border-bottom: 1px solid var(--color-border-dark) !important;
        }
      }
    }
    .ant-pagination {
      .anticon.anticon-double-right.ant-pagination-item-link-icon {
        color: var(--color-text);
      }
      .anticon.anticon-double-left.ant-pagination-item-link-icon {
        color: var(--color-text);
      }
      li {
        margin: 0;
      }
      .ant-pagination-item-link {
        border-color: var(--color-grey-7);
      }
      .ant-pagination-prev {
        padding: 0px 15px;
        border: 1px solid var(--color-grey-7);
        border-radius: 5px 0px 0px 5px;
      }
      .ant-pagination-next {
        padding: 0px 15px;
        border: 1px solid var(--color-grey-7);
        border-radius: 0px 5px 5px 0px;
      }
      .ant-pagination-item {
        border-radius: 0px;
        border-color: var(--color-grey-7);

        a {
          font-weight: 400;
          color: var(--color-text);
        }
      }
      .ant-pagination-item-active {
        border-left: none;
        border-right: none;
        border-color: none;
        a {
          font-weight: 400;
          color: #ffffff;
        }
      }
      .ant-pagination-disabled .ant-pagination-item-link {
        font-weight: 400;
        color: var(--color-text);
      }
      .ant-pagination-options {
        display: none;
      }
      .ant-pagination-item-ellipsis {
        color: var(--color-text) !important;
      }
    }
  `;

  const paginationProps = {
    itemRender: itemRender,
    current: currentPage,
    pageSize: props?.pageLimit || 10,
    total: props?.totalData,
    onChange: handlePageChange,
  };

  if (props?.dataSource?.length === 0 && !props?.loading) {
    return <EmptyContainer image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  if (props?.loading) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  return <CustTable {...props} pagination={paginationProps} loading={false} />;
};

export default CustomTable;
