import React, { forwardRef } from 'react';
import './style.scss';
import { Form, Input } from 'antd';

const CustomInput = forwardRef(
  ({ label = '', style, status = 'success', formClassName = '', message, className = '', ...props }, ref) => {
    return (
      <Form className={`modal-form__input ${formClassName}`} style={style}>
        {label && <label className="modal-form__input-label">{label}</label>}
        <Form.Item validateStatus={status} help={message}>
          {/* Make sure ref is passed to the Input */}
          <Input {...props} ref={ref} className={'modal-form__input-value ' + className} />
        </Form.Item>
      </Form>
    );
  },
);

export default CustomInput;
