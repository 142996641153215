import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FOREX_PAIRS } from './constant/constant';
import { deleteApi, getApi, postApi } from './utils/utils';
import openNotification from '../utils/openNotification';
import { getQueryStringFromObj, useTranslatedNotification } from '../utils/utils';

export const useGetForexPairsList = ({ queryKey = {} } = {}) => {
  const queryString = getQueryStringFromObj(queryKey);
  return useQuery({
    queryKey: ['forex', 'pairs', ...Object.values(queryKey)],
    queryFn: () => {
      return getApi(FOREX_PAIRS.LIST + queryString);
    },
    onSuccess: () => {},
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useGetFavForexPairs = ({ ...props } = {}) => {
  return useQuery({
    queryKey: ['forex', 'fav'],
    queryFn: () => {
      return getApi(FOREX_PAIRS.FAV_LIST);
    },
    onSuccess: () => {},
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
    ...props,
  });
};

export const useAddToForexPair = () => {
  const queryClient = useQueryClient();
  const { showNotification } = useTranslatedNotification();

  return useMutation({
    mutationFn: (data) => {
      return postApi(FOREX_PAIRS.ADD_TO_FAV_LIST, data);
    },
    onSuccess: ({ data }) => {
      queryClient.setQueryData(['forex', 'fav'], (oldData) => {
        return { ...oldData, data: data };
      });
      showNotification('success', 'Added to favourites');
    },
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useDeleteForexPairFav = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => {
      return deleteApi(FOREX_PAIRS.REMOVE_FROM_FAV_LIST, data);
    },
    onSuccess: ({ data }) => {
      queryClient.setQueryData(['forex', 'fav'], (oldData) => {
        return { ...oldData, data: data };
      });
    },
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useForexHistory = () => {
  return useMutation({
    mutationKey: ['forex', 'history'],
    mutationFn: (data) => {
      return postApi(FOREX_PAIRS.FOREX_HISTORY, data);
    },
    cacheTime: Infinity,
    refetchOnWindowFocus: true,
    onSuccess: ({ data: { data: history = [] } = {} }, variables) => {
      if (variables?.lastTime) {
        window.chartRef.applyMoreData(history.reverse());
      }
    },
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};
