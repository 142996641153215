import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useGetProfileDetails } from '../api/profileApi';
import { Spin } from 'antd';
import routes from '../utils/routes';
import { Spinner } from './style';

const SponsoreProtectRoute = ({ children, ...rest }) => {
  const { data: { data: getProfileData = {} } = {}, isLoading } = useGetProfileDetails();

  const location = useLocation();

  if (location.pathname.includes(routes.PROFILE.HOME + routes.PROFILE.DEPOSIT)) {
    return isLoading ? (
      <Spinner>
        <Spin />
      </Spinner>
    ) : getProfileData.sponsored ? (
      <Navigate to={routes.TERMINAL.HOME} />
    ) : (
      children
    );
  } else {
    return children;
  }
};

export default SponsoreProtectRoute;
