import styled from 'styled-components';

export const OverlayToolMenuItem = styled.div`
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 4px 4px 4px 10px;
  background-color: var(--color-grey-2);
  display: flex;
  border-radius: 6px;
  align-items: center;

  .tool_title {
    margin-right: 5px;
    color: var(--color-text);
    font-size: 12px;
    line-height: 2;
    font-weight: 400;
  }
  .ant-btn {
    width: 24px;
    height: 24px;
    padding: 0px !important;
    border-left: 1px solid var(--color-grey-1);
    border-radius: 0px 6px 6px 0px;

    &:hover {
      background: var(--color-grey-1) !important;
    }
  }
`;

export const OverlayToolMenuContainer = styled.div`
  right: 150px;
  top: 150px;
  position: absolute;
  z-index: 50;
  max-width: 55%;
  flex-wrap: wrap;
  display: flex;
  gap: 5px;
  justify-content: end;

  @media screen and (max-width: 1300px) {
    max-width: 100%;
  }
`;
