/* eslint-disable no-console */
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getApi, postApi, putApi } from './utils/utils';
import { USER_TRADING } from './constant/constant';
import openNotification from '../utils/openNotification';
import { downloadCSV, objectToQueryString, useTranslatedNotification } from '../utils/utils';
import _ from 'lodash';

export const useGetTradeAccountDetails = ({ ...props } = {}) => {
  let result = useQuery({
    queryKey: ['trade', 'account', 'details'],
    queryFn: () => {
      return getApi(USER_TRADING.USER_ACCOUNT);
    },
    staleTime: Infinity,

    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
    ...props,
  });

  return result;
};

export const useSwitchTradingAccount = ({ successMessage = true } = {}) => {
  const queryClient = useQueryClient();
  const { showNotification } = useTranslatedNotification();

  return useMutation({
    mutationFn: (data) => {
      return putApi(USER_TRADING.ACCOUNT_SWITCH, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['trade', 'history'], refetchType: 'active' });
      queryClient.invalidateQueries({ queryKey: ['trade', 'active'], refetchType: 'active' });
      queryClient.invalidateQueries({ queryKey: ['trade', 'account', 'details'], refetchType: 'active' });
      if (successMessage) {
        showNotification('success', 'Account switched successfully');
      }
    },
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useGetActiveTrade = ({ pairId = '' } = {}, allTrades = false) => {
  return useQuery({
    queryKey: ['trade', 'active', ...(pairId ? [pairId] : [])],
    queryFn: () => {
      return getApi(USER_TRADING.ACTIVE_TRADE + (pairId ? `?pairId=${pairId}` : ''));
    },
    staleTime: 0,
    onSuccess: () => {},
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
    enabled: Boolean(pairId) || allTrades,
  });
};

export const useEnterTrade = () => {
  return useMutation({
    mutationFn: (data) => {
      return postApi(USER_TRADING.ENTER_TRADE, data);
    },
    onSuccess: () => {
      // openNotification('success', { title: 'Account switched successfully' });
    },
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useResetDemoBalance = () => {
  return useMutation({
    mutationFn: (data) => {
      return postApi(USER_TRADING.RESET_DEMO_BALANCE, data);
    },
    onSuccess: () => {
      // openNotification('success', { title: 'Account switched successfully' });
    },
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useResetLiveBalance = () => {
  return useMutation({
    mutationFn: (data) => {
      return postApi(USER_TRADING.RESET_LIVE_BALANCE, data);
    },
    onSuccess: () => {},
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useConvertCurrencyOverview = () => {
  return useMutation({
    mutationFn: (data) => {
      return postApi(USER_TRADING.CHANGE_CURRENCY_OVERVIEW, data);
    },
    onSuccess: () => {
      // openNotification('success', { title: 'Currency exchanged successfully' });
    },
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useGetActiveTradeHistoryInfinite = () => {
  return useInfiniteQuery({
    queryKey: ['trade', 'history'],
    queryFn: async ({ pageParam = '' }) => {
      const res = await getApi(USER_TRADING.TRADE_HISTORY + (pageParam ? `/?lastId=${pageParam}` : ''));
      return res?.data?.data;
    },
    staleTime: 0,
    onSuccess: () => {},
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
    getNextPageParam(lastPage) {
      return lastPage.length > 0 ? lastPage.at(-1).id : undefined;
    },
  });
};

export const useGetActiveTradeHistory = (filterData = {}) => {
  let filtered = _.pickBy(filterData, (value) => !!value);
  return useQuery({
    queryKey: ['trade', 'history', filtered],
    queryFn: async () => {
      const res = await getApi(`${USER_TRADING.TRADE_HISTORY}?${objectToQueryString(filterData)}`);
      return res?.data;
    },
    onSuccess: () => {},
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useGetActiveTradeHistoryMutation = () => {
  return useMutation({
    mutationFn: async (filterData) => {
      const res = await getApi(`${USER_TRADING.TRADE_HISTORY}?${objectToQueryString(filterData)}`);
      return res?.data;
    },
    onSuccess: () => {},

    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useGetWithdrawalLists = (offset) => {
  return useQuery({
    queryKey: ['list', 'allwithdrawals', offset],
    queryFn: async () => {
      const res = await getApi(`${USER_TRADING?.WITHDRAWAL_LIST}?offset=${offset}`);
      return res?.data;
    },

    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useGetCountryDocumentType = () => {
  return useQuery({
    queryKey: ['country', 'document'],
    queryFn: async () => {
      const res = await getApi(USER_TRADING?.COUNTRY_DOCUMENT_TYPES);
      return res?.data;
    },

    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useGetTradeHistoryExportType = (filterData) => {
  const exportData = async () =>
    getApi(`${USER_TRADING?.EXPORT}?${objectToQueryString(filterData)}`)
      .then((data) => {
        downloadCSV(data?.data, 'TradeHistory', 'text/csv;charset=utf-8;');
      })
      .catch((error) => {
        openNotification('error', { title: error?.response?.data?.message || error?.message });
      });

  return exportData;
};
