import styled from 'styled-components';

const FAQWrapper = styled.section`
  background: var(--color-grey-2);
  border-radius: 10px;
`;
const FAQContainer = styled.section`
  max-width: 1200px;

  .faq-page-container {
    padding: 15px 30px;
  }
`;

export { FAQWrapper, FAQContainer };
