import styled from 'styled-components';
import Logo1 from '../../../assets/icons/result.svg';

export const DefaultNavbarContainer = styled.header`
  width: 100%;
  padding: 20px 10%;
  border-bottom: 2px solid var(--color-border-dark);

  @media screen and (max-width: 1280px) {
    padding: 20px 70px;
  }

  @media screen and (max-width: 768px) {
    padding: 20px;
  }

  .container {
    display: flex;
    align-items: center;
    margin: 0 auto;
    font-weight: 500;
    max-width: 1440px;
    justify-content: center;

    .first-section {
      display: flex;
      gap: 35px;
      align-items: center;
      .nav-tag {
        display: flex !important;
        gap: 30px !important;
        @media screen and (max-width: 992px) {
          display: none !important;
        }
      }
      img {
        -webkit-user-select: none; /* For Safari */
        user-select: none;
        width: 100%;
        height: 100%;
      }
      .logo-link {
        display: flex;
        align-items: center;
        cursor: pointer;
        outline: none;
        color: var(--color-text);
        font-size: 19px;
        gap: 10px;
        background-repeat: no-repeat;
        width: 180px !important;
        height: 30px !important ;
        background-size: cover;

        @media screen and (max-width: 468px) {
          background-image: url(${Logo1});
          width: 40px !important;
          height: 40px !important;
        }
      }
    }
    .second-section {
      nav {
        @media screen and (max-width: 992px) {
          display: none;
        }
      }
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1;
      gap: 30px;
      @media screen and (max-width: 767px) {
        gap: 11.1px;
      }
      .header__button {
        padding: 12px 26px;
        border-radius: 10px;
        border: 2px solid var(--color-border-dark);
        width: 121px;
        // height: 57px;
        text-align: center;
        @media screen and (min-width: 768px) and (max-width: 991px) {
          padding: 13px 15px;
          color: var(--color-text);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        @media screen and (max-width: 767px) {
          padding: 10px 15px;
          font-size: 0.875rem;
          border-radius: 8px;
          border: 2px solid var(--color-border-dark);
        }
        &.signup {
          background: var(--color-success);
          border-color: var(--color-success);
        }
      }
      .globe {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
        @media screen and (max-width: 870px) {
          display: none;
        }
        .arrow {
          width: 13px;
          height: 13px;
        }

        .ant-form-item {
          min-width: 150px !important;
          margin-bottom: 0px;
        }

        .modal-form__input-label {
          background: var(--color-grey-1) !important;
        }
      }
      .login {
        @media screen and (max-width: 767px) {
          width: 93.949px;
          height: 44.412px;
        }
      }
      .signup {
        @media screen and (max-width: 767px) {
          width: 93.949px;
          height: 44.412px;
        }
      }
    }
  }
`;

export const NavbarMobileMenu = styled.div`
  display: none;
  @media screen and (max-width: 991px) {
    display: block;
  }
`;

export const DropdownContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  display: none;
  @media screen and (max-width: 991px) {
    display: block;
  }
  .option {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
    border-top: 1px solid var(--color-grey-2);
  }
  .lastChild {
    padding-bottom: 0;
    .ant-form-item {
      min-width: 150px !important;
      margin-bottom: 0px;
    }

    .modal-form__input-label {
      background: var(--color-grey-1) !important;
    }
  }
`;
