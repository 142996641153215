import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export const timerInSeconds = 60;

export const useTimerStore = create(
  persist(
    (set, get) => ({
      timer: timerInSeconds,
      reset: () => set({ timer: timerInSeconds }),
      setTime: (time) => set({ timer: time }),
    }),
    {
      name: 'timer', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    },
  ),
);
