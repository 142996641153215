const positiveSegment = {
  name: 'positiveSegment',
  totalStep: 2,
  createPointFigures: ({ overlay, coordinates, bounding }) => {
    const startX = coordinates[0].x;
    const startY = coordinates[0].y;
    const startX1 = coordinates[1].x < 0 || coordinates[1].x < coordinates[0].x ? bounding?.width : coordinates[1].x;
    const startY1 = coordinates[1].y;

    const isRay = Boolean(coordinates[1].x < 0 || coordinates[1].x < coordinates[0].x);

    return [
      {
        type: 'line',
        attrs: {
          coordinates: [
            {
              x: startX,
              y: startY,
            },
            {
              x: startX1,
              y: startY1,
            },
          ],
        },
        styles: {
          color: 'green',
          borderSize: 3,
        },
        ignoreEvent: true,
      },
      {
        type: 'custom-circle',
        attrs: {
          x: startX,
          y: startY,
        },
        styles: {
          style: 'stroke-fill',
          borderColor: 'green',
        },
        ignoreEvent: true,
      },
      ...(!isRay
        ? [
            {
              type: 'custom-circle',
              attrs: {
                x: startX1,
                y: startY1,
              },
              styles: {
                style: 'stroke-fill',
                borderColor: 'green',
              },
              ignoreEvent: true,
            },
          ]
        : []),
    ];
  },
};

export default positiveSegment;
