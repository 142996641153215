import { useChartAction } from '../store/useChartAction';

const useHandleIndicator = () => {
  const { setIndicators } = useChartAction();

  const createChartIndicator = ({ id, name, noOfDecimal, settings = [] }) => {
    window.chartRef.createIndicator(
      { name: name, precision: noOfDecimal, ...(settings.length && { calcParams: settings }) },
      id.includes('main'),
      {
        id: id.includes('main') ? 'candle_pane' : id,
      },
    );
    setIndicators('add', { name, id, settings });
  };

  const removeChartIndicator = ({ id, name, indicatorId }) => {
    window.chartRef.removeIndicator(indicatorId, name);
    setIndicators('remove', { id, name });
  };

  const removeChartIndicatorWithIcon = ({ name, indicatorId }) => {
    window.chartRef.removeIndicator(indicatorId, name);
    setIndicators('remove', { id: indicatorId === 'candle_pane' ? `main${name}` : `sub${name}`, name });
  };

  const overrideIndicatorWithIcon = ({ name, paneId, settings = [] }) => {
    if (settings.length) {
      window.chartRef.overrideIndicator({ name, calcParams: settings }, paneId);
      setIndicators('add', { id: paneId === 'candle_pane' ? `main${name}` : `sub${name}`, name, settings });
    }
  };

  return {
    createChartIndicator,
    removeChartIndicator,
    removeChartIndicatorWithIcon,
    overrideIndicatorWithIcon,
  };
};

export default useHandleIndicator;
