export function transparent(color) {
  return color === 'transparent';
}

function isString(value) {
  return typeof value === 'string';
}

const DEVIATION = 2;

export function checkCoordinateOnRect(coordinate, rect) {
  let x = rect.x;
  let width = rect.width;
  if (width < DEVIATION * 2) {
    x -= DEVIATION;
    width = DEVIATION * 2;
  }
  let y = rect.y;
  let height = rect.height;
  if (height < DEVIATION * 2) {
    y -= DEVIATION;
    height = DEVIATION * 2;
  }

  return coordinate.x >= x && coordinate.x <= x + width && coordinate.y >= y && coordinate.y <= y + height;
}

export function drawRect(ctx, attrs, styles) {
  const { x, y, width: w, height: h } = attrs;
  const {
    style = 'fill',
    color = 'green',
    borderSize = 1,
    borderColor = 'transparent',
    borderStyle = 'solid',
    borderRadius: r = 0,
    borderDashedValue = [2, 2],
  } = styles;

  if (style === 'fill' || styles.style === 'stroke-fill') {
    let draw = true;
    if (isString(color)) {
      draw = !transparent(color);
    }
    if (draw) {
      ctx.fillStyle = color;
      ctx.beginPath();
      ctx.moveTo(x + r, y);
      ctx.arcTo(x + w, y, x + w, y + h, r);
      ctx.arcTo(x + w, y + h, x, y + h, r);
      ctx.arcTo(x, y + h, x, y, r);
      ctx.arcTo(x, y, x + w, y, r);
      ctx.closePath();
      ctx.fill();
    }
  }
  if ((style === 'stroke' || styles.style === 'stroke-fill') && !transparent(borderColor) && borderSize >= 0) {
    ctx.strokeStyle = borderColor;
    ctx.lineWidth = borderSize;
    if (borderStyle === 'dashed') {
      ctx.setLineDash(borderDashedValue);
    } else {
      ctx.setLineDash([]);
    }
    ctx.beginPath();
    ctx.moveTo(x + r, y);
    ctx.arcTo(x + w, y, x + w, y + h, r);
    ctx.arcTo(x + w, y + h, x, y + h, r);
    ctx.arcTo(x, y + h, x, y, r);
    ctx.arcTo(x, y, x + w, y, r);
    ctx.closePath();
    ctx.stroke();
  }
}

const rect = {
  name: 'rect',
  checkEventOn: checkCoordinateOnRect,
  draw: (ctx, attrs, styles) => {
    drawRect(ctx, attrs, styles);
  },
};

export default rect;
