import React from 'react';
import MaintenanceImage from '../../assets/images/demodesktop.jpeg';
import CustomAnnouncement from '../../Components/UI/CustomAnnouncement/CustomAnnouncement';
import { Container } from './style';
import { useTranslation } from 'react-i18next';

const UnderMaintenance = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <CustomAnnouncement
        open={true}
        header={t('Under Maintenance')}
        text={t(
          'Maintenance in progress! We are making upgrades to serve you better. Thank you for your understanding and patience during this time.',
        )}
        image={MaintenanceImage}
      />
    </Container>
  );
};

export default UnderMaintenance;
