export const schema = (t) => {
  return {
    email: {
      required: t('Email is required'),
      pattern: {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: t('Valid email please'),
      },
    },
    password: {
      required: t('Password is required'),
      minLength: {
        value: 8,
        message: t('Min length 8'),
      },
    },
  };
};
