import { useTranslation } from 'react-i18next';
import { useGetLanguages } from '../../api/commonApi';
import CustomSelect from '../UI/CustomSelect/CustomSelect';

const LanguageSelect = ({ loading, ...props }) => {
  const { t } = useTranslation();
  const { data: { data: language = [] } = {}, isLoading: loadingLanguage } = useGetLanguages();

  const languageOptions = language.map((l) => ({
    value: l,
    label: l,
  }));

  return (
    <CustomSelect label={t('Language')} options={languageOptions} loading={loadingLanguage || loading} {...props} />
  );
};

export default LanguageSelect;
