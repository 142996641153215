import React, { useCallback } from 'react';
import PrivateNavbar from '../Navbars/PrivateNavbar/PrivateNavbar';
import Sidebar from '../Sidebar/Sidebar';
import useMediaQuery from '../../hooks/useMediaQuery';
import TerminalSettings from '../../Pages/TerminalSettings';
import LeaderBoard from '../../Pages/LeaderBoard';
import { useLocation } from 'react-router-dom';
import { hashRoutes } from '../../utils/routes';
import { PrivateLayoutAppContainer, PrivateLayoutWrapper, SecondSidebarWrapper, SidebarTabContainer } from './style';

const PrivateLayout = ({ children }) => {
  const location = useLocation();

  const TABS = [hashRoutes.SETTINGS, hashRoutes.LEADER_BOARD];

  const renderSideBar = useCallback(() => {
    switch (location.hash) {
      case hashRoutes.SETTINGS:
        return <TerminalSettings />;
      case hashRoutes.LEADER_BOARD:
        return <LeaderBoard />;
      default:
        return <TerminalSettings />;
    }
  }, [location.hash]);

  const isSecondSidebarActive = () => {
    return TABS.includes(location.hash);
  };

  const isTab = useMediaQuery(769);

  return location.pathname !== '/native-chart' ? (
    <PrivateLayoutWrapper>
      {' '}
      {!isTab && <Sidebar />}
      {!isTab && isSecondSidebarActive() && <SecondSidebarWrapper>{renderSideBar()}</SecondSidebarWrapper>}
      <PrivateLayoutAppContainer>
        <PrivateNavbar />
        {isTab && isSecondSidebarActive() ? (
          <SidebarTabContainer>
            <SecondSidebarWrapper>{renderSideBar()}</SecondSidebarWrapper>
          </SidebarTabContainer>
        ) : (
          children
        )}
      </PrivateLayoutAppContainer>
    </PrivateLayoutWrapper>
  ) : (
    <>{children}</>
  );
};

export default React.memo(PrivateLayout);
