export const ACCOUNT_TYPE = {
  LIVE: 'Live',
  DEMO: 'Demo',
};

export const accountOptions = (t) => [
  { label: t(ACCOUNT_TYPE.LIVE), value: ACCOUNT_TYPE.LIVE },
  { label: t(ACCOUNT_TYPE.DEMO), value: ACCOUNT_TYPE.DEMO },
];

export const VERIFICATION_STATUS = {
  UNDER_PROCESS: 'Verification Under Process',
  VERIFIED: 'Verified',
  REJECTED: 'Rejected',
  NOT_VERIFIED: 'Not Verified',
};

export const LEVELS = {
  Standard: 'STANDARD',
  Premium: 'PREMIUM',
  Vip: 'VIP',
};
