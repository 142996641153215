import styled from 'styled-components';

export const PrivacyPolicyContainer = styled.div`
  padding: 40px 80px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #000;
  background: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;

  .privacy-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #000;
    padding-bottom: 15px;
    margin-bottom: 10px;
    align-items: center;
  }

  .logo {
    max-width: 200px;
  }

  .contactInformation {
    margin-top: 30px;
  }

  h2 {
    font-weight: 500;
    color: #2c3e50;
  }

  p {
    padding-left: 15px;
  }

  @media screen and (max-width: 767px) {
    padding: 20px;

    p {
      font-size: 0.875rem;
    }

    h2 {
      font-size: 1.2rem;
    }

    h1 {
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 468px) {
    .logo {
      max-width: 36px;
    }
  }
`;
