import { create } from './clearStore';
import { INVESTMENT_TIME_TYPE, TIME_SPAN } from '../utils/constants';
import moment from 'moment-timezone';

const initialState = {
  trades: {},
  investmentTime: TIME_SPAN[4].value,
  investmentValue: 0,
  investmentTimeType: INVESTMENT_TIME_TYPE.CURRENT,
  investmentCurrentTime: moment().unix(),
};

export const useTrade = create(
  (set, get) => ({
    ...initialState,
    setTrades: (pairId, trade) => {
      set({
        trades: {
          ...get().trades,
          [pairId]: [...(get().trades?.[pairId] || []), trade],
        },
      });
    },
    removeTrade: (pairId, tradeId) => {
      set({
        trades: {
          ...get().trades,
          [pairId]: get().trades?.[pairId]?.filter((t) => t.id !== tradeId),
        },
      });
    },
    setTradesList: (pairId, trades) => {
      set({
        trades: {
          ...get().trades,
          [pairId]: trades,
        },
      });
    },
    setInvestmentTime: (time) => {
      set({
        investmentTime: time,
      });
    },
    setInvestmentValue: (time) => {
      set({
        investmentValue: time,
      });
    },
    setTradeStore: (data) => {
      set(data);
    },
    toggleInvestmentTimeType: () => {
      let diffWithCurrentMin = moment().unix() - moment().startOf('minute').unix() < 30;

      let type =
        get().investmentTimeType === INVESTMENT_TIME_TYPE.NORMAL
          ? INVESTMENT_TIME_TYPE.CURRENT
          : INVESTMENT_TIME_TYPE.NORMAL;
      set({
        investmentTimeType: type,
        investmentCurrentTime: diffWithCurrentMin
          ? moment().startOf('minute').unix()
          : moment().add(1, 'minutes').startOf('minute').unix(),
        investmentTime:
          type === INVESTMENT_TIME_TYPE.NORMAL
            ? TIME_SPAN[4].value
            : diffWithCurrentMin
              ? moment().add(1, 'minutes').startOf('minute').unix()
              : moment().add(2, 'minutes').startOf('minute').unix(),
      });
    },
  }),
  {
    name: 'tradeData',
    initialState,
    version: 1,
  },
);
