import React from 'react';

const SettingsSVG = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.9375 9.60579C19.0521 9.60579 18.2812 8.27246 19.2187 6.63704C19.7604 5.68912 19.4375 4.48079 18.4896 3.93912L16.6875 2.90787C15.8646 2.41829 14.8021 2.70996 14.3125 3.53287L14.1979 3.73079C13.2604 5.36621 11.7187 5.36621 10.7708 3.73079L10.6562 3.53287C10.1875 2.70996 9.12498 2.41829 8.30206 2.90787L6.49998 3.93912C5.55206 4.48079 5.22915 5.69954 5.77081 6.64746C6.71873 8.27246 5.9479 9.60579 4.06248 9.60579C2.97915 9.60579 2.08331 10.4912 2.08331 11.585V13.4183C2.08331 14.5016 2.96873 15.3975 4.06248 15.3975C5.9479 15.3975 6.71873 16.7308 5.77081 18.3662C5.22915 19.3141 5.55206 20.5225 6.49998 21.0641L8.30206 22.0954C9.12498 22.585 10.1875 22.2933 10.6771 21.4704L10.7916 21.2725C11.7291 19.637 13.2708 19.637 14.2187 21.2725L14.3333 21.4704C14.8229 22.2933 15.8854 22.585 16.7083 22.0954L18.5104 21.0641C19.4583 20.5225 19.7812 19.3037 19.2396 18.3662C18.2916 16.7308 19.0625 15.3975 20.9479 15.3975C22.0312 15.3975 22.9271 14.512 22.9271 13.4183V11.585C22.9166 10.5016 22.0312 9.60579 20.9375 9.60579ZM12.5 15.887C10.6354 15.887 9.11456 14.3662 9.11456 12.5016C9.11456 10.637 10.6354 9.11621 12.5 9.11621C14.3646 9.11621 15.8854 10.637 15.8854 12.5016C15.8854 14.3662 14.3646 15.887 12.5 15.887Z"
        fill="white"
      />
    </svg>
  );
};

export default SettingsSVG;
