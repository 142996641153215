export function checkCoordinateOnCircle(coordinate, circle) {
  const difX = coordinate.x - circle.x;
  const difY = coordinate.y - circle.y;
  const r = circle.r;
  return !(difX * difX + difY * difY > r * r);
}

export function drawArrow(ctx, attrs, styles) {
  const { x, y } = attrs;
  const startX = x;
  const startY = y;

  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.font = "15px ''";
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.font = "   15px ''";
  ctx.save();
  ctx.fillStyle = 'white';
  ctx.font = "   15px ''";
  ctx.beginPath();

  if (styles?.type === 'Long') {
    ctx.moveTo(startX + 8, startY);
    ctx.bezierCurveTo(startX + 3.58187, startY, startX, startY + 3.58187, startX, startY + 8);
    ctx.bezierCurveTo(startX, startY + 12.4181, startX + 3.58187, startY + 16, startX + 8, startY + 16);
    ctx.bezierCurveTo(startX + 12.4181, startY + 16, startX + 16, startY + 12.4181, startX + 16, startY + 8);
    ctx.bezierCurveTo(startX + 16, startY + 3.58187, startX + 12.4181, startY, startX + 8, startY);
    ctx.closePath();
    ctx.moveTo(startX + 9.37147, startY + 7.548);
    ctx.lineTo(startX + 9.37147, startY + 12.5333);
    ctx.lineTo(startX + 6.62853, startY + 12.5333);
    ctx.lineTo(startX + 6.62853, startY + 7.548);
    ctx.lineTo(startX + 3.73333, startY + 7.548);
    ctx.lineTo(startX + 8, startY + 3.46667);
    ctx.lineTo(startX + 12.2667, startY + 7.548);
    ctx.lineTo(startX + 9.37147, startY + 7.548);
  } else if (styles?.type === 'Short') {
    ctx.moveTo(startX + 8, startY + 16);
    ctx.bezierCurveTo(startX + 3.58187, startY + 16, startX, startY + 12.4181, startX, startY + 8);
    ctx.bezierCurveTo(startX, startY + 3.58187, startX + 3.58187, startY, startX + 8, startY);
    ctx.bezierCurveTo(startX + 12.4181, startY, startX + 16, startY + 3.58187, startX + 16, startY + 8);
    ctx.bezierCurveTo(startX + 16, startY + 12.4181, startX + 12.4181, startY + 16, startX + 8, startY + 16);
    ctx.closePath();
    ctx.moveTo(startX + 9.37147, startY + 8.452);
    ctx.lineTo(startX + 9.37147, startY + 3.46667);
    ctx.lineTo(startX + 6.62853, startY + 3.46667);
    ctx.lineTo(startX + 6.62853, startY + 8.452);
    ctx.lineTo(startX + 3.73333, startY + 8.452);
    ctx.lineTo(startX + 8, startY + 12.5333);
    ctx.lineTo(startX + 12.2667, startY + 8.452);
    ctx.lineTo(startX + 9.37147, startY + 8.452);
  }

  ctx.closePath();
  ctx.fill();

  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

const customArrow = {
  name: 'custom-arrow',
  checkEventOn: checkCoordinateOnCircle,
  draw: (ctx, attrs, styles, direction) => {
    drawArrow(ctx, attrs, styles, direction);
  },
};

export default customArrow;
