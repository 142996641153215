import { resetAll } from '../../store/clearStore';
import { useUserStore } from '../../store/useUserStore';
import { AUTHENTICATION, apiURL } from '../constant/constant';
import { client, setHeaderToken } from './utils';

export const fetchNewToken = async () => {
  try {
    const token = await client.get(apiURL + AUTHENTICATION.REFRESH_TOKEN).then((res) => {
      return res.data;
    });
    return token;
  } catch (error) {
    return null;
  }
};

export const refreshAuth = async (failedRequest) => {
  const newToken = await fetchNewToken();

  if (newToken) {
    failedRequest.response.config.headers.Authorization = 'Bearer ' + newToken.accessToken;
    useUserStore.getState().addToken(newToken);
    setHeaderToken(newToken.accessToken, newToken.refreshToken);

    return Promise.resolve(newToken);
  } else {
    resetAll();
    return Promise.reject();
  }
};
