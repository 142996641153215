import React, { useEffect } from 'react';
import { useSocketContext } from '../context/SocketContext';
import moment from 'moment';
import { useTrade } from '../store/useTrade';
import { useGlobalStore } from '../store/useGlobalStore';
import { useGetSetData } from './useGetSetData';
import { getCandleTimestamps } from '../utils/utils';

const useReactQuerySubscription = (
  { pair, timeFrame, getCurrentTimeZone, investmentTimeType },
  addNewData = () => {},
) => {
  const { socket } = useSocketContext();

  React.useEffect(() => {
    socket.on('cas', (newData) => {
      addNewData(pair, newData);
    });
    return () => {
      socket.off('cas');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pair, timeFrame, getCurrentTimeZone, investmentTimeType]);

  return { socket };
};

export const createActiveTradeLabel = (
  id,
  index,
  type,
  investment,
  startTime,
  endTime,
  targetTime,
  value,
  targetCandleEnd,
) => {
  const time = (targetTime - endTime) / 1000;
  if (time > 0) {
    if (window.chartRef.getOverlayById(id)) {
      return window.chartRef.overrideOverlay({
        id: id,
        extendData: {
          type,
          activeTradeIndex: index,
          label: `${investment} - ${moment().startOf('day').seconds(time).format('HH:mm:ss')}`,
          targetCandleEnd,
        },
      });
    }

    window.chartRef.createOverlay({
      id: id,
      name: `SegmentTag`,
      needDefaultPointFigure: true,
      extendData: {
        type,
        activeTradeIndex: index,
        label: `${investment} - ${moment().startOf('day').seconds(time).format('HH:mm:ss')}`,
        targetCandleEnd,
      },
      points: [{ timestamp: startTime, value: value }],
      mode: 'strong_magnet',
      modeSensitivity: 2,
      zLevel: 6 - index,
    });
  }
};

export const useShowLabelsForActiveTrades = ({ pair, timeFrame, getCurrentTimeZone, activeTrades }) => {
  const { socket } = useSocketContext();
  const { removeTrade } = useTrade();
  const { removeActiveTrade } = useGlobalStore();
  const { getActivePairId } = useGetSetData();

  // useEffect(() => {
  //   return () => {
  //     activeTrades?.forEach(({ id }) => {
  //       window.chartRef.removeOverlay({ id: id });
  //     });
  //   };
  // }, [pair, activeTrades]);

  React.useEffect(() => {
    socket.on('cas', (newData) => {
      if (pair === newData?.pair) {
        window.cas = newData;
      }
      if (activeTrades?.length) {
        activeTrades?.forEach(({ type, investment, startTime, targetTime, value, id }, index, self) => {
          const targetCandleEnd = getCandleTimestamps(targetTime, timeFrame);
          const startCandle = getCandleTimestamps(startTime, timeFrame);

          if (pair === newData?.pair && targetTime >= newData?.end) {
            return createActiveTradeLabel(
              id,
              self.length - index - 1,
              type,
              investment,
              startCandle?.candleStart,
              newData?.end,
              targetTime,
              value,
              targetCandleEnd?.candleEnd,
            );
          }
          if (targetTime < newData?.end) {
            removeTrade(getActivePairId, id);
            removeActiveTrade(getActivePairId, id);
            window.chartRef.removeOverlay({ id: id });
          }
        });
      }
    });

    // return () => {
    //   socket.off('cas');
    // };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pair, JSON.stringify(timeFrame), getCurrentTimeZone, JSON.stringify(activeTrades)]);

  React.useEffect(() => {
    return () => {
      activeTrades?.forEach(({ id }) => {
        window.chartRef.removeOverlay({ id: id });
      });
    };
  }, [timeFrame]);
};

export default useReactQuerySubscription;
