import { Spin } from 'antd';
import styled from 'styled-components';

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomSpinner = ({ children, ...props }) => {
  return <SpinnerContainer>{<Spin {...props}>{children}</Spin>}</SpinnerContainer>;
};

export default CustomSpinner;
