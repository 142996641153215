import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteApi, getApi, putApi } from './utils/utils';
import { PROFILE_API } from './constant/constant';
import openNotification from '../utils/openNotification';
import { useTranslation } from 'react-i18next';
import { useTranslatedNotification } from '../utils/utils';

export const useGetProfileDetails = (props = {}) => {
  return useQuery({
    queryKey: ['profile', 'details'],
    queryFn: () => {
      return getApi(PROFILE_API.GET_PROFILE);
    },
    staleTime: 0,
    ...props,
  });
};

export const useGetProfilePicture = (props = {}) => {
  return useQuery({
    staleTime: 0,
    queryKey: ['profile', 'picture'],
    queryFn: () => {
      return getApi(PROFILE_API.GET_PROFILE_PIC, { responseType: 'arraybuffer' });
    },
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
    ...props,
  });
};

export const useUpdateProfileDetails = ({ onSuccess = () => {}, message, ...rest } = {}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { showNotification } = useTranslatedNotification();

  return useMutation({
    mutationFn: (data) => {
      return putApi(PROFILE_API.UPDATE_PROFILE, { ...data }, true);
    },
    onSuccess: (data) => {
      showNotification('success', message || 'Updated Successfully');
      queryClient.setQueryData(['profile', 'details'], data);
      onSuccess(data);
    },
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
    ...rest,
  });
};

export const useDeleteProfilePic = () => {
  const { showNotification } = useTranslatedNotification();

  return useMutation({
    mutationFn: () => {
      return deleteApi(PROFILE_API.DELETE_PROFILE_PIC);
    },
    onSuccess: () => {
      showNotification('success', 'Profile picture deleted successfully');
    },
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useDeleteUserProfile = () => {
  return useMutation({
    mutationFn: () => {
      return deleteApi(PROFILE_API.DELETE_PROFILE);
    },
    onSuccess: () => {},
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};
