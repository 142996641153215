import { Card } from 'antd';
import styled from 'styled-components';

const CustomStyleCard = styled(Card)`
  background-color: var(--color-grey-2);

  .ant-card-head {
    border-bottom: 1px solid var(--color-border-dark);
    border-radius: 8px 8px 0 0;
  }
  .ant-card-extra {
    button {
      max-height: 40px;
    }
  }
`;

const CustomCard = ({ children, ...props }) => {
  return (
    <CustomStyleCard bordered={false} {...props}>
      {children}
    </CustomStyleCard>
  );
};

export default CustomCard;
