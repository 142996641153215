import styled from 'styled-components';

export const FooterContainer = styled.footer`
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  border-bottom: 1px solid var(--color-border-dark);
  padding: 20px 10%;

  @media screen and (max-width: 1280px) {
    padding: 20px 70px;
  }

  @media screen and (max-width: 768px) {
    padding: 20px;
  }
  .wrapper {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto 50px;
    font-size: 16px;
    line-height: 18px;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 58px;
      flex-wrap: wrap;

      .social-media {
        display: flex;
        align-items: center;
      }
    }

    .logo {
      display: flex;
      align-items: center;
      @media screen and (max-width: 671px) {
        margin-bottom: 15px;
      }
      img {
        width: auto;
        height: 26px;
        margin-bottom: 15px;
      }
      p {
        font-size: 2.5rem;
        margin-left: 7.5px;
        color: var(--color-text);
      }
    }

    .navigation {
      @media screen and (max-width: 440px) {
        display: block;
      }

      .navigation__links {
        @media screen and (max-width: 768px) {
          margin-bottom: 30px;
        }

        ul {
          li {
            cursor: pointer;
            line-height: 24px;
            font-size: 16px;
            font-weight: 400;
            .footer__link {
              color: var(--color-text-secondary);
            }
            &:not(:last-child) {
              margin-bottom: 8px;
            }
            &:first-child {
              .footer__link {
                color: var(--color-text);
                font-weight: 500;
              }
            }
            &:hover {
              .footer__link {
                color: var(--color-link);
              }
            }
          }
        }
      }
    }
    .address {
      display: flex;
      flex-direction: column;
      gap: 20px;
      line-height: 26px;
      color: var(--color-text-secondary);
      margin-bottom: 20px;

      @media screen and (max-width: 621px) {
        width: 100%;
        p {
          font-size: 16px;
        }
      }
      @media screen and (min-width: 768px) and (max-width: 991px) {
        margin-top: 32px;
      }
      .title {
        color: var(--color-text);
        font-weight: 500;
      }
    }
    .riskMsg {
      color: var(--color-text-secondary);
      font-size: 16px;
      line-height: 24px;
    }
  }

  .show-mobile {
    display: none !important;
    @media screen and (max-width: 767px) {
      display: flex !important;
    }
  }

  .hide-mobile {
    display: flex !important;
    @media screen and (max-width: 767px) {
      display: none !important;
    }
  }
`;

export const CopyWrightContainer = styled.div`
  display: flex;
  margin: 0 auto;
  color: var(--color-text);
  padding: 35px;
  justify-content: center;
`;
