import './style.scss';

const InstagramSVG = () => {
  return (
    <a href="https://www.instagram.com/martextradeofficial/" target="_blank" rel="noreferrer" className="svg-link">
      <svg width="35" height="35" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_67_5516)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.0436 8.14728C13.0901 8.23542 11.397 8.71301 10.0298 10.0744C8.65783 11.443 8.18617 13.1428 8.09781 15.076C8.04288 16.2826 7.7217 25.398 8.65306 27.7885C9.28113 29.4012 10.5181 30.6412 12.1456 31.2713C12.905 31.5667 13.7719 31.7666 15.0436 31.825C25.6766 32.3062 29.6181 32.0442 31.2802 27.7885C31.5751 27.0309 31.7781 26.1648 31.8342 24.8963C32.3202 14.2361 31.7555 11.9254 29.9023 10.0744C28.4324 8.60819 26.7034 7.6101 15.0436 8.14728ZM15.1415 29.6811C13.9773 29.6286 13.3456 29.4347 12.9241 29.2715C11.8638 28.8594 11.0674 28.0662 10.6578 27.0121C9.94858 25.1957 10.1838 16.5686 10.2471 15.1727C10.3092 13.8053 10.5862 12.5556 11.551 11.5908C12.745 10.3997 14.2877 9.81599 24.7918 10.29C26.1625 10.352 27.4151 10.6284 28.3823 11.5908C29.5763 12.7819 30.1686 14.3365 29.6862 24.8002C29.6336 25.9615 29.439 26.5917 29.2754 27.0121C28.1948 29.7814 25.7088 30.1658 15.1415 29.6811ZM24.9076 13.6275C24.9076 14.4161 25.5488 15.0572 26.3405 15.0572C27.1321 15.0572 27.7745 14.4161 27.7745 13.6275C27.7745 12.8391 27.1321 12.1984 26.3405 12.1984C25.5488 12.1984 24.9076 12.8391 24.9076 13.6275ZM13.8352 19.9856C13.8352 23.3635 16.5803 26.1022 19.9666 26.1022C23.3529 26.1022 26.0981 23.3635 26.0981 19.9856C26.0981 16.6076 23.3529 13.8707 19.9666 13.8707C16.5803 13.8707 13.8352 16.6076 13.8352 19.9856ZM15.9869 19.9856C15.9869 17.794 17.7684 16.0158 19.9666 16.0158C22.1649 16.0158 23.9464 17.794 23.9464 19.9856C23.9464 22.1784 22.1649 23.957 19.9666 23.957C17.7684 23.957 15.9869 22.1784 15.9869 19.9856Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_67_5516">
            <rect width="24" height="24" fill="white" transform="translate(8 8)" />
          </clipPath>
        </defs>
      </svg>
    </a>
  );
};

export default InstagramSVG;
