import React, { useContext, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTrade } from '../store/useTrade';
import { useGlobalStore } from '../store/useGlobalStore';
import _, { noop } from 'lodash';
import { Flex, notification } from 'antd';
import { ProfitLossResultContainer } from '../styles/style';
import routes from '../utils/routes';
import winNotificationTone from '../assets/audio/trade-tone.wav';
import lossNotificationTone from '../assets/audio/Loss.mp3';
import entryNotificationTone from '../assets/audio/TradeEntry.mp3';
import { useUserStore } from '../store/useUserStore';
import { formatNumericValues, getCandleTimestamps } from '../utils/utils';
import { io } from 'socket.io-client';
import { createActiveTradeLabel } from '../hooks/useSocket';
import { useChartAction } from '../store/useChartAction';

export const SocketContext = React.createContext();

export const useSocketContext = () => useContext(SocketContext);

const notificationIncludedRoutes = [routes.DEMO_TRADE, routes.TERMINAL.HOME];

const SocketProvider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [currentSocketInstance, setCurrentSocketInstance] = useState(null);
  const { trades, setTrades, removeTrade } = useTrade();
  const { setActiveTrades, activeTrades } = useGlobalStore();
  const { notificationAlert } = useUserStore();
  const { timeFrame } = useChartAction();

  const handleWinNotificationAlert = () => {
    let audio = new Audio(winNotificationTone);
    audio.play().catch(() => {
      // eslint-disable-next-line no-console
      console.log('audio error');
    });
  };

  const handleEntryNotificationAlert = () => {
    let audio = new Audio(entryNotificationTone);
    audio.play().catch(() => {
      // eslint-disable-next-line no-console
      console.log('audio error');
    });
  };

  const handleLossNotificationAlert = () => {
    let audio = new Audio(lossNotificationTone);
    audio.play().catch(() => {
      // eslint-disable-next-line no-console
      console.log('audio error');
    });
  };

  const [closedApi, closedContextHolder] = notification.useNotification({
    bottom: 50,
  });
  const [enteredApi, enteredContextHolder] = notification.useNotification({
    top: 200,
  });

  const openCloseTradeNotification = (action, data) => {
    if (notificationIncludedRoutes.includes(window.location.pathname)) {
      if (action === 'closed') {
        window.chartRef.removeOverlay({ id: `${data?.id}` });
        closedApi.info({
          message: (
            <Flex>
              <img src={data.imageUrl} alt="flag" style={{ width: '22px', height: '22px', marginRight: 10 }} />
              {data.pair}
            </Flex>
          ),
          description: (
            <Flex>
              {' '}
              <ProfitLossResultContainer
                amount={data?.resultAmount}
              >{`+ ${data?.resultAmount} ${data?.currencySymbol}`}</ProfitLossResultContainer>
            </Flex>
          ),
          className: 'custom_notification',
          placement: 'bottomLeft',
        });
      } else {
        enteredApi.success({
          message: (
            <Flex>
              <img src={data.imageUrl} alt="flag" style={{ width: '22px', height: '22px', marginRight: 10 }} />
              {data.pair}
            </Flex>
          ),
          className: 'custom_notification',
          description: `Trade opened with price: ${data?.startAmount} `,
          placement: 'topLeft',
        });
      }
    }
  };

  const queryClient = useQueryClient();

  const tradeEntered = (newData) => {
    openCloseTradeNotification('open', newData);
    if (window.notificationAlert) {
      handleEntryNotificationAlert();
    }
    queryClient.setQueryData(['trade', 'active'], (oldData) => {
      if (oldData?.data?.data) {
        const copyOfOldData = JSON.parse(JSON.stringify(oldData));
        copyOfOldData.data.data = [newData, ...copyOfOldData.data.data];
        return copyOfOldData;
      }
      return oldData;
    });

    const { data: { data: favList = [] } = {} } = queryClient.getQueryData(['forex', 'fav']) || {};

    const activePair = favList?.find((item) => item.active);

    const createAnnotation = (newTradeData, id, type, investment) => {
      if (trades?.[activePair?.pairId]?.length < 10 || !trades?.[activePair?.pairId]) {
        const newTrade = {
          type,
          investment,
          startTime: newTradeData?.startAt,
          targetTime: newTradeData?.endAt,
          id: id,
          value: newTradeData?.startAmount,
        };
        setTrades(activePair?.pairId, newTrade);

        [...activeTrades, newTrade]?.forEach(({ type, investment, startTime, targetTime, value, id }, index, self) => {
          const lastSocketData = window.cas;
          const targetCandleEnd = getCandleTimestamps(targetTime, timeFrame?.type);
          const startCandle = getCandleTimestamps(startTime, timeFrame?.type);

          return createActiveTradeLabel(
            id,
            self.length - index - 1,
            type,
            investment,
            startCandle?.candleStart,
            lastSocketData?.end,
            targetTime,
            value,
            targetCandleEnd?.candleEnd,
          );
        });
        setActiveTrades([newTrade], true);
      }
    };

    if (activePair?.pairId === newData.pairId) {
      createAnnotation(
        newData,
        `${newData?.id}`,
        `${newData.position}`,
        `${newData?.currencySymbol} ${formatNumericValues(newData.tradeAmount)}`,
      );
    }
  };

  const tradeClosed = (newData) => {
    newData.forEach((trade) => {
      if (!trade?.discarded) {
        openCloseTradeNotification('closed', trade);
        if (window.notificationAlert) {
          if (trade.resultAmount) {
            handleWinNotificationAlert();
          } else {
            handleLossNotificationAlert();
          }
        }
      }
      removeTrade(trade?.pairId, `${trade?.id}`);
    });
    queryClient.setQueryData(['trade', 'active'], (oldData) => {
      if (oldData?.data?.data?.length) {
        const copyOfOldData = JSON.parse(JSON.stringify(oldData));
        const completedId = newData.map((trade) => trade.id);
        copyOfOldData.data.data = copyOfOldData.data.data.filter((trade) => !completedId.includes(trade.id));
        return copyOfOldData;
      }
      return oldData;
    });

    queryClient.setQueryData(['trade', 'history'], (oldData) => {
      if (oldData?.pages) {
        const copyOfOldData = JSON.parse(JSON.stringify(oldData));

        copyOfOldData.pages[0] = [...newData.sort((a, b) => b.id - a.id), ...(copyOfOldData.pages[0] || [])];
        return copyOfOldData;
      }
      return oldData;
    });
  };

  const updateAccount = (newData) => {
    const parseData = JSON.parse(newData);
    queryClient.setQueryData(['trade', 'account', 'details'], (oldData) => {
      if (oldData?.data?.data) {
        let indexOfPayloadToBeUpdate = oldData.data.data.findIndex((account) => account.id === parseData.id);
        if (indexOfPayloadToBeUpdate > -1) {
          const copyOfOldData = JSON.parse(JSON.stringify(oldData));
          copyOfOldData.data.data[indexOfPayloadToBeUpdate].balance = `${parseData.balance}`;
          return copyOfOldData;
        }
        return oldData;
      } else {
        return oldData;
      }
    });
  };

  const forexPairUpdate = (newData) => {
    queryClient.setQueryData(['forex', 'fav'], (oldData) => {
      if (oldData?.data?.data) {
        let indexOfPayloadToBeUpdate = oldData.data.data.findIndex((forex) => forex.pairId === newData.id);
        if (indexOfPayloadToBeUpdate > -1) {
          const copyOfOldData = JSON.parse(JSON.stringify(oldData));
          copyOfOldData.data.data[indexOfPayloadToBeUpdate].forexPair.activeFromService = newData.activeFromService;
          copyOfOldData.data.data[indexOfPayloadToBeUpdate].forexPair.revenue1Min = newData.revenue1Min;
          copyOfOldData.data.data[indexOfPayloadToBeUpdate].forexPair.revenue5Min = newData.revenue5Min;
          copyOfOldData.data.data[indexOfPayloadToBeUpdate].forexPair.enabled = newData.enabled;
          return copyOfOldData;
        }
        return oldData;
      } else {
        return oldData;
      }
    });
  };

  const debouncedDisconnect = _.debounce(() => setIsConnected(false), 2000);

  const loginStore = useUserStore();

  useEffect(() => {
    const socket = io(process.env.REACT_APP_WS_URL);
    setCurrentSocketInstance(socket);
    socket.on('connect', () => {
      socket.emit('auth', { token: loginStore?.token?.accessToken });

      socket.on('trade_entered', tradeEntered);
      socket.on('trade_closed', tradeClosed);
      socket.on('trade_account_update', updateAccount);
      socket.on('forex_pair_update', forexPairUpdate);
    });

    socket.on('disconnect', () => {
      socket.off('trade_entered', tradeEntered);
      socket.off('trade_closed', tradeClosed);
      socket.off('trade_account_update', updateAccount);
      socket.off('forex_pair_update', forexPairUpdate);
      setIsConnected(false);
    });

    socket.on('auth', (data) => {
      if (data?.message === 'OK') setIsConnected(true);
    });

    return () => {
      return socket ? socket.disconnect() : noop;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStore?.token?.accessToken]);

  useEffect(() => {
    window.notificationAlert = notificationAlert;
  }, [notificationAlert]);

  return (
    <SocketContext.Provider value={{ socket: currentSocketInstance, isConnected, debouncedDisconnect, setIsConnected }}>
      {notificationIncludedRoutes.includes(window.location.pathname) && closedContextHolder}
      {notificationIncludedRoutes.includes(window.location.pathname) && enteredContextHolder}
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
