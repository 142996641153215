import { useEffect, useMemo } from 'react';
import { useChartAction } from '../../store/useChartAction';
import { TimeFrameTypes } from '../../klinechart/constant';
import { v4 as uuidv4 } from 'uuid';
import useHandleOverlay from '../../hooks/useHandleOverlay';
import { useGetSetData } from '../../hooks/useGetSetData';
import useHandleIndicator from '../../hooks/useHandleIndicator';
import { countDecimal } from '../../utils/utils';
import { useUserStore } from '../../store/useUserStore';
import { useSocketContext } from '../../context/SocketContext';

const NativeEvents = () => {
  const { getActivePairId } = useGetSetData();
  const { setActiveForex } = useGetSetData();
  const { setTheme } = useUserStore();
  const { isConnected } = useSocketContext();
  const { setChartAction, indicators, chartType, timeFrame, overlays } = useChartAction();
  const { createToolOverlay } = useHandleOverlay();
  const { createChartIndicator, removeChartIndicator, overrideIndicatorWithIcon } = useHandleIndicator();

  const handleToFav = (pair) => {
    const pairObj = JSON.parse(pair);
    setActiveForex(pairObj);
  };

  const handleChartType = (type) => {
    window.chartRef.setStyles({ candle: { type } });
    setChartAction({ chartType: type });
    window?.Android?.indicatorListPresent(
      JSON.stringify({
        indicators,
        chartType: type,
        timeFrame,
        overlays,
      }),
    );
  };

  const handleChartTimeFrame = (time) => {
    let timeFrame = TimeFrameTypes.find((type) => type.type === time);
    setChartAction({ timeFrame });
    window?.Android?.indicatorListPresent(
      JSON.stringify({
        indicators,
        chartType,
        timeFrame,
        overlays,
      }),
    );
  };

  const handleDrawing = (type) => {
    const newOverlayId = uuidv4();
    createToolOverlay({
      id: newOverlayId,
      name: type,
      getActivePairId,
    });
  };

  const handleIndicator = (action, name, id) => {
    const isChecked = action;
    if (isChecked === 'remove') {
      removeChartIndicator({ id, indicatorId: id.includes('main') ? 'candle_pane' : id, name });
    } else {
      let history = window.chartRef.getDataList();
      let noOfDecimal = countDecimal(history[0]);
      createChartIndicator({ id, name, noOfDecimal });
    }
  };

  const handleIndicatorSettings = (type, paneId, settings) => {
    overrideIndicatorWithIcon({
      name: type,
      paneId: paneId,
      settings: settings,
    });
  };

  const handleNativeEvents = (e) => {
    switch (e.detail.eventName) {
      case 'currentAssetEvent': {
        handleToFav(e.detail.currentAsset);
        break;
      }
      case 'timeFrameEvent': {
        handleChartTimeFrame(e.detail.timeFrame);
        break;
      }
      case 'candleTypeEvent': {
        handleChartType(e.detail.candleType);
        break;
      }
      case 'drawingEvent': {
        handleDrawing(e.detail.drawing);
        break;
      }
      case 'indicatorEvent': {
        handleIndicator(e.detail.indicatorAction, e.detail.indicatorName, e.detail.indicatorId);
        break;
      }
      case 'indicatorSettingEvent': {
        handleIndicatorSettings(e.detail.indicatorName, e.detail.paneId, e.detail.settings);
        break;
      }
      case 'themeEvent': {
        setTheme(e.detail.theme);
        break;
      }
      case 'scrollToRealTimeEvent': {
        window.chartRef.scrollToRealTime(500);
        break;
      }
      default: {
        return;
      }
    }
  };

  useEffect(() => {
    window.addEventListener('nativeEvent', handleNativeEvents, false);

    return () => {
      window.removeEventListener('nativeEvent', handleNativeEvents);
    };
  }, []);

  useEffect(() => {
    window?.chartRef?.subscribeAction('onScroll', () => {
      const distanceOnRight = window?.chartRef?.getOffsetRightDistance();
      if (distanceOnRight > 0) {
        window?.Android?.toggleRealTimeNavigation('false');
      } else {
        window?.Android?.toggleRealTimeNavigation('true');
      }
    });

    return () => {
      window?.chartRef?.unsubscribeAction('onScroll');
    };
  }, [isConnected, getActivePairId]);

  useEffect(() => {
    window?.Android?.toggleRealTimeNavigation('false');
  }, [getActivePairId]);

  useEffect(() => {
    window?.Android?.indicatorListPresent(
      JSON.stringify({
        indicators,
        chartType,
        timeFrame,
        overlays,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overlays.length, indicators.length]);

  return <></>;
};

export default NativeEvents;
