import React from 'react';
import SomethingWentWrongIcon from '../../assets/images/505Error.svg';
import CustomAnnouncement from '../../Components/UI/CustomAnnouncement/CustomAnnouncement';
import { useTranslation } from 'react-i18next';
const SomethingWentWrong = () => {
  const { t } = useTranslation();
  return (
    <CustomAnnouncement.StaticAnnouncement
      header={t('Error!')}
      text={t('Please refresh the page and try again!')}
      img={SomethingWentWrongIcon}
      actionButton={() => window.location.reload()}
    />
  );
};

export default SomethingWentWrong;
