import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Forex_Web from '../../assets/images/Forex_Web.png';
import Forex_Mobile from '../../assets/images/Forex_Mobile.png';
import './style.scss';
import { Link, useNavigate } from 'react-router-dom';
import routes from '../../utils/routes';
import { useTranslation } from 'react-i18next';

const InnovationBlock = () => {
  const { t } = useTranslation();
  const isExtraSmallScreen = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();

  const [imageSrc, setImageSrc] = useState(isExtraSmallScreen ? Forex_Mobile : Forex_Web);
  useEffect(() => {
    if (isExtraSmallScreen) {
      setImageSrc(Forex_Mobile);
    } else {
      setImageSrc(Forex_Web);
    }
  }, [isExtraSmallScreen]);
  return (
    <div className="innovation__wrapper">
      <div className="innovation__wrapper__image-block">
        <img alt="center" src={imageSrc} className="img__center" />
      </div>
      <div className="innovation__wrapper__buttons-block">
        <div className="innovation__wrapper__buttons-block__title">
          {t('MartexTrade: Your Trading Triumph Begins Now.')}
        </div>
        <div className="buttons">
          <button className="button-real" onClick={() => navigate(routes.REGISTRATION)}>
            <Link>{t('Open real account')}</Link>
          </button>
          <button onClick={() => navigate(routes.REGISTRATION)} className="button-demo">
            {t('Demo Account')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InnovationBlock;
