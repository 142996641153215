import React from 'react';
import { Collapse, Skeleton } from 'antd';
import './style.scss';

const CustomCollapse = ({ items, loading }) => {
  const loadingItems = Array.from({ length: 3 }).map((item, index) => ({
    key: 'dummy' + index,
    label: <Skeleton.Input size="small" style={{ width: '100%' }} title={{ width: '100%' }} />,
  }));

  return <Collapse bordered={false} items={loading ? loadingItems : items} expandIconPosition="end" />;
};

export default CustomCollapse;
