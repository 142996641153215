import styled from 'styled-components';
import CustomCard from '../CustomCard';

export const Container = styled.div`
  background: var(--color-grey-2);
  border-radius: 10px;
  padding: 40px 0;
  min-width: 300px;
  width: 700px;
`;

export const MaintenanceCard = styled(CustomCard)`
  min-width: 300px !important;
  width: 700px !important;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;

  @media screen and (max-width: 480px) {
    padding-bottom: 10px;
  }
`;

export const ImageWrapper = styled.img`
  max-height: calc(70vh - 186px);
  @media screen and (max-width: 480px) {
    width: 85%;
  }
`;

export const Header = styled.div`
  font-family: Poppins !important;
  font-weight: 500;
  font-size: 32px;
  line-height: 1.5;
  text-align: center;
  color: var(--color-text);

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 22px;
  }
`;

export const TextWrapper = styled.div`
  padding-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 480px) {
    padding-top: 6px;
  }
`;

export const Text = styled.div`
  width: 80%;
  font-family: Poppins !important;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.5;
  text-align: center;
  color: var(--color-text);

  @media screen and (max-width: 768px) {
    font-size: 18px;
    width: 90%;
  }

  @media screen and (max-width: 480px) {
    font-size: 16px;
    width: 90%;
  }
`;

export const StaticWrapper = styled.div`
  margin: auto;
  height: calc(100% - 72px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
