import { Navigate, Outlet } from 'react-router-dom';
import PrivateLayout from '../Components/Layouts/PrivateLayout';
import React from 'react';

import { useGetProfileDetails } from '../api/profileApi';
import CountrySelectModal from '../Components/CountrySelectModal';
import { useUserStore } from '../store/useUserStore';

function PrivateRoute() {
  const loginStore = useUserStore();

  const token = loginStore?.token?.accessToken;
  const data = useGetProfileDetails({ enabled: Boolean(token) });

  return token ? (
    <PrivateLayout>
      {!(data?.data?.data?.country || data.isLoading) && <CountrySelectModal onSave={data.refetch} />}
      <Outlet />
    </PrivateLayout>
  ) : (
    <Navigate to="/login" />
  );
}

export default React.memo(PrivateRoute);
