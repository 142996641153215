const addSupportSchema = (t) => {
  return {
    type: {
      required: t('Subject is required'),
    },
    message: {
      required: t('Message is required'),
    },
  };
};

export { addSupportSchema };
