import { Select } from 'antd';
import styled from 'styled-components';

export const StyledSelect = styled(Select)`
  ${(props) =>
    props?.showSuffix &&
    `
        .ant-select-arrow {
            left: 18px;
        }
        .ant-select-selection-item {
            left: 40px;
            max-width: calc(100% - 40px);
        }
    `}
`;

export const SuffixIcon = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 50%;
`;
