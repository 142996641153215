import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import phones from '../../assets/images/phones.png';
import googlePlay from '../../assets/images/googlePlay.svg';

import arrowIC from '../../assets/icons/arrow.svg';
import faqIC from '../../assets/icons/faq-ic.svg';

import phoneItem from '../../assets/images/iphone-item.png';

import InnovationBlock from '../../Components/InnovationBlock/InnovationBlock';
import routes from '../../utils/routes';
import './style.scss';
import { FAQWrapperItems, UnauthorizedWrapper } from '../../styles/style';
import useTranslatedConstant from '../../utils/useTranslatedConstant';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import { Helmet } from 'react-helmet';
import { playStoreUrl } from '../../api/constant/constant';

const HomePage = () => {
  const { t } = useTranslation();

  const { FAQS_ITEM_1, FAQS_ITEM_2, CAPABILITIES_ITEMS, STEPS_ITEM } = useTranslatedConstant();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <UnauthorizedWrapper>
      <Helmet>
        <title>{t('Home')}</title>
      </Helmet>
      <div className="home-page">
        <div className="demo-register__wrapper">
          <div className="image-section">
            <img src={phones} alt="phones" />
          </div>
          <div className="register-section">
            <div className="text-section">
              <h2 className="title">{t('Join the next generation of traders!')}</h2>
              <span className="subtitle">{t('Begin your journey to master the art of smart investing.')}</span>
            </div>
            <div className="buttons-section">
              <Link to={routes.REGISTRATION}>
                <button className="register">{t('Registration')}</button>
              </Link>
              <Link to={routes.REGISTRATION}>
                <button className="demo-register"> {t('Open demo account')} </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="brands__wrapper">
          <i className="icon icon__visa" />
          <i className="icon icon__mastercard" />
          <i className="icon icon__advacash" />
          <i className="icon icon__perfect_money" />
        </div>
        <div className="capability__wrapper">
          <Row className="capability-items" gutter={[36, 24]}>
            {CAPABILITIES_ITEMS.map((capability) => (
              <Col xs={24} md={12} lg={12} xl={6} xxl={6} key={capability.text}>
                <div className="capability-img-section">
                  <img src={capability.bgSRC} alt={capability.title} className="capability-item__img" />
                  <img src={capability.icon} alt={capability.title} className="capability-icon__img" />
                </div>
                <div className="capability-item__title">{capability.title}</div>
                <p className="capability-item__text">{capability.text}</p>
              </Col>
            ))}
          </Row>
          <div className="capability-button__block">
            <Link to={routes.REGISTRATION}>
              <button className="capability-button">{t('Try playing on a demo account')}</button>
            </Link>
          </div>
        </div>

        <div className="mobile-app__wrapper">
          <div className="image-block">
            <img src={phoneItem} alt="phone" />
          </div>
          <div className="get-app-block">
            <div className="get-app-block__title title">{t('Trade smartly on your mobile device today.')}</div>
            <p className="get-app-block__subtitle">
              {t('Download our user-friendly application for iPhone or Android and start trading!')}
            </p>
            <div className="get-app-block__buttons" style={{ position: 'relative' }}>
              <Link to={playStoreUrl} target="_blank" rel="noopener noreferrer">
                <img src={googlePlay} alt="android_app" />
              </Link>
            </div>
          </div>
        </div>
        <div className="start-trading__wrapper">
          <h2 className="start-trading__wrapper-title">{t('Start trading')}</h2>
          <p className="start-trading__wrapper-subtitle">{t('3 steps')}</p>
          <div className="start-trading__wrapper-steps">
            {STEPS_ITEM.map((step, idx) => (
              <div className="step" key={idx}>
                <div
                  className="step-img"
                  style={{
                    background: `url(${step.bgSRC})`,
                  }}
                ></div>
                <div className="step-title">{step.title}</div>
                <div className="step-description">{step.description}</div>
                <button className="step-button">
                  <Link to={routes.REGISTRATION}>{step.buttonText}</Link>
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="faq__wrapper">
          <h2 className="faq__wrapper-title title">{t('Frequently asked questions')}</h2>

          <div className="faq-answer__container">
            <FAQWrapperItems>
              {FAQS_ITEM_1.map((faq) => (
                <div className="faq-item" key={faq.id}>
                  <input type="checkbox" id={faq.id} className="accordion-checkbox" />
                  <label className="question" htmlFor={faq.id}>
                    <img src={faqIC} alt="13" />
                    <div className="text">{faq.question}</div>
                    <img src={arrowIC} alt="13" className="arrow-IC" />
                  </label>
                  <div className="faq-accordion-text">{faq.answer}</div>
                </div>
              ))}
            </FAQWrapperItems>
            <FAQWrapperItems>
              {FAQS_ITEM_2.map((faq) => (
                <div className="faq-item" key={faq.id}>
                  <input type="checkbox" id={faq.id} className="accordion-checkbox" />
                  <label className="question" htmlFor={faq.id}>
                    <img src={faqIC} alt="13" />
                    <div className="text">{faq.question}</div>
                    <img src={arrowIC} alt="13" className="arrow-IC" />
                  </label>
                  <div className="faq-accordion-text">{faq.answer}</div>
                </div>
              ))}
            </FAQWrapperItems>
          </div>
          <NavLink className="allquestion-link" to={routes.FAQ}>
            {t('Go to all questions')}
          </NavLink>
        </div>
        <InnovationBlock />
      </div>
    </UnauthorizedWrapper>
  );
};

export default HomePage;
