import { useQuery } from '@tanstack/react-query';
import { getApi } from './utils/utils';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../constants/language';
import { getKeyByValue } from '../utils/utils';

export const useFAQ = () => {
  const { i18n } = useTranslation();
  const currentLanguage = getKeyByValue(LANGUAGES, i18n.language);

  return useQuery({
    queryKey: ['faq', currentLanguage],
    queryFn: () => {
      return getApi(`/public/faq/all?language=${currentLanguage} `);
    },
    onSuccess: () => {},
  });
};
