import React from 'react';
import { PrivacyPolicyContainer } from './style';
import LogoBlack from '../../assets/icons/logo-black font.svg';
import MobileLogo from '../../assets/icons/result.svg';
import useMediaQuery from '../../hooks/useMediaQuery';

const PrivacyPolicy = () => {
  const isMobile = useMediaQuery(468);
  const headingStyle = {
    color: '#000',
  };

  const subHeadingStyle = {
    color: '#000',
    marginTop: '20px',
    marginBottom: '10px',
  };

  const listStyle = {
    marginLeft: '40px',
  };

  return (
    <PrivacyPolicyContainer>
      <div className="privacy-header">
        <h1 style={headingStyle}>Privacy Policy</h1>
        <img src={isMobile ? MobileLogo : LogoBlack} alt="martex-logo" className="logo" />
      </div>
      <div>
        <strong>Zoe Fintech LTD</strong>, Reg. No: 2023-000672, Ground Floor, The Sotheby Building, Saint Lucia P.O. Box
        838
      </div>
      <strong>Effective Date: March/01/2024</strong>
      <h2 style={subHeadingStyle}>1. General Provisions</h2>
      <p>
        1.1. Forex is an international commercial company specializing in Forex trading, recognizes the importance of
        privacy for our clients (&quot;you&quot;). We are committed to safeguarding your personal data and all
        information you provide to us.
        <br />
        <br />
        1.2. We strive to create the safest possible conditions for processing your information.
        <br />
        <br />
        1.3. Our utmost effort is directed towards ensuring the privacy of our customers.
        <br />
        <br />
        1.4. This Privacy Policy outlines the procedures for collecting, processing, using, and storing confidential
        client information on the Company&apos;s website, applications, and other internet platforms used to access our
        services.
        <br />
        <br />
        1.5. By agreeing to the terms of the Service Agreement, you simultaneously agree to all provisions of this
        Policy.
        <br />
        <br />
        1.6. It is assumed that you have thoroughly read and understood this Policy. All your subsequent decisions or
        actions will express your full agreement with this Policy and any updates.
      </p>
      <h2 style={subHeadingStyle}>2. Types of Information</h2>
      <p>
        <strong>2.1. Customer’s Personal Information:</strong>
        <br />
        <br />
      </p>
      <ul style={listStyle}>
        <li>
          2.1.1. Registration data: When registering on our platform, you provide information like name, phone, contact
          details, address, password, and account currency.
          <br />
          <br />
        </li>
        <li>
          2.1.2. Supporting documents: We may request documents to verify your identity, solvency, and ownership of
          funds.
          <br />
          <br />
        </li>
        <li>
          2.1.3. Your devices data: Personal data from various devices, such as geolocation, unique identifiers, and IP
          addresses.
          <br />
          <br />
        </li>
      </ul>
      <p>
        <strong>
          2.2. Depersonalized Information:
          <br />
          <br />
        </strong>
      </p>
      <ul style={listStyle}>
        <li>
          2.2.1. Technical information: Details about the software and devices used, like domain, language, browser
          type, and operating system.
          <br />
          <br />
        </li>
        <li>
          2.2.2. Analytical information: Insights into your use of our applications, servers, etc. <br />
          <br />
        </li>
        <li>
          2.2.3. Statistics: Depersonalized information may include transaction history, popular assets, and optimal
          transaction times.
          <br />
          <br />
        </li>
        <li>
          2.2.4. Anonymous information: Non-identifiable data used for statistical purposes.
          <br />
          <br />
        </li>
      </ul>
      <h2 style={subHeadingStyle}>3. Information Use by the Company</h2>
      <p>
        3.1. All information, including Personal Information, may be used for purposes such as:
        <br />
        <br />
      </p>
      <ul style={listStyle}>
        <li>
          3.2.1. Providing Company Services: Trading services, creating a trading account, assessing experience and risk
          level, etc.
          <br />
          <br />
        </li>
        <li>
          3.2.2. Creating your individual Account: Tailoring services to your needs.
          <br />
          <br />
        </li>
        <li>
          3.2.3. Identification (authentication) of access: Ensuring secure access to our services.
          <br />
          <br />
        </li>
        <li>
          3.2.4. Notifications of Company updates: Keeping you informed about changes and improvements.
          <br />
          <br />
        </li>
        <li>
          3.2.5. Promoting our product and site: Marketing our services and products.
          <br />
          <br />
        </li>
        <li>
          3.2.6. Response to your inquiries, applications: Addressing your queries and requests.
          <br />
          <br />
        </li>
        <li>
          3.2.7. Detecting violations of the law: Ensuring compliance with legal norms.
          <br />
          <br />
        </li>
        <li>
          3.2.8. Monitoring compliance with legislation: Adhering to legal standards and Company policies.
          <br />
          <br />
        </li>
        <li>
          3.2.9. Enforcement of a court decision: Fulfilling legal obligations.
          <br />
          <br />
        </li>
        <li>
          3.2.10. Correction of errors: Improving the functioning of Company services.
          <br />
          <br />
        </li>
        <li>
          3.2.11. Advertising: Promoting our services.
          <br />
          <br />
        </li>
        <li>
          3.2.12. Pursuing a policy to improve the functioning of services: Continuous improvement of our services.
          <br />
          <br />
        </li>
        <li>
          3.2.13. Identifying the interests of customers: Enhancing user experience.
          <br />
          <br />
        </li>
        <li>
          3.2.14. Preventing fraudulent transactions: Ensuring the integrity of our services.
          <br />
          <br />
        </li>
      </ul>
      <p>
        3.3. The company does not transfer confidential information except as provided by law and this Policy.
        <br />
        <br />
      </p>
      <p>
        3.4. Personal data may be disclosed to authorized representatives for improving services, marketing,
        identification, analysis, and account verification.
        <br />
        <br />
      </p>
      <p>
        3.5. The Company may disclose confidential information to comply with legal requirements, protect rights, and
        prevent illegal actions.
        <br />
        <br />
      </p>
      <p>
        3.6. The Company processes only the information received from the Client during service provision.
        <br />
        <br />
      </p>
      <p>
        3.7. When navigating to other sites or trading platforms, the Client is subject to the policies of those sites.
        <br />
        <br />
      </p>
      <p>
        3.8. Links to other companies on the Company’s website do not imply endorsement, and the Company is not
        responsible for third-party information.
        <br />
        <br />
      </p>
      <h2 style={subHeadingStyle}>4. Information Retention Period</h2>
      <p>
        4.1. Clients can request changes or report errors in personal data by contacting the Company.
        <br />
        <br />
        4.2. The Company reserves the right to store information necessary for legal compliance and service provision.
        <br />
        <br />
        4.3. The Company may edit or delete inaccurate or incomplete information.
      </p>
      <h2 style={subHeadingStyle}>5. Data Protection</h2>
      <p>
        5.1. The Company employs technologies and security standards to maintain client information confidentiality.
        <br />
        <br />
        5.2. The Company is not responsible for third-party actions with unauthorized access.
        <br />
        <br />
        5.3. Clients noticing disclosure of information should contact the Company via email.
      </p>
      <h2 style={subHeadingStyle}>6. Cookies</h2>
      <p>
        6.1. Cookies are utilized to improve site interaction but can be blocked by the client, impacting certain
        functions.
        <br />
        <br />
        6.2. The Company uses permanent, session, and third-party cookies.
      </p>
      <h2 style={subHeadingStyle}>7. Advertising</h2>
      <p>
        7.1. Advertising, including targeted ads, may be encountered during service use.
        <br />
        <br />
        7.2. The company reserves the right to attract third parties for disseminating information and evaluating
        advertising campaign success.
      </p>
      <h2 style={subHeadingStyle}>8. Marketing</h2>
      <p>
        8.1 The Company may use personal data for providing information about services and marketing offers.
        <br />
        <br />
        8.2. Clients can refuse marketing information by contacting the Company.
      </p>
      <h2 style={subHeadingStyle}>9. Significant Corporate Actions</h2>
      <p>9.1. In specific corporate actions, the Company may transfer personal data to authorized third parties.</p>
      <h2 style={subHeadingStyle}>10. Final Provisions</h2>
      <p>
        10.1. The Company reserves the right to amend this Policy.
        <br />
        <br />
        10.2. Clients are responsible for staying updated on Policy changes, available on the Company’s website.
        <br />
        <br />
        10.3. Contact Information:
        <br />
        Email: support@martextrade.com
      </p>
      <p className="contactInformation">
        Address: Ground Floor, The Sotheby Building, Rodney Bay, Gros-Islet, Saint Lucia P.O. Box 838, Castries, Saint
        Lucia.
      </p>
    </PrivacyPolicyContainer>
  );
};

export default PrivacyPolicy;
