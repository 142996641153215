import { calcTextWidth, createFont } from '../utils/canvas';
import { drawArrow } from './custom-arrow';

import { drawRect } from './rect';

export function getTextRect(attrs, styles) {
  const {
    size = 12,
    paddingLeft = 5,
    paddingTop = 5,
    paddingRight = 5,
    paddingBottom = 5,
    weight = 'normal',
    family,
  } = styles;
  const { x, y, text, align = 'left', baseline = 'top', width: w, height: h } = attrs;
  const width = w ?? paddingLeft + calcTextWidth(text, size, weight, family) + paddingRight;
  const height = h ?? paddingTop + size + paddingBottom;
  let startX;
  switch (align) {
    case 'left':
    case 'start': {
      startX = x;
      break;
    }
    case 'right':
    case 'end': {
      startX = x - width;
      break;
    }
    default: {
      startX = x - width / 2;
      break;
    }
  }
  let startY;
  switch (baseline) {
    case 'top':
    case 'hanging': {
      startY = y;
      break;
    }
    case 'bottom':
    case 'ideographic':
    case 'alphabetic': {
      startY = y - height;
      break;
    }
    default: {
      startY = y - height / 2;
      break;
    }
  }
  return { x: startX, y: startY, width, height };
}

export function checkCoordinateOnText(coordinate, attrs, styles) {
  const { x, y, width, height } = getTextRect(attrs, styles);
  return coordinate.x >= x && coordinate.x <= x + width && coordinate.y >= y && coordinate.y <= y + height;
}

export function drawText(ctx, attrs, styles) {
  const { text } = attrs;
  const {
    color = 'white',
    size = 12,
    family,
    weight,
    paddingLeft = 5,
    paddingTop = 5,
    paddingRight = 5,
    type = 'positive',
  } = styles;
  const rect = getTextRect(attrs, styles);

  drawRect(ctx, rect, { ...styles, color: type === 'Long' ? 'green' : 'red' });
  drawArrow(ctx, { x: rect.x + 5, y: rect.y + 3 }, { type });

  ctx.textAlign = 'left';
  ctx.textBaseline = 'top';
  ctx.font = createFont(size, weight, family);
  ctx.fillStyle = color;
  ctx.fillText(text, rect.x + paddingLeft, rect.y + paddingTop, rect.width - paddingLeft - paddingRight);
}

const text = {
  name: 'custom-text',
  checkEventOn: (coordinate, attrs, styles) => {
    return checkCoordinateOnText(coordinate, attrs, styles);
  },
  draw: (ctx, attrs, styles) => {
    drawText(ctx, attrs, styles);
  },
};

export default text;
