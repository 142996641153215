import _ from 'lodash';
import { useEffect, useState } from 'react';

const useResizeWindows = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = () => setWidth(window.innerWidth);

  const handleThrottleResize = _.throttle(handleResize, 1000);

  useEffect(() => {
    window.addEventListener('resize', handleThrottleResize);

    return () => {
      window.removeEventListener('resize', handleThrottleResize);
    };
  });

  return width;
};

export default useResizeWindows;
