import React from 'react';
import {
  Container,
  Header,
  ImageContainer,
  ImageWrapper,
  MaintenanceCard,
  StaticWrapper,
  Text,
  TextWrapper,
} from './style';
import { Button } from 'antd';

const CustomAnnouncement = ({ header, text, image }) => {
  return (
    <MaintenanceCard>
      <ImageContainer>
        <ImageWrapper src={image} />
      </ImageContainer>
      <div>
        <Header>{header}</Header>
        <TextWrapper>
          <Text>{text}</Text>
        </TextWrapper>
      </div>
    </MaintenanceCard>
  );
};

const StaticAnnouncement = ({ img, header, text, actionButton = () => {} }) => {
  return (
    <StaticWrapper>
      <Container>
        <ImageContainer>
          <ImageWrapper src={img} />
        </ImageContainer>
        <div>
          <Header>{header}</Header>
          <TextWrapper>
            <Button type="primary" onClick={actionButton}>
              {text}
            </Button>
          </TextWrapper>
        </div>
      </Container>
    </StaticWrapper>
  );
};

CustomAnnouncement.StaticAnnouncement = StaticAnnouncement;

export default CustomAnnouncement;
