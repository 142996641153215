import styled from 'styled-components';

export const BlockedCountryContainer = styled.div`
  padding: 200px 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  text-align: center;
  margin: auto;

  p {
    margin-top: 20px;
    max-width: 250px;
  }

  h2 {
    font-weight: 600;
  }

  span {
    color: var(--color-info);
    cursor: pointer;
  }
`;
