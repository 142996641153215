const ArrowSVG = () => {
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.215 5.33892C4.36485 5.48761 4.5674 5.57105 4.7785 5.57105C4.9896 5.57105 5.19215 5.48761 5.342 5.33892L9.321 1.35991C9.44946 1.20661 9.51584 1.01075 9.50705 0.810931C9.49826 0.611113 9.41494 0.421827 9.27351 0.280398C9.13208 0.138969 8.9428 0.0556452 8.74299 0.0468546C8.54317 0.038064 8.3473 0.104449 8.194 0.232913L4.775 3.6499L1.358 0.234912C1.28672 0.149849 1.19852 0.0805472 1.09901 0.0314127C0.999501 -0.0177218 0.890857 -0.0456189 0.779984 -0.0504965C0.669112 -0.0553741 0.558439 -0.037129 0.454999 0.00307713C0.351558 0.0432832 0.257613 0.104572 0.179139 0.183047C0.100664 0.261521 0.039377 0.35547 -0.000829123 0.458911C-0.0410352 0.562351 -0.0592804 0.67302 -0.0544027 0.783892C-0.0495251 0.894765 -0.0216319 1.00341 0.0275026 1.10292C0.0766372 1.20243 0.145937 1.29063 0.231 1.36191L4.21 5.34091L4.215 5.33892Z"
        fill="white"
      />
    </svg>
  );
};

export default ArrowSVG;
