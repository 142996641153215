/**
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 * http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export function checkCoordinateOnCircle(coordinate, circle) {
  const difX = coordinate.x - circle.x;
  const difY = coordinate.y - circle.y;
  const r = circle.r;
  return !(difX * difX + difY * difY > r * r);
}

export function drawCircle(ctx, attrs, styles) {
  const { x, y, r = 5 } = attrs;
  const { style = 'fill', color = 'white', borderSize = 3, borderColor = 'green' } = styles;
  if (style === 'fill' || styles?.style === 'stroke-fill') {
    ctx.fillStyle = color;
    ctx.beginPath();
    ctx.arc(x, y, r, 0, Math.PI * 2);
    ctx.closePath();
    ctx.fill();
  }
  if (style === 'stroke' || styles.style === 'stroke-fill') {
    ctx.strokeStyle = borderColor;
    ctx.lineWidth = borderSize;
    ctx.beginPath();
    ctx.arc(x, y, r, 0, Math.PI * 2);
    ctx.closePath();
    ctx.stroke();
  }
}

const circle = {
  name: 'custom-circle',
  checkEventOn: checkCoordinateOnCircle,
  draw: (ctx, attrs, styles) => {
    drawCircle(ctx, attrs, styles);
  },
};

export default circle;
