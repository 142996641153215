import React from 'react';
import Logo from '../../assets/icons/logo-white font.svg';
import FacebookSVG from '../UI/SVG-Icons/FacebookSVG';
import InstagramSVG from '../UI/SVG-Icons/InstagramSVG';
import TwitterSVG from '../UI/SVG-Icons/TwitterSVG';
import AirplaneSVG from '../UI/SVG-Icons/SidebarSVG/AirplaneSVG';
import './style.js';
import NavigationLink from '../UI/NavigationLink/NavigationLink';
import routes from '../../utils/routes';
import { Col, Row } from 'antd';
import { CopyWrightContainer, FooterContainer } from './style.js';
import { useTranslation } from 'react-i18next';
import { PUBLIC_LINKS } from '../../constants/links.js';
import { NavLink } from 'react-router-dom';

const PublicFooter = () => {
  const { t } = useTranslation();

  return (
    <>
      <FooterContainer>
        <div className="wrapper">
          <div className="top"></div>
          <Row className="navigation" gutter={[16, 16]}>
            <Col className="navigation__links" xl={6} lg={6} md={8} sm={8} xs={24}>
              <ul>
                <li>
                  <div className="logo">
                    <img src={Logo} alt="logo" />
                  </div>
                  <div className="address">
                    <p className="text">
                      MartexTrade
                      <br /> Reg. No: 2023-000672 <br /> Ground Floor, The Sotheby Building, <br /> Saint Lucia P.O. Box
                      838
                    </p>
                  </div>
                  <div className="social-media hide-mobile">
                    {/* <YoutubeSVG /> */}
                    {/* <AirplaneSVG/> */}
                    <NavLink to="https://t.me/MartexTradeOfficial" target="_blank" className="svg-link">
                      <AirplaneSVG />
                    </NavLink>
                    <FacebookSVG />
                    <InstagramSVG />
                    <TwitterSVG />
                  </div>
                </li>
              </ul>
            </Col>
            <Col className="navigation__links" xl={6} lg={6} md={8} sm={8} xs={24}>
              <ul>
                <li>
                  <NavigationLink
                    to={process.env.REACT_APP_AFFILIATE_WEBSITE}
                    target="_blank"
                    customClass="footer__link"
                  >
                    {t('Affiliates')}
                  </NavigationLink>
                </li>
                <li>
                  <NavigationLink to={routes.REGISTRATION} customClass="footer__link">
                    {t('Sign up')}
                  </NavigationLink>
                </li>
              </ul>
            </Col>
            <Col className="navigation__links" xl={6} lg={6} md={8} sm={8} xs={24}>
              <ul>
                <li>
                  <NavigationLink to="#" customClass="footer__link">
                    {t('Quick Links')}
                  </NavigationLink>
                </li>
                <li>
                  <NavigationLink to={routes.ABOUT_US} customClass="footer__link">
                    {t('About us')}
                  </NavigationLink>
                </li>
                <li>
                  <NavigationLink to={routes.CONTACT_US} customClass="footer__link">
                    {t('Contact us')}
                  </NavigationLink>
                </li>
                <li>
                  <NavigationLink to={routes.FAQ} customClass="footer__link">
                    {t('FAQ')}
                  </NavigationLink>
                </li>
              </ul>
            </Col>
            <Col className="navigation__links" xl={6} lg={6} md={24} sm={24} xs={24}>
              <ul>
                <li>
                  <NavigationLink to="#" customClass="footer__link">
                    {t('Regulations')}
                  </NavigationLink>
                </li>
                <li>
                  <NavigationLink to={routes.PRIVACY_POLICY} customClass="footer__link" target="_blank">
                    {t('Privacy policy')}
                  </NavigationLink>
                </li>
                <li>
                  <NavigationLink to={PUBLIC_LINKS.PAYMENT_POLICY} customClass="footer__link" target="_blank">
                    {t('Payment policy')}
                  </NavigationLink>{' '}
                </li>
                <li>
                  <NavigationLink to={PUBLIC_LINKS.SERVICE_AGREEMENT} customClass="footer__link" target="_blank">
                    {t('Service agreement')}
                  </NavigationLink>{' '}
                </li>
                <li>
                  <NavigationLink to={PUBLIC_LINKS.RISK_DISCLOSURE} customClass="footer__link" target="_blank">
                    {t('Risk disclosure')}
                  </NavigationLink>{' '}
                </li>
                <li>
                  <NavigationLink to={PUBLIC_LINKS.TRADING_OPERATION_RULE} customClass="footer__link" target="_blank">
                    {t('Rules of trading operations')}
                  </NavigationLink>
                </li>
                <li>
                  <NavigationLink to={PUBLIC_LINKS.NON_TRADING} customClass="footer__link" target="_blank">
                    {t('Non-trading operations regulations')}
                  </NavigationLink>
                </li>
                <li>
                  <NavigationLink to={PUBLIC_LINKS.KYC_POLICY} customClass="footer__link" target="_blank">
                    {t('KYC Policy')}
                  </NavigationLink>
                </li>
                <li>
                  <NavigationLink to={PUBLIC_LINKS.AML_POLICY} customClass="footer__link" target="_blank">
                    {t('AML Policy')}
                  </NavigationLink>
                </li>
              </ul>
            </Col>
          </Row>
          <div className="top">
            <div className="social-media show-mobile">
              {/* <YoutubeSVG /> */}
              <NavLink to="https://t.me/MartexTradeOfficial" target="_blank" className="svg-link">
                <AirplaneSVG />
              </NavLink>
              <FacebookSVG />
              <InstagramSVG />
              <TwitterSVG />
            </div>
          </div>
          <p className="riskMsg">
            <b>{t('Risk Warning')}</b>{' '}
            {t(
              'MartexTrade and leveraged financial instruments carry significant risk, possibly leading to capital loss. Only invest what you can afford to lose. Trading leveraged products may not be suitable for everyone. Even non-leveraged products like stocks involve risks, with the value fluctuating. Past performance doesnt guarantee future results. Consider your experience and investment goals before trading, and seek independent financial advice if needed. Clients must verify if using MartexTrade services complies with legal requirements in their residence country.',
            )}
          </p>
        </div>
      </FooterContainer>
      <CopyWrightContainer>{t('Copyright © 2023-24 MartexTrade. All rights reserved')}</CopyWrightContainer>
    </>
  );
};

export default PublicFooter;
