import React from 'react';

const UserSVG = ({ fill = 'white', width = '25', height = '25' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 10.4168C14.8012 10.4168 16.6666 8.55135 16.6666 6.25016C16.6666 3.94898 14.8012 2.0835 12.5 2.0835C10.1988 2.0835 8.33331 3.94898 8.33331 6.25016C8.33331 8.55135 10.1988 10.4168 12.5 10.4168Z"
        fill={fill}
      />
      <path
        d="M20.8334 18.229C20.8334 20.8179 20.8334 22.9165 12.5 22.9165C4.16669 22.9165 4.16669 20.8179 4.16669 18.229C4.16669 15.6401 7.89764 13.5415 12.5 13.5415C17.1024 13.5415 20.8334 15.6401 20.8334 18.229Z"
        fill={fill}
      />
    </svg>
  );
};

export default UserSVG;
