import React, { useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import SupportSVG from '../UI/SVG-Icons/SidebarSVG/SupportSVG';
import ChartSVG from '../UI/SVG-Icons/SidebarSVG/ChartSVG';
import DemoSVG from '../UI/SVG-Icons/SidebarSVG/DemoSVG';
import UserSVG from '../UI/SVG-Icons/SidebarSVG/UserSVG';
import CupSVG from '../UI/SVG-Icons/SidebarSVG/CupSVG';
import SettingsSVG from '../UI/SVG-Icons/SidebarSVG/SettingsSVG';
import AirplaneSVG from '../UI/SVG-Icons/SidebarSVG/AirplaneSVG';
import CloseSVG from '../UI/SVG-Icons/SidebarSVG/CloseSVG';
import './style.scss';
import Backdrop from '../UI/Backdrop/Backdrop';
import routes, { hashRoutes } from '../../utils/routes';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useUserStore } from '../../store/useUserStore';
import { ReactComponent as Unmute } from '../../assets/icons/Unmute.svg';
import { ReactComponent as Mute } from '../../assets/icons/Mute.svg';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

const Sidebar = ({ setIsSidebarOpen = () => {} }) => {
  const sidebarRef = useRef();
  const { notificationAlert, setNotificationAlert } = useUserStore();
  const location = useLocation();
  const isTab = useMediaQuery(769);
  const { t } = useTranslation();
  // const toggleSidebard = () => {
  //   sidebarRef.current.classList.toggle('open');
  // };

  const handleNotificationAlert = () => {
    setNotificationAlert(!notificationAlert);
  };

  useOnClickOutside(sidebarRef, () => setIsSidebarOpen(false));

  return (
    <>
      <div className="app__sidebar sidebar" ref={sidebarRef}>
        <nav className="sidebar__navigations">
          {isTab && (
            <div onClick={() => setIsSidebarOpen(false)} className="close_item">
              <CloseSVG />
            </div>
          )}
          <NavLink to={routes.TERMINAL.HOME} className={({ isActive }) => (isActive ? 'nav-item active' : 'nav-item')}>
            <ChartSVG />
            {t('Live')}
          </NavLink>
          <NavLink to={routes.DEMO_TRADE} className={({ isActive }) => (isActive ? 'nav-item active' : 'nav-item')}>
            <DemoSVG />
            {t('Demo')}
          </NavLink>
          <NavLink
            to="/support/my-request"
            className={() => (location.pathname.includes('/support') ? 'nav-item active' : 'nav-item')}
          >
            <SupportSVG />
            {t('Support')}
          </NavLink>
          <NavLink
            to={routes.PROFILE.HOME + routes.PROFILE.ACCOUNT}
            className={({ isActive }) => (isActive ? 'nav-item active' : 'nav-item')}
          >
            <UserSVG />
            {t('Profile')}
          </NavLink>
          <NavLink
            to={hashRoutes.LEADER_BOARD}
            className={() => (`${location?.hash}` === hashRoutes.LEADER_BOARD ? 'nav-item' : 'nav-item')}
          >
            <CupSVG />
            {t('Leaders')}
          </NavLink>
          <NavLink
            to={hashRoutes.SETTINGS}
            className={() => (`${location?.hash}` === hashRoutes.SETTINGS ? 'nav-item' : 'nav-item')}
          >
            <SettingsSVG />
            {t('Settings')}
          </NavLink>
        </nav>
        <div className="sidebar__footer">
          <div onClick={handleNotificationAlert} className="sidebar__footer__nav-item">
            {!notificationAlert ? <Mute /> : <Unmute />}
            {!notificationAlert ? `${t('Mute')}` : `${t('Unmute')}`}
          </div>
          <NavLink to="https://t.me/MartexTradeOfficial" target="_blank" className="sidebar__footer__nav-item">
            <AirplaneSVG />
            {t('Join')}
          </NavLink>
        </div>
      </div>
      <Backdrop />
    </>
  );
};

export default React.memo(Sidebar);
