import styled from 'styled-components';

export const LeaderBoardContainer = styled.div`
  .leader-board__header {
    border-bottom: 1px solid var(--color-border-dark);
    padding-bottom: 10px;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;

    h5 {
      color: var(--color-text-secondary);
    }

    span {
      color: var(--color-text-secondary);
      cursor: pointer;
    }
  }

  .userInLoss {
    color: var(--color-text-error) !important;
  }

  .userInProfit {
    color: var(--color-success) !important;
  }

  .my-rank {
    padding: 0px 20px;
    font-size: 12px;

    hr {
      border-color: ${(props) => (props.isUserInLoss ? 'var(--color-text-error)' : 'var(--color-success)')};
    }

    .rank {
      background-color: var(--color-grey-1);
      border-radius: 5px 5px 0px 0px;
      padding: 10px;
      display: flex;
      width: 100%;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }

      div {
        display: flex;
        align-items: center;
      }

      img {
        margin-right: 5px;
      }
    }

    .position {
      background-color: var(--color-grey-1);
      border-radius: 0px 0px 5px 5px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .popover {
        cursor: pointer;
      }
    }
  }

  .success {
    color: var(--color-success);
    margin-left: auto;
  }

  .body {
    padding: 0px 20px 0px 20px;
    font-size: 12px;
    overflow: auto;
    max-height: calc(100vh - 169px);

    @media screen and (max-width: 768px) {
      max-height: calc(100vh - 300px);
    }
  }

  .leader-board__item {
    padding: 15px 0px;
    border-bottom: 1px solid var(--color-border-dark);
    color: var(--color-text);
    display: flex;
    align-items: center;

    .profile-img {
      margin-right: 10px;
      display: flex;
      align-items: center;

      img,
      svg {
        height: 14px;
        width: 14px;
        border-radius: 50%;
        path {
          fill: var(--color-text);
        }
      }

      svg {
        margin-left: -5px;
        /* color: var(--color-grey-6); */
        background-color: var(--color-grey-6);
        border-radius: 50%;
      }
    }

    .gold {
      background-color: var(--color-warning-dark);
    }

    .silver {
      background-color: var(--color-grey-6);
    }

    .bronze {
      background-color: var(--color-text-bronze);
    }

    .rank {
      font-size: 10px;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
    }
  }
`;

export const LeaderBoardSkeleton = styled.div`
  display: flex;
  margin: 15px 0px;
  align-items: center;

  .ant-skeleton-avatar.ant-skeleton-avatar-circle {
    width: 16px;
    height: 16px;
    margin: auto;
  }

  .ant-skeleton.ant-skeleton-element .ant-skeleton-input-sm {
    height: 16px;
  }
`;
