import styled from 'styled-components';
import { ChartBg } from '../constants/image';

const CustomForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ApplicationWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const FallbackWrapper = styled.div`
  background-color: var(--color-grey-1);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
`;

const UnauthorizedWrapper = styled.div`
  padding: 0px 10%;

  @media screen and (max-width: 1280px) {
    padding: 0 70px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
`;

const PublicWrapper = styled.div`
  padding: 0px 10%;
  background-image: url(${ChartBg});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% 35%;

  .chart-img {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0%;
    z-index: -1;
  }

  @media screen and (max-width: 1280px) {
    padding: 0 70px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 20px;
    background-size: contain;
  }
`;

const PrivateWrapper = styled.div`
  padding: 0px 15px 0px 10px;

  @media screen and (max-width: 768px) {
    padding: 15px;
  }
`;

const NavbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    padding-bottom: 20px;

    .ant-form {
      width: 100% !important;
    }
  }
`;

const FAQWrapperItems = styled.div`
  width: 50%;
  &:first-child {
    margin-right: 24px;
    @media screen and (max-width: 1025px) {
      padding: 0 15px;
      width: 100%;
    }
  }
  @media screen and (max-width: 1025px) {
    width: 100%;
    padding: 0 15px;
  }
  .faq-item {
    padding: 16px 25px;
    margin-bottom: 18px;
    background: var(--color-grey-2);
    border-radius: 12px;
    .accordion-checkbox {
      display: none;
    }
    .arrow-IC {
      transition: transform 0.3s ease;
    }
    .accordion-checkbox:checked + .faq-item__question .arrow-IC {
      transform: rotate(180deg);
    }
    .question {
      display: flex;
      align-items: center;
      gap: 24px;
      min-height: 50px;
      cursor: pointer;
      .text {
        width: 100%;
        line-height: 30px;
        font-size: 1.25rem;
        @media screen and (max-width: 767px) {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

    .faq-accordion-text {
      height: 0;
      opacity: 0;
      overflow: hidden;
      transition:
        height 0.3s ease-in-out,
        opacity 0.4 ease-in-out;
    }
    .accordion-checkbox:checked ~ .faq-accordion-text {
      height: auto;
      margin-top: 20px;
      opacity: 1;
    }
  }
`;

const ProfitLossResultContainer = styled.div`
  color: ${(props) => (props.amount ? 'var(--color-success)' : 'var(--color-text-error)')};
`;

export {
  CustomForm,
  ApplicationWrapper,
  FallbackWrapper,
  UnauthorizedWrapper,
  PublicWrapper,
  PrivateWrapper,
  FAQWrapperItems,
  ProfitLossResultContainer,
  NavbarWrapper,
};
