export const EXPIRY_MONTH = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
  { value: 11, label: 11 },
  { value: 12, label: 12 },
];
export const QUESTION_TAB_ITEMS = [
  {
    title: '',
  },
  {
    title: '',
  },
  {
    title: '',
  },
];
export const AMOUNT_PRICES = [
  { value: 10000, label: 10000 },
  { value: 20000, label: 20000 },
  { value: 30000, label: 30000 },
  { value: 40000, label: 40000 },
  { value: 50000, label: 50000 },
];

export const TIME_SELECTION = [
  { value: 1, label: '00:05:00' },
  { value: 1, label: '00:07:00' },
  { value: 1, label: '00:09:00' },
  { value: 1, label: '00:11:00' },
  { value: 1, label: '00:25:00' },
];

export const MONEY_SELECTION = [
  { value: 1, label: '1$' },
  { value: 1, label: '4$' },
  { value: 1, label: '6$' },
  { value: 1, label: '8$' },
  { value: 1, label: '10$' },
];
export function convertMinToSec(min) {
  return min * 60;
}

export const FOREX_PAIR_TYPE = {
  REGULAR: 'Regular',
  OTC: 'OTC',
};

export const INVESTMENT_TIME_TYPE = {
  CURRENT: 'current',
  NORMAL: 'normal',
};

export const TIME_SPAN = [
  { value: 5, label: '5s', type: [FOREX_PAIR_TYPE.OTC] },
  { value: 10, label: '10s', type: [FOREX_PAIR_TYPE.OTC] },
  { value: 15, label: '15s', type: [FOREX_PAIR_TYPE.OTC] },
  { value: 30, label: '30s', type: [FOREX_PAIR_TYPE.OTC] },
  { value: convertMinToSec(1), label: '1m', type: [FOREX_PAIR_TYPE.OTC, FOREX_PAIR_TYPE.REGULAR] },
  { value: convertMinToSec(2), label: '2m', type: [FOREX_PAIR_TYPE.OTC, FOREX_PAIR_TYPE.REGULAR] },
  { value: convertMinToSec(3), label: '3m', type: [FOREX_PAIR_TYPE.OTC, FOREX_PAIR_TYPE.REGULAR] },
  { value: convertMinToSec(5), label: '5m', type: [FOREX_PAIR_TYPE.OTC, FOREX_PAIR_TYPE.REGULAR] },
  { value: convertMinToSec(10), label: '10m', type: [FOREX_PAIR_TYPE.OTC, FOREX_PAIR_TYPE.REGULAR] },
  { value: convertMinToSec(15), label: '15m', type: [FOREX_PAIR_TYPE.OTC, FOREX_PAIR_TYPE.REGULAR] },
  { value: convertMinToSec(30), label: '30m', type: [FOREX_PAIR_TYPE.OTC, FOREX_PAIR_TYPE.REGULAR] },
  { value: convertHoursToSec(1), label: '1h', type: [FOREX_PAIR_TYPE.OTC, FOREX_PAIR_TYPE.REGULAR] },
];
export function convertHoursToSec(hour) {
  return hour * 60 * 60;
}

export const getGoalEpochTime = (start, end) => {
  return start + end * 1000;
};

export const chart_data_dummy = {
  ticker: 'BABA',
  queryCount: 1970,
  resultsCount: 195,
  adjusted: true,
  results: [
    {
      volume: 2091,
      value: 83.8453,
      open: 83.82,
      close: 83.85,
      high: 83.89,
      low: 83.82,
      timestamp: 1695339420000,
      n: 49,
    },
    {
      volume: 1941,
      value: 83.8141,
      open: 83.85,
      close: 83.82,
      high: 83.85,
      low: 83.8,
      timestamp: 1695340320000,
      n: 36,
    },
    {
      volume: 73761,
      value: 87.9034,
      open: 87.68,
      close: 87.95,
      high: 88.11,
      low: 86.43,
      timestamp: 1695369120000,
      n: 881,
    },
    {
      volume: 28985,
      value: 87.8374,
      open: 87.91,
      close: 87.74,
      high: 87.92,
      low: 87.74,
      timestamp: 1695370020000,
      n: 345,
    },
    {
      volume: 15332,
      value: 87.6881,
      open: 87.71,
      close: 87.78,
      high: 87.79,
      low: 87.6,
      timestamp: 1695370920000,
      n: 206,
    },
    {
      volume: 7117,
      value: 87.6962,
      open: 87.74,
      close: 87.69,
      high: 87.74,
      low: 87.66,
      timestamp: 1695371820000,
      n: 67,
    },
    {
      volume: 6085,
      value: 87.6215,
      open: 87.65,
      close: 87.62,
      high: 87.66,
      low: 87.6,
      timestamp: 1695372720000,
      n: 81,
    },
    {
      volume: 12114,
      value: 87.6619,
      open: 87.57,
      close: 87.74,
      high: 87.74,
      low: 87.56,
      timestamp: 1695373620000,
      n: 110,
    },
    {
      volume: 8427,
      value: 87.7916,
      open: 87.75,
      close: 87.87,
      high: 87.88,
      low: 87.74,
      timestamp: 1695374520000,
      n: 108,
    },
    {
      volume: 46578,
      value: 87.9937,
      open: 87.87,
      close: 88.07,
      high: 88.15,
      low: 87.84,
      timestamp: 1695375420000,
      n: 285,
    },
    {
      volume: 8110,
      value: 87.9899,
      open: 88.03,
      close: 87.95,
      high: 88.05,
      low: 87.88,
      timestamp: 1695376320000,
      n: 98,
    },
    {
      volume: 5213,
      value: 87.866,
      open: 87.93,
      close: 87.85,
      high: 87.95,
      low: 87.8,
      timestamp: 1695377220000,
      n: 66,
    },
    {
      volume: 3355,
      value: 87.8019,
      open: 87.85,
      close: 87.75,
      high: 87.87,
      low: 87.75,
      timestamp: 1695378120000,
      n: 45,
    },
    {
      volume: 17825,
      value: 87.709,
      open: 87.7,
      close: 87.73,
      high: 87.75,
      low: 87.66,
      timestamp: 1695379020000,
      n: 89,
    },
    {
      volume: 54956,
      value: 87.5326,
      open: 87.74,
      close: 87.3,
      high: 87.74,
      low: 87.28,
      timestamp: 1695379920000,
      n: 584,
    },
    {
      volume: 24500,
      value: 87.3448,
      open: 87.29,
      close: 87.4,
      high: 87.45,
      low: 87.28,
      timestamp: 1695380820000,
      n: 243,
    },
    {
      volume: 18166,
      value: 87.4009,
      open: 87.45,
      close: 87.4,
      high: 87.46,
      low: 87.35,
      timestamp: 1695381720000,
      n: 170,
    },
    {
      volume: 11537,
      value: 87.3637,
      open: 87.42,
      close: 87.3,
      high: 87.42,
      low: 87.29,
      timestamp: 1695382620000,
      n: 176,
    },
    {
      volume: 178110,
      value: 87.5058,
      open: 87.3,
      close: 87.6,
      high: 87.98,
      low: 85.88,
      timestamp: 1695383520000,
      n: 1342,
    },
    {
      volume: 52099,
      value: 87.6765,
      open: 87.52,
      close: 87.8,
      high: 88,
      low: 87.4,
      timestamp: 1695384420000,
      n: 509,
    },
    {
      volume: 74127,
      value: 87.8534,
      open: 87.79,
      close: 87.8,
      high: 88,
      low: 87.71,
      timestamp: 1695385320000,
      n: 779,
    },
    {
      volume: 38944,
      value: 87.6824,
      open: 87.83,
      close: 87.57,
      high: 87.83,
      low: 87.51,
      timestamp: 1695386220000,
      n: 532,
    },
    {
      volume: 36073,
      value: 87.5997,
      open: 87.56,
      close: 87.58,
      high: 87.7,
      low: 87.51,
      timestamp: 1695387120000,
      n: 502,
    },
    {
      volume: 87010,
      value: 87.5243,
      open: 87.58,
      close: 87.49,
      high: 87.6,
      low: 87.4,
      timestamp: 1695388020000,
      n: 887,
    },
    {
      volume: 2.467243e6,
      value: 87.5028,
      open: 87.49,
      close: 87.76,
      high: 87.945,
      low: 87.09,
      timestamp: 1695388920000,
      n: 24122,
    },
    {
      volume: 2.508219e6,
      value: 87.7536,
      open: 87.7521,
      close: 87.49,
      high: 88.205,
      low: 87.33,
      timestamp: 1695389820000,
      n: 23602,
    },
    {
      volume: 2.227349e6,
      value: 87.9459,
      open: 87.49,
      close: 88.435,
      high: 88.5,
      low: 87.46,
      timestamp: 1695390720000,
      n: 19957,
    },
    {
      volume: 1.931356e6,
      value: 88.3924,
      open: 88.435,
      close: 88.57,
      high: 88.875,
      low: 87.97,
      timestamp: 1695391620000,
      n: 18050,
    },
    {
      volume: 1.315918e6,
      value: 88.6027,
      open: 88.575,
      close: 88.645,
      high: 88.81,
      low: 88.313,
      timestamp: 1695392520000,
      n: 13490,
    },
    {
      volume: 760236,
      value: 88.4001,
      open: 88.645,
      close: 88.161,
      high: 88.7,
      low: 88.04,
      timestamp: 1695393420000,
      n: 8821,
    },
    {
      volume: 716955,
      value: 88.4095,
      open: 88.18,
      close: 88.46,
      high: 88.55,
      low: 88.18,
      timestamp: 1695394320000,
      n: 7645,
    },
    {
      volume: 830166,
      value: 88.1992,
      open: 88.45,
      close: 88.065,
      high: 88.56,
      low: 87.9,
      timestamp: 1695395220000,
      n: 8025,
    },
    {
      volume: 544600,
      value: 88.0718,
      open: 88.07,
      close: 88.205,
      high: 88.269,
      low: 87.92,
      timestamp: 1695396120000,
      n: 5772,
    },
    {
      volume: 393397,
      value: 88.3069,
      open: 88.2,
      close: 88.24,
      high: 88.5,
      low: 88.17,
      timestamp: 1695397020000,
      n: 4169,
    },
    {
      volume: 391611,
      value: 88.0967,
      open: 88.24,
      close: 88.06,
      high: 88.27,
      low: 87.95,
      timestamp: 1695397920000,
      n: 4143,
    },
    {
      volume: 479613,
      value: 88.1933,
      open: 88.06,
      close: 88.29,
      high: 88.38,
      low: 88.04,
      timestamp: 1695398820000,
      n: 3706,
    },
    {
      volume: 438562,
      value: 88.3077,
      open: 88.295,
      close: 88.37,
      high: 88.38,
      low: 88.235,
      timestamp: 1695399720000,
      n: 4150,
    },
    {
      volume: 636268,
      value: 88.4598,
      open: 88.375,
      close: 88.4942,
      high: 88.58,
      low: 88.36,
      timestamp: 1695400620000,
      n: 5075,
    },
    {
      volume: 349491,
      value: 88.4643,
      open: 88.49,
      close: 88.34,
      high: 88.55,
      low: 88.315,
      timestamp: 1695401520000,
      n: 3626,
    },
    {
      volume: 393256,
      value: 88.2881,
      open: 88.34,
      close: 88.3501,
      high: 88.4,
      low: 88.15,
      timestamp: 1695402420000,
      n: 3728,
    },
    {
      volume: 385395,
      value: 88.2098,
      open: 88.3557,
      close: 88.2015,
      high: 88.37,
      low: 88.09,
      timestamp: 1695403320000,
      n: 4245,
    },
    {
      volume: 247145,
      value: 88.0798,
      open: 88.2,
      close: 88.1499,
      high: 88.2,
      low: 88.01,
      timestamp: 1695404220000,
      n: 3033,
    },
    {
      volume: 201215,
      value: 88.1122,
      open: 88.14,
      close: 88.12,
      high: 88.15,
      low: 88.06,
      timestamp: 1695405120000,
      n: 2635,
    },
    {
      volume: 419004,
      value: 88.0287,
      open: 88.12,
      close: 88.07,
      high: 88.15,
      low: 87.91,
      timestamp: 1695406020000,
      n: 4211,
    },
    {
      volume: 444004,
      value: 88.3225,
      open: 88.0787,
      close: 88.34,
      high: 88.46,
      low: 88.0787,
      timestamp: 1695406920000,
      n: 4565,
    },
    {
      volume: 216106,
      value: 88.2821,
      open: 88.34,
      close: 88.27,
      high: 88.345,
      low: 88.23,
      timestamp: 1695407820000,
      n: 2888,
    },
    {
      volume: 283974,
      value: 88.295,
      open: 88.26,
      close: 88.325,
      high: 88.39,
      low: 88.21,
      timestamp: 1695408720000,
      n: 3295,
    },
    {
      volume: 386276,
      value: 88.454,
      open: 88.33,
      close: 88.46,
      high: 88.54,
      low: 88.33,
      timestamp: 1695409620000,
      n: 4230,
    },
    {
      volume: 510835,
      value: 88.4705,
      open: 88.45,
      close: 88.4113,
      high: 88.55,
      low: 88.35,
      timestamp: 1695410520000,
      n: 5218,
    },
    {
      volume: 789333,
      value: 88.2102,
      open: 88.4,
      close: 88.09,
      high: 88.4186,
      low: 88.04,
      timestamp: 1695411420000,
      n: 9301,
    },
    {
      volume: 804737,
      value: 88.2191,
      open: 88.095,
      close: 88.49,
      high: 88.49,
      low: 88.05,
      timestamp: 1695412320000,
      n: 9067,
    },
    {
      volume: 8712,
      value: 88.3432,
      open: 88.48,
      close: 88.3,
      high: 88.48,
      low: 88.25,
      timestamp: 1695413220000,
      n: 140,
    },
    {
      volume: 5251,
      value: 88.4026,
      open: 88.29,
      close: 88.5,
      high: 88.5,
      low: 88.28,
      timestamp: 1695414120000,
      n: 77,
    },
    {
      volume: 2728,
      value: 88.2973,
      open: 88.37,
      close: 88.22,
      high: 88.37,
      low: 88.22,
      timestamp: 1695415020000,
      n: 46,
    },
    {
      volume: 2675,
      value: 88.3231,
      open: 88.29,
      close: 88.36,
      high: 88.36,
      low: 88.29,
      timestamp: 1695415920000,
      n: 19,
    },
    {
      volume: 55670,
      value: 88.3009,
      open: 88.3,
      close: 88.38,
      high: 88.38,
      low: 88.3,
      timestamp: 1695416820000,
      n: 20,
    },
    {
      volume: 103279,
      value: 88.3081,
      open: 88.38,
      close: 88.44,
      high: 88.44,
      low: 88.3,
      timestamp: 1695417720000,
      n: 82,
    },
    {
      volume: 966,
      value: 88.4385,
      open: 88.45,
      close: 88.44,
      high: 88.45,
      low: 88.41,
      timestamp: 1695418620000,
      n: 12,
    },
    {
      volume: 4014,
      value: 88.4925,
      open: 88.47,
      close: 88.55,
      high: 88.55,
      low: 88.41,
      timestamp: 1695419520000,
      n: 45,
    },
    {
      volume: 1537,
      value: 88.5474,
      open: 88.55,
      close: 88.49,
      high: 88.58,
      low: 88.49,
      timestamp: 1695420420000,
      n: 24,
    },
    {
      volume: 1708,
      value: 88.5014,
      open: 88.49,
      close: 88.5,
      high: 88.5,
      low: 88.49,
      timestamp: 1695421320000,
      n: 31,
    },
    {
      volume: 1206,
      value: 88.4925,
      open: 88.49,
      close: 88.49,
      high: 88.5,
      low: 88.49,
      timestamp: 1695422220000,
      n: 20,
    },
    {
      volume: 2053,
      value: 88.4932,
      open: 88.49,
      close: 88.46,
      high: 88.51,
      low: 88.46,
      timestamp: 1695423120000,
      n: 32,
    },
    {
      volume: 1718,
      value: 88.4042,
      open: 88.41,
      close: 88.43,
      high: 88.43,
      low: 88.3201,
      timestamp: 1695424020000,
      n: 14,
    },
    {
      volume: 7454,
      value: 88.4931,
      open: 88.4,
      close: 88.55,
      high: 88.55,
      low: 88.4,
      timestamp: 1695424920000,
      n: 24,
    },
    {
      volume: 654,
      value: 88.4854,
      open: 88.4,
      close: 88.55,
      high: 88.55,
      low: 88.4,
      timestamp: 1695425820000,
      n: 17,
    },
    {
      volume: 3114,
      value: 88.4529,
      open: 88.56,
      close: 88.5,
      high: 88.58,
      low: 88.4,
      timestamp: 1695426720000,
      n: 35,
    },
    {
      volume: 19506,
      value: 86.5969,
      open: 86.6,
      close: 86.6,
      high: 86.8,
      low: 86.5,
      timestamp: 1695628320000,
      n: 224,
    },
    {
      volume: 6877,
      value: 86.5555,
      open: 86.55,
      close: 86.51,
      high: 86.6,
      low: 86.51,
      timestamp: 1695629220000,
      n: 117,
    },
    {
      volume: 23706,
      value: 86.3096,
      open: 86.55,
      close: 86.4,
      high: 86.6,
      low: 86.05,
      timestamp: 1695630120000,
      n: 234,
    },
    {
      volume: 2093,
      value: 86.3523,
      open: 86.4,
      close: 86.36,
      high: 86.4,
      low: 86.32,
      timestamp: 1695631020000,
      n: 37,
    },
    {
      volume: 6802,
      value: 86.333,
      open: 86.4,
      close: 86.27,
      high: 86.4,
      low: 86.27,
      timestamp: 1695631920000,
      n: 62,
    },
    {
      volume: 18299,
      value: 86.3125,
      open: 86.34,
      close: 86.29,
      high: 86.43,
      low: 86.2,
      timestamp: 1695632820000,
      n: 173,
    },
    {
      volume: 7037,
      value: 86.2322,
      open: 86.26,
      close: 86.23,
      high: 86.28,
      low: 86.21,
      timestamp: 1695633720000,
      n: 70,
    },
    {
      volume: 745,
      value: 86.214,
      open: 86.19,
      close: 86.29,
      high: 86.29,
      low: 86.19,
      timestamp: 1695634620000,
      n: 14,
    },
    {
      volume: 1148,
      value: 86.2876,
      open: 86.28,
      close: 86.34,
      high: 86.34,
      low: 86.28,
      timestamp: 1695636420000,
      n: 24,
    },
    {
      volume: 3906,
      value: 86.3553,
      open: 86.35,
      close: 86.36,
      high: 86.39,
      low: 86.35,
      timestamp: 1695637320000,
      n: 50,
    },
    {
      volume: 3345,
      value: 86.297,
      open: 86.35,
      close: 86.25,
      high: 86.35,
      low: 86.25,
      timestamp: 1695638220000,
      n: 50,
    },
    {
      volume: 9661,
      value: 86.3191,
      open: 86.33,
      close: 86.33,
      high: 86.35,
      low: 86.27,
      timestamp: 1695639120000,
      n: 128,
    },
    {
      volume: 15956,
      value: 86.2076,
      open: 86.33,
      close: 86.12,
      high: 86.35,
      low: 86.11,
      timestamp: 1695640020000,
      n: 161,
    },
    {
      volume: 12667,
      value: 86.0841,
      open: 86.11,
      close: 86.05,
      high: 86.17,
      low: 86.01,
      timestamp: 1695640920000,
      n: 146,
    },
    {
      volume: 6580,
      value: 86.087,
      open: 86.03,
      close: 86.1,
      high: 86.1,
      low: 86.03,
      timestamp: 1695641820000,
      n: 70,
    },
    {
      volume: 32775,
      value: 86.2871,
      open: 86.09,
      close: 86.1,
      high: 86.99,
      low: 86.01,
      timestamp: 1695642720000,
      n: 408,
    },
    {
      volume: 29415,
      value: 86.1241,
      open: 86.06,
      close: 86.11,
      high: 86.2,
      low: 86.05,
      timestamp: 1695643620000,
      n: 234,
    },
    {
      volume: 58176,
      value: 86.0267,
      open: 86.05,
      close: 86.07,
      high: 86.14,
      low: 85.88,
      timestamp: 1695644520000,
      n: 291,
    },
    {
      volume: 11318,
      value: 86.1422,
      open: 86.12,
      close: 86.2,
      high: 86.2,
      low: 86.09,
      timestamp: 1695645420000,
      n: 150,
    },
    {
      volume: 49973,
      value: 86.0686,
      open: 86.1,
      close: 85.95,
      high: 86.2,
      low: 85.91,
      timestamp: 1695646320000,
      n: 249,
    },
    {
      volume: 45040,
      value: 85.9094,
      open: 86.02,
      close: 86.05,
      high: 86.15,
      low: 85.8,
      timestamp: 1695647220000,
      n: 423,
    },
    {
      volume: 816980,
      value: 86.0141,
      open: 86,
      close: 86.04,
      high: 86.378,
      low: 85.76,
      timestamp: 1695648120000,
      n: 8010,
    },
    {
      volume: 924696,
      value: 86.1656,
      open: 86.07,
      close: 86.34,
      high: 86.4823,
      low: 85.835,
      timestamp: 1695649020000,
      n: 9497,
    },
    {
      volume: 570940,
      value: 86.408,
      open: 86.35,
      close: 86.5182,
      high: 86.6,
      low: 86.245,
      timestamp: 1695649920000,
      n: 6265,
    },
    {
      volume: 542692,
      value: 86.5353,
      open: 86.51,
      close: 86.565,
      high: 86.7,
      low: 86.37,
      timestamp: 1695650820000,
      n: 5363,
    },
    {
      volume: 564247,
      value: 86.7575,
      open: 86.565,
      close: 86.73,
      high: 87,
      low: 86.48,
      timestamp: 1695651720000,
      n: 6191,
    },
    {
      volume: 308239,
      value: 86.725,
      open: 86.72,
      close: 86.735,
      high: 86.855,
      low: 86.6,
      timestamp: 1695652620000,
      n: 3559,
    },
    {
      volume: 266778,
      value: 86.7021,
      open: 86.72,
      close: 86.8305,
      high: 86.84,
      low: 86.5627,
      timestamp: 1695653520000,
      n: 3076,
    },
    {
      volume: 352189,
      value: 86.9306,
      open: 86.83,
      close: 86.9,
      high: 87.03,
      low: 86.805,
      timestamp: 1695654420000,
      n: 3289,
    },
    {
      volume: 274894,
      value: 86.9962,
      open: 86.9,
      close: 87.13,
      high: 87.14,
      low: 86.87,
      timestamp: 1695655320000,
      n: 2921,
    },
    {
      volume: 257166,
      value: 87.0236,
      open: 87.13,
      close: 86.96,
      high: 87.145,
      low: 86.925,
      timestamp: 1695656220000,
      n: 2290,
    },
    {
      volume: 159164,
      value: 86.9919,
      open: 86.96,
      close: 87.05,
      high: 87.07,
      low: 86.91,
      timestamp: 1695657120000,
      n: 1843,
    },
    {
      volume: 127574,
      value: 87.07,
      open: 87.0616,
      close: 87.0901,
      high: 87.12,
      low: 87.01,
      timestamp: 1695658020000,
      n: 1487,
    },
    {
      volume: 168787,
      value: 87.1129,
      open: 87.0903,
      close: 87.05,
      high: 87.19,
      low: 87.03,
      timestamp: 1695658920000,
      n: 1814,
    },
    {
      volume: 154410,
      value: 86.9869,
      open: 87.04,
      close: 86.925,
      high: 87.06,
      low: 86.88,
      timestamp: 1695659820000,
      n: 1728,
    },
    {
      volume: 137155,
      value: 86.9063,
      open: 86.922,
      close: 87,
      high: 87,
      low: 86.835,
      timestamp: 1695660720000,
      n: 1601,
    },
    {
      volume: 103429,
      value: 87.0188,
      open: 87.02,
      close: 86.958,
      high: 87.08,
      low: 86.958,
      timestamp: 1695661620000,
      n: 1190,
    },
    {
      volume: 116338,
      value: 86.8971,
      open: 86.9526,
      close: 86.88,
      high: 86.965,
      low: 86.84,
      timestamp: 1695662520000,
      n: 1458,
    },
    {
      volume: 131980,
      value: 86.8896,
      open: 86.88,
      close: 86.89,
      high: 86.915,
      low: 86.84,
      timestamp: 1695663420000,
      n: 1356,
    },
    {
      volume: 133485,
      value: 87.0069,
      open: 86.9,
      close: 87.08,
      high: 87.11,
      low: 86.88,
      timestamp: 1695664320000,
      n: 1570,
    },
    {
      volume: 103296,
      value: 87.0372,
      open: 87.08,
      close: 87,
      high: 87.13,
      low: 86.99,
      timestamp: 1695665220000,
      n: 1532,
    },
    {
      volume: 133766,
      value: 87.0446,
      open: 87,
      close: 87.09,
      high: 87.1,
      low: 86.982,
      timestamp: 1695666120000,
      n: 1649,
    },
    {
      volume: 217082,
      value: 87.0798,
      open: 87.08,
      close: 87.08,
      high: 87.1299,
      low: 87.04,
      timestamp: 1695667020000,
      n: 2586,
    },
    {
      volume: 219726,
      value: 87.0285,
      open: 87.08,
      close: 86.97,
      high: 87.14,
      low: 86.86,
      timestamp: 1695667920000,
      n: 2811,
    },
    {
      volume: 156560,
      value: 87.0394,
      open: 86.975,
      close: 87.08,
      high: 87.115,
      low: 86.94,
      timestamp: 1695668820000,
      n: 1658,
    },
    {
      volume: 168207,
      value: 87.0054,
      open: 87.075,
      close: 87.015,
      high: 87.085,
      low: 86.94,
      timestamp: 1695669720000,
      n: 2306,
    },
    {
      volume: 354237,
      value: 86.992,
      open: 87.01,
      close: 86.955,
      high: 87.06,
      low: 86.95,
      timestamp: 1695670620000,
      n: 4621,
    },
    {
      volume: 896241,
      value: 87.144,
      open: 86.95,
      close: 87.16,
      high: 87.27,
      low: 86.93,
      timestamp: 1695671520000,
      n: 7590,
    },
    {
      volume: 3414,
      value: 87.1662,
      open: 87.16,
      close: 87.22,
      high: 87.22,
      low: 87.16,
      timestamp: 1695672420000,
      n: 33,
    },
    {
      volume: 1139,
      value: 87.2054,
      open: 87.2,
      close: 87.22,
      high: 87.22,
      low: 87.2,
      timestamp: 1695673320000,
      n: 14,
    },
    {
      volume: 2249,
      value: 87.2425,
      open: 87.27,
      close: 87.25,
      high: 87.27,
      low: 87.2,
      timestamp: 1695674220000,
      n: 12,
    },
    {
      volume: 1453,
      value: 87.203,
      open: 87.2099,
      close: 87.2,
      high: 87.2099,
      low: 87.2,
      timestamp: 1695675120000,
      n: 8,
    },
    {
      volume: 2615,
      value: 86.9855,
      open: 87.21,
      close: 87.2,
      high: 87.21,
      low: 87.13,
      timestamp: 1695676020000,
      n: 28,
    },
    {
      volume: 1914,
      value: 87.1808,
      open: 87.11,
      close: 87.2,
      high: 87.23,
      low: 87.11,
      timestamp: 1695676920000,
      n: 11,
    },
    {
      volume: 2682,
      value: 87.2637,
      open: 87.23,
      close: 87.28,
      high: 87.28,
      low: 87.23,
      timestamp: 1695677820000,
      n: 9,
    },
    {
      volume: 906,
      value: 87.2199,
      open: 87.21,
      close: 87.28,
      high: 87.28,
      low: 87.21,
      timestamp: 1695678720000,
      n: 5,
    },
    {
      volume: 201,
      value: 87.2201,
      open: 87.22,
      close: 87.22,
      high: 87.22,
      low: 87.22,
      timestamp: 1695679620000,
      n: 3,
    },
    {
      volume: 1715,
      value: 87.2546,
      open: 87.27,
      close: 87.26,
      high: 87.27,
      low: 87.21,
      timestamp: 1695680520000,
      n: 18,
    },
    {
      volume: 1450,
      value: 87.2176,
      open: 87.2101,
      close: 87.22,
      high: 87.22,
      low: 87.2101,
      timestamp: 1695681420000,
      n: 7,
    },
    {
      volume: 806,
      value: 87.229,
      open: 87.26,
      close: 87.21,
      high: 87.26,
      low: 87.21,
      timestamp: 1695682320000,
      n: 4,
    },
    {
      volume: 845,
      value: 87.2356,
      open: 87.2493,
      close: 87.25,
      high: 87.25,
      low: 87.22,
      timestamp: 1695683220000,
      n: 5,
    },
    {
      volume: 983,
      value: 87.2433,
      open: 87.28,
      close: 87.24,
      high: 87.28,
      low: 87.22,
      timestamp: 1695684120000,
      n: 9,
    },
    {
      volume: 5309,
      value: 87.3003,
      open: 87.24,
      close: 87.3,
      high: 87.39,
      low: 87.24,
      timestamp: 1695685020000,
      n: 21,
    },
    {
      volume: 3511,
      value: 87.3992,
      open: 87.4,
      close: 87.4,
      high: 87.4,
      low: 87.4,
      timestamp: 1695685920000,
      n: 17,
    },
    {
      volume: 8107,
      value: 86.1009,
      open: 86.28,
      close: 86.16,
      high: 86.28,
      low: 86,
      timestamp: 1695714720000,
      n: 128,
    },
    {
      volume: 2681,
      value: 86.1118,
      open: 86.06,
      close: 86.15,
      high: 86.19,
      low: 86.03,
      timestamp: 1695715620000,
      n: 36,
    },
    {
      volume: 3319,
      value: 86.1953,
      open: 86.19,
      close: 86.17,
      high: 86.23,
      low: 86.17,
      timestamp: 1695716520000,
      n: 45,
    },
    {
      volume: 6570,
      value: 86.1775,
      open: 86.15,
      close: 86.23,
      high: 86.23,
      low: 86.14,
      timestamp: 1695717420000,
      n: 42,
    },
    {
      volume: 2000,
      value: 86.1214,
      open: 86.12,
      close: 86.12,
      high: 86.12,
      low: 86.12,
      timestamp: 1695718320000,
      n: 18,
    },
    {
      volume: 33140,
      value: 86.7686,
      open: 86.2,
      close: 87,
      high: 87.24,
      low: 86.18,
      timestamp: 1695719220000,
      n: 477,
    },
    {
      volume: 11719,
      value: 86.9161,
      open: 86.82,
      close: 86.89,
      high: 87,
      low: 86.8,
      timestamp: 1695720120000,
      n: 149,
    },
    {
      volume: 1662,
      value: 86.9016,
      open: 86.9,
      close: 86.85,
      high: 86.94,
      low: 86.85,
      timestamp: 1695721020000,
      n: 20,
    },
    {
      volume: 12584,
      value: 86.8489,
      open: 86.83,
      close: 86.8,
      high: 86.88,
      low: 86.78,
      timestamp: 1695721920000,
      n: 161,
    },
    {
      volume: 2071,
      value: 86.6643,
      open: 86.72,
      close: 86.58,
      high: 86.72,
      low: 86.58,
      timestamp: 1695722820000,
      n: 44,
    },
    {
      volume: 854,
      value: 86.6402,
      open: 86.6,
      close: 86.67,
      high: 86.67,
      low: 86.6,
      timestamp: 1695723720000,
      n: 8,
    },
    {
      volume: 1951,
      value: 86.7262,
      open: 86.64,
      close: 86.8,
      high: 86.8,
      low: 86.64,
      timestamp: 1695724620000,
      n: 28,
    },
    {
      volume: 10644,
      value: 86.7415,
      open: 86.78,
      close: 86.66,
      high: 86.84,
      low: 86.66,
      timestamp: 1695725520000,
      n: 90,
    },
    {
      volume: 5396,
      value: 86.6756,
      open: 86.69,
      close: 86.76,
      high: 86.76,
      low: 86.66,
      timestamp: 1695726420000,
      n: 55,
    },
    {
      volume: 9906,
      value: 86.7765,
      open: 86.8,
      close: 86.75,
      high: 86.84,
      low: 86.7,
      timestamp: 1695727320000,
      n: 172,
    },
    {
      volume: 12842,
      value: 86.7722,
      open: 86.79,
      close: 86.66,
      high: 86.83,
      low: 86.66,
      timestamp: 1695728220000,
      n: 129,
    },
    {
      volume: 37558,
      value: 86.4963,
      open: 86.68,
      close: 86.496,
      high: 86.85,
      low: 85.8,
      timestamp: 1695729120000,
      n: 454,
    },
    {
      volume: 4860,
      value: 86.5304,
      open: 86.5,
      close: 86.56,
      high: 86.576,
      low: 86.5,
      timestamp: 1695730020000,
      n: 53,
    },
    {
      volume: 13680,
      value: 86.4598,
      open: 86.53,
      close: 86.38,
      high: 86.53,
      low: 86.38,
      timestamp: 1695730920000,
      n: 128,
    },
    {
      volume: 9451,
      value: 86.4188,
      open: 86.46,
      close: 86.41,
      high: 86.46,
      low: 86.35,
      timestamp: 1695731820000,
      n: 105,
    },
    {
      volume: 46295,
      value: 86.5598,
      open: 86.45,
      close: 86.43,
      high: 86.96,
      low: 86.38,
      timestamp: 1695732720000,
      n: 336,
    },
    {
      volume: 11286,
      value: 86.3564,
      open: 86.42,
      close: 86.28,
      high: 86.47,
      low: 86.28,
      timestamp: 1695733620000,
      n: 164,
    },
    {
      volume: 445799,
      value: 86.5362,
      open: 86.39,
      close: 86.795,
      high: 86.85,
      low: 86.26,
      timestamp: 1695734520000,
      n: 4602,
    },
    {
      volume: 694414,
      value: 86.7728,
      open: 86.78,
      close: 86.55,
      high: 87.07,
      low: 86.382,
      timestamp: 1695735420000,
      n: 8479,
    },
    {
      volume: 482858,
      value: 86.5043,
      open: 86.5401,
      close: 86.62,
      high: 86.766,
      low: 86.35,
      timestamp: 1695736320000,
      n: 5627,
    },
    {
      volume: 612400,
      value: 86.3015,
      open: 86.61,
      close: 86.115,
      high: 86.65,
      low: 86.09,
      timestamp: 1695737220000,
      n: 5291,
    },
    {
      volume: 390664,
      value: 86.4212,
      open: 86.115,
      close: 86.4,
      high: 86.6,
      low: 86.11,
      timestamp: 1695738120000,
      n: 4363,
    },
    {
      volume: 306316,
      value: 86.5033,
      open: 86.4,
      close: 86.54,
      high: 86.63,
      low: 86.36,
      timestamp: 1695739020000,
      n: 3777,
    },
    {
      volume: 275275,
      value: 86.4859,
      open: 86.535,
      close: 86.435,
      high: 86.6,
      low: 86.385,
      timestamp: 1695739920000,
      n: 2995,
    },
    {
      volume: 771220,
      value: 86.3606,
      open: 86.44,
      close: 86.355,
      high: 86.48,
      low: 86.31,
      timestamp: 1695740820000,
      n: 3112,
    },
    {
      volume: 343188,
      value: 86.259,
      open: 86.35,
      close: 86.295,
      high: 86.35,
      low: 86.17,
      timestamp: 1695741720000,
      n: 3634,
    },
    {
      volume: 193899,
      value: 86.2827,
      open: 86.29,
      close: 86.19,
      high: 86.37,
      low: 86.19,
      timestamp: 1695742620000,
      n: 2491,
    },
    {
      volume: 261572,
      value: 86.1463,
      open: 86.19,
      close: 86.16,
      high: 86.23,
      low: 86.08,
      timestamp: 1695743520000,
      n: 3144,
    },
    {
      volume: 187440,
      value: 86.1865,
      open: 86.16,
      close: 86.08,
      high: 86.26,
      low: 86.06,
      timestamp: 1695744420000,
      n: 2241,
    },
    {
      volume: 138556,
      value: 86.1391,
      open: 86.09,
      close: 86.205,
      high: 86.23,
      low: 86.0712,
      timestamp: 1695745320000,
      n: 1613,
    },
    {
      volume: 98403,
      value: 86.2202,
      open: 86.205,
      close: 86.1481,
      high: 86.27,
      low: 86.135,
      timestamp: 1695746220000,
      n: 1373,
    },
    {
      volume: 234582,
      value: 86.1834,
      open: 86.15,
      close: 86.085,
      high: 86.26,
      low: 86.0787,
      timestamp: 1695747120000,
      n: 2198,
    },
    {
      volume: 132124,
      value: 86.1464,
      open: 86.09,
      close: 86.065,
      high: 86.225,
      low: 86.05,
      timestamp: 1695748020000,
      n: 1797,
    },
    {
      volume: 194873,
      value: 85.9848,
      open: 86.0686,
      close: 85.901,
      high: 86.09,
      low: 85.8948,
      timestamp: 1695748920000,
      n: 2594,
    },
    {
      volume: 181393,
      value: 85.867,
      open: 85.9,
      close: 86,
      high: 86,
      low: 85.8,
      timestamp: 1695749820000,
      n: 2232,
    },
    {
      volume: 158175,
      value: 85.9597,
      open: 85.99,
      close: 85.94,
      high: 86,
      low: 85.925,
      timestamp: 1695750720000,
      n: 1896,
    },
    {
      volume: 202748,
      value: 85.8596,
      open: 85.94,
      close: 85.79,
      high: 85.94,
      low: 85.75,
      timestamp: 1695751620000,
      n: 2037,
    },
    {
      volume: 233329,
      value: 85.7696,
      open: 85.785,
      close: 85.79,
      high: 85.86,
      low: 85.7,
      timestamp: 1695752520000,
      n: 2400,
    },
    {
      volume: 179569,
      value: 85.8131,
      open: 85.7912,
      close: 85.8573,
      high: 85.87,
      low: 85.735,
      timestamp: 1695753420000,
      n: 2206,
    },
    {
      volume: 197233,
      value: 85.8579,
      open: 85.85,
      close: 85.94,
      high: 85.96,
      low: 85.763,
      timestamp: 1695754320000,
      n: 2515,
    },
    {
      volume: 185652,
      value: 85.9448,
      open: 85.9351,
      close: 85.83,
      high: 86.03,
      low: 85.82,
      timestamp: 1695755220000,
      n: 2210,
    },
    {
      volume: 271284,
      value: 85.8384,
      open: 85.82,
      close: 85.88,
      high: 85.905,
      low: 85.79,
      timestamp: 1695756120000,
      n: 3177,
    },
    {
      volume: 559439,
      value: 85.9227,
      open: 85.89,
      close: 85.81,
      high: 86.02,
      low: 85.79,
      timestamp: 1695757020000,
      n: 5494,
    },
    {
      volume: 502805,
      value: 85.8861,
      open: 85.805,
      close: 85.9,
      high: 85.925,
      low: 85.805,
      timestamp: 1695757920000,
      n: 5044,
    },
    {
      volume: 23531,
      value: 85.9606,
      open: 85.92,
      close: 85.91,
      high: 86,
      low: 85.91,
      timestamp: 1695758820000,
      n: 34,
    },
    {
      volume: 2684,
      value: 85.8297,
      open: 85.9707,
      close: 85.88,
      high: 85.9707,
      low: 85.7,
      timestamp: 1695759720000,
      n: 39,
    },
    {
      volume: 700,
      value: 85.8943,
      open: 85.9,
      close: 85.88,
      high: 85.9,
      low: 85.88,
      timestamp: 1695760620000,
      n: 3,
    },
    {
      volume: 4623,
      value: 85.8508,
      open: 85.88,
      close: 85.85,
      high: 85.89,
      low: 85.82,
      timestamp: 1695761520000,
      n: 40,
    },
    {
      volume: 3760,
      value: 85.851,
      open: 85.85,
      close: 85.82,
      high: 85.89,
      low: 85.82,
      timestamp: 1695762420000,
      n: 30,
    },
    {
      volume: 1203,
      value: 85.8796,
      open: 85.7,
      close: 85.9,
      high: 85.9,
      low: 85.7,
      timestamp: 1695763320000,
      n: 20,
    },
    {
      volume: 1139,
      value: 85.9265,
      open: 85.93,
      close: 85.86,
      high: 85.98,
      low: 85.86,
      timestamp: 1695764220000,
      n: 13,
    },
    {
      volume: 1890,
      value: 85.8637,
      open: 85.88,
      close: 85.8611,
      high: 85.88,
      low: 85.86,
      timestamp: 1695766020000,
      n: 13,
    },
    {
      volume: 1518,
      value: 85.8789,
      open: 85.89,
      close: 85.87,
      high: 85.89,
      low: 85.87,
      timestamp: 1695766920000,
      n: 24,
    },
    {
      volume: 423,
      value: 85.9034,
      open: 85.9,
      close: 85.9,
      high: 85.9,
      low: 85.9,
      timestamp: 1695767820000,
      n: 10,
    },
    {
      volume: 5146,
      value: 85.9679,
      open: 85.91,
      close: 86,
      high: 86,
      low: 85.91,
      timestamp: 1695768720000,
      n: 32,
    },
    {
      volume: 710,
      value: 86.014,
      open: 85.96,
      close: 86.06,
      high: 86.06,
      low: 85.96,
      timestamp: 1695769620000,
      n: 10,
    },
    {
      volume: 4710,
      value: 86.0769,
      open: 86.0501,
      close: 86.1,
      high: 86.1,
      low: 86.05,
      timestamp: 1695770520000,
      n: 22,
    },
    {
      volume: 4469,
      value: 86.1411,
      open: 86.15,
      close: 86.13,
      high: 86.15,
      low: 86.1,
      timestamp: 1695771420000,
      n: 25,
    },
    {
      volume: 7467,
      value: 86.0912,
      open: 86.11,
      close: 86.05,
      high: 86.25,
      low: 86.02,
      timestamp: 1695772320000,
      n: 37,
    },
  ],
  status: 'DELAYED',
  request_id: '55651e6fbb742cac3dea0d930fc04432',
  count: 195,
};

export const getEmptyData = (lastDate, gap) =>
  Array.from({ length: 15 }).map((item, index) => {
    return {
      open: null,
      close: null,
      high: null,
      low: Infinity,
      timestamp: lastDate + (index + 1) * gap * 60 * 1000,
    };
  });

export const TIME_FRAMES = {
  ONE_MINUTE: '1/minute',
  FIVE_MINUTE: '5/minute',
  ONE_HOUR: '1/hour',
  TWO_HOUR: '2/hour',
  FOUR_HOUR: '4/hour',
  ONE_DAY: '1/day',
  ONE_WEEK: '1/week',
  ONE_MONTH: '1/month',
  ONE_YEAR: '1/year',
};

export const handleAddCandle = (currTime, endTime, addTime) => {
  if (endTime > currTime + addTime) {
    return handleAddCandle(currTime + addTime, endTime, addTime);
  } else return currTime + addTime;
};

export const timezonesList = [
  { timezone: 'Etc/GMT+12', utc: 'UTC-12:00' },
  { timezone: 'Etc/GMT+11', utc: 'UTC-11:00' },
  { timezone: 'Etc/GMT+10', utc: 'UTC-10:00' },
  { timezone: 'Pacific/Marquesas', utc: 'UTC-09:30' },
  { timezone: 'Etc/GMT+9', utc: 'UTC-09:00' },
  { timezone: 'Etc/GMT+8', utc: 'UTC-08:00' },
  { timezone: 'Etc/GMT+7', utc: 'UTC-07:00' },
  { timezone: 'Etc/GMT+6', utc: 'UTC-06:00' },
  { timezone: 'Etc/GMT+5', utc: 'UTC-05:00' },
  { timezone: 'Etc/GMT+4', utc: 'UTC-04:00' },
  { timezone: 'America/St_Johns', utc: 'UTC-03:30' },
  { timezone: 'Etc/GMT+3', utc: 'UTC-03:00' },
  { timezone: 'Etc/GMT+2', utc: 'UTC-02:00' },
  { timezone: 'Etc/GMT+1', utc: 'UTC-01:00' },
  { timezone: 'Etc/GMT', utc: 'UTC+00:00' },
  { timezone: 'Etc/GMT-1', utc: 'UTC+01:00' },
  { timezone: 'Etc/GMT-2', utc: 'UTC+02:00' },
  { timezone: 'Etc/GMT-3', utc: 'UTC+03:00' },
  { timezone: 'Asia/Tehran', utc: 'UTC+03:30' },
  { timezone: 'Etc/GMT-4', utc: 'UTC+04:00' },
  { timezone: 'Asia/Kabul', utc: 'UTC+04:30' },
  { timezone: 'Etc/GMT-5', utc: 'UTC+05:00' },
  { timezone: 'Asia/Kolkata', utc: 'UTC+05:30' },
  { timezone: 'Asia/Kathmandu', utc: 'UTC+05:45' },
  { timezone: 'Etc/GMT-6', utc: 'UTC+06:00' },
  { timezone: 'Asia/Yangon', utc: 'UTC+06:30' },
  { timezone: 'Etc/GMT-7', utc: 'UTC+07:00' },
  { timezone: 'Etc/GMT-8', utc: 'UTC+08:00' },
  { timezone: 'Etc/GMT-9', utc: 'UTC+09:00' },
  { timezone: 'Australia/Darwin', utc: 'UTC+09:30' },
  { timezone: 'Etc/GMT-10', utc: 'UTC+10:00' },
  { timezone: 'Etc/GMT-11', utc: 'UTC+11:00' },
  { timezone: 'Etc/GMT-12', utc: 'UTC+12:00' },
  { timezone: 'Etc/GMT-13', utc: 'UTC+13:00' },
  { timezone: 'Etc/GMT-14', utc: 'UTC+14:00' },
];
export const INVESTMENT_TYPE = {
  positive: 'Long',
  negative: 'Short',
};
export const INVESTMENT_TYPE_REVERSE = {
  Long: 'positive',
  Short: 'negative',
};

export const TRANSACTION_TYPE = {
  SUCCEED: 'Succeeded',
  PENDING: 'Under Process',
  FAILED: 'Failed',
};
