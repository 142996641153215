import { Navigate, Outlet } from 'react-router-dom';
import PublicLayout from '../Components/Layouts/PublicLayout';
import routes from '../utils/routes';
import OAuthContextProvider from '../context/OAuthContext';
import { useUserStore } from '../store/useUserStore';

function PublicRoute() {
  const loginStore = useUserStore();

  const token = loginStore?.token?.accessToken;

  return !token ? (
    <OAuthContextProvider>
      <PublicLayout>
        <Outlet />
      </PublicLayout>
    </OAuthContextProvider>
  ) : (
    <Navigate to={routes.TERMINAL.HOME} />
  );
}

export default PublicRoute;
