import { schema } from '../../../schema/schema';

const loginSchema = (t) => {
  return {
    email: schema(t).email,
    password: schema(t).password,
  };
};

const registerSchema = (t) => {
  return {
    email: schema(t).email,
    password: schema(t).password,
    country: {
      required: t('Country is required'),
    },
  };
};

const forgotPasswordSchema = (t) => {
  return {
    email: schema(t).email,
  };
};

const restorePasswordSchema = (t) => {
  return {
    password: schema(t).password,
  };
};

export { loginSchema, registerSchema, forgotPasswordSchema, restorePasswordSchema };
