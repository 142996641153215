import { notification } from 'antd';

const openNotification = (type = 'error', { title = '', message = '' } = {}) => {
  notification[type]({
    message: title,
    description: message,
    duration: 2,
  });
};

export default openNotification;
