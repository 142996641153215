const PlusSVG = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.84615 0H7.15385V4.84615H12V7.15385H7.15385V12H4.84615V7.15385H0V4.84615H4.84615V0Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export default PlusSVG;
